import { Fade, Grid2 as Grid, Paper, styled } from "@mui/material";
import {
  EditAccountProfileBody,
  EditAccountProfileHeader,
  ViewAccountProfileBody,
  ViewAccountProfileHeader,
} from "./fragments";
import { useEffect, useState } from "react";
import {
  useCreateAccountMutation,
  useFetchAccountInfoQuery,
  useFetchAccountLogoUploadPreSignedUrlQuery,
  useUpdateProfileAndAddressMutation,
  useUploadFileToS3Mutation,
} from "../../api";
import { useAtom } from "jotai";
import {
  isAccountProfileBodyEditModeAtom,
  isCreateAccountModeAtom,
  selectedAccountAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
} from "../../atoms";
import { AccountProfileInfo } from "../../types";
import { AccountModel } from "../../models";
import { objectDiff } from "../../utils";
import { useNavigate } from "react-router-dom";
import { SadmAccountsRoute } from "../../routes";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  boxShadow: "none",
}));

export const AccountProfile = () => {
  const [isHeaderEditMode, setIsHeaderEditMode] = useState(false);
  const [isBodyEditMode, setIsBodyEditMode] = useAtom(
    isAccountProfileBodyEditModeAtom
  );
  const [logoName, setLogoName] = useState<string>();
  const [logoFile, setLogoFile] = useState<File>();
  const [logoUploadPreSignedUrl, setLogoUploadPreSignedUrl] =
    useState<string>();
  const [isCreateAccountMode, setIsCreateAccountMode] = useAtom(
    isCreateAccountModeAtom
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [, setSelectedAccount] = useAtom(selectedAccountAtom);
  const [profileInfo, setProfileInfo] = useState<AccountProfileInfo>();
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const fetchAccountInfoQuery = useFetchAccountInfoQuery(
    selectedOrgId,
    selectedAccountId || 0,
    { contacts: true },
    { enabled: !isCreateAccountMode && selectedAccountId !== undefined }
  );
  const fetchAccountLogoUploadPreSignedUrlQuery =
    useFetchAccountLogoUploadPreSignedUrlQuery(
      selectedOrgId,
      selectedAccountId || 0,
      logoName || "logo.png",
      { enabled: false }
    );
  const updateProfileAndAddressMutation = useUpdateProfileAndAddressMutation();
  const uploadFileToS3Mutation = useUploadFileToS3Mutation();
  const createAccountMutation = useCreateAccountMutation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsHeaderEditMode(isCreateAccountMode);
    if (isCreateAccountMode) setIsBodyEditMode(isCreateAccountMode);
    else setIsBodyEditMode(false);
  }, [isCreateAccountMode]);

  useEffect(() => {
    if (
      (logoName && !isCreateAccountMode) ||
      (logoName && isCreateAccountMode && selectedAccountId !== 0)
    ) {
      fetchAccountLogoUploadPreSignedUrlQuery.refetch();
    }
  }, [logoName, selectedAccountId]);

  useEffect(() => {
    if (fetchAccountInfoQuery.isSuccess) {
      const profileInfo = AccountModel.buildAccountProfile(
        fetchAccountInfoQuery.data
      );
      setProfileInfo(profileInfo);
      setSelectedAccount({
        orgId: selectedOrgId,
        accountId: profileInfo.accountId,
        accountName: profileInfo.accountName,
        logo: profileInfo.logo,
        status: profileInfo.status,
      });
    }
  }, [
    fetchAccountInfoQuery.isSuccess,
    fetchAccountInfoQuery.isError,
    fetchAccountInfoQuery.data,
    fetchAccountInfoQuery.error,
    fetchAccountInfoQuery.isLoading,
  ]);

  useEffect(() => {
    if (updateProfileAndAddressMutation.isSuccess) {
      fetchAccountInfoQuery.refetch();

      if (isHeaderEditMode) setIsHeaderEditMode(false);
      if (isBodyEditMode) setIsBodyEditMode(false);

      setToastOptions({
        open: true,
        message: "Profile updated",
        severity: "success",
      });
    }

    if (updateProfileAndAddressMutation.isError) {
      if (isHeaderEditMode) setIsHeaderEditMode(false);
      if (isBodyEditMode) setIsBodyEditMode(false);

      if (updateProfileAndAddressMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Name already taken",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    updateProfileAndAddressMutation.isSuccess,
    updateProfileAndAddressMutation.isError,
    updateProfileAndAddressMutation.isLoading,
    updateProfileAndAddressMutation.data,
    updateProfileAndAddressMutation.error,
  ]);

  useEffect(() => {
    if (
      fetchAccountLogoUploadPreSignedUrlQuery.isSuccess ||
      fetchAccountLogoUploadPreSignedUrlQuery.isFetched
    ) {
      const preSignedUrl = fetchAccountLogoUploadPreSignedUrlQuery.data;

      setLogoUploadPreSignedUrl(preSignedUrl);
    }

    if (fetchAccountLogoUploadPreSignedUrlQuery.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    fetchAccountLogoUploadPreSignedUrlQuery.isSuccess,
    fetchAccountLogoUploadPreSignedUrlQuery.isError,
    fetchAccountLogoUploadPreSignedUrlQuery.isLoading,
    fetchAccountLogoUploadPreSignedUrlQuery.data,
    fetchAccountLogoUploadPreSignedUrlQuery.error,
    fetchAccountLogoUploadPreSignedUrlQuery.isFetched,
    fetchAccountLogoUploadPreSignedUrlQuery.isFetching,
  ]);

  useEffect(() => {
    if (uploadFileToS3Mutation.isError) {
      setToastOptions({
        open: true,
        message: "Error while uploading logo",
        severity: "error",
      });
    }
  }, [
    uploadFileToS3Mutation.isSuccess,
    uploadFileToS3Mutation.isError,
    uploadFileToS3Mutation.isLoading,
    uploadFileToS3Mutation.data,
    uploadFileToS3Mutation.error,
  ]);

  useEffect(() => {
    if (createAccountMutation.isSuccess) {
      const accountId = createAccountMutation.data;
      setIsCreateAccountMode(false);
      setIsBodyEditMode(true);
      navigate(`${SadmAccountsRoute.path}/${accountId.toString()}`);
      setToastOptions({
        open: true,
        message: "Account created",
        severity: "success",
      });
    }

    if (createAccountMutation.isError) {
      if (createAccountMutation.error.status === 409) {
        setToastOptions({
          open: true,
          message: "Name already taken",
          severity: "error",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    createAccountMutation.isSuccess,
    createAccountMutation.isError,
    createAccountMutation.isLoading,
    createAccountMutation.data,
    createAccountMutation.error,
  ]);

  useEffect(() => {
    if (logoUploadPreSignedUrl && logoFile) {
      uploadFileToS3Mutation.mutate({
        file: logoFile,
        signedUrl: logoUploadPreSignedUrl,
      });
    }
  }, [logoUploadPreSignedUrl]);

  const handleEditHeader = ({
    data,
    logo,
  }: {
    data: Pick<AccountProfileInfo, "accountName" | "logo">;
    logo?: File;
  }) => {
    const changedProps = objectDiff<AccountProfileInfo>(profileInfo, data);

    if (logo && changedProps.logo) {
      setLogoName(changedProps.logo);
      setLogoFile(logo);
    }

    if (Object.keys(changedProps).length !== 0) {
      if (isCreateAccountMode && changedProps.accountName) {
        createAccountMutation.mutate({
          orgId: selectedOrgId,
          accountInfo: {
            accountName: changedProps.accountName,
            logo: changedProps.logo,
          },
        });
      } else {
        updateProfileAndAddressMutation.mutate({
          orgId: selectedOrgId,
          accountId: selectedAccountId || 0,
          ...changedProps,
        });
        setIsHeaderEditMode(false);
      }
    } else {
      setIsHeaderEditMode(false);
      setToastOptions({
        open: true,
        message: "No changes detected",
        severity: "error",
      });
    }
  };

  const handleEditBody = (
    data: Omit<AccountProfileInfo, "logo" | "accountName" | "accountId">
  ) => {
    const changedProps = objectDiff(profileInfo, data);

    if (Object.keys(changedProps).length !== 0) {
      updateProfileAndAddressMutation.mutate({
        orgId: selectedOrgId,
        accountId: selectedAccountId || 0,
        ...changedProps,
      });
      setIsBodyEditMode(false);
    } else {
      setIsBodyEditMode(false);
      setToastOptions({
        open: true,
        message: "No changes detected",
        severity: "error",
      });
    }
  };

  return (
    <Grid
      container
      direction={"column"}
      spacing={3}
      sx={{
        width: "100%",
        height: "100%",
        direction: "row",
        borderRadius: "10px",
      }}
    >
      <Grid
        container
        size={12}
        direction={"row"}
        sx={{
          flexGrow: 1,
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px",
        }}
      >
        <Grid size={12}>
          {(isHeaderEditMode || isCreateAccountMode) && (
            <Fade
              timeout={350}
              in={isHeaderEditMode || isCreateAccountMode}
              style={{
                height: "100%",
              }}
            >
              <div>
                <EditAccountProfileHeader
                  defaultValues={!isCreateAccountMode ? profileInfo : undefined}
                  handleEdit={handleEditHeader}
                  handleDiscard={() => {
                    if (!isCreateAccountMode) setIsHeaderEditMode(false);
                  }}
                  loading={createAccountMutation.isLoading}
                />
              </div>
            </Fade>
          )}

          {!isHeaderEditMode && !isCreateAccountMode && (
            <Fade
              timeout={350}
              in={!isHeaderEditMode}
              style={{
                height: "100%",
              }}
            >
              <div>
                <ViewAccountProfileHeader
                  isLoading={
                    (!isCreateAccountMode &&
                      (fetchAccountInfoQuery.isLoading ||
                        fetchAccountInfoQuery.isFetching ||
                        uploadFileToS3Mutation.isLoading ||
                        !profileInfo)) ||
                    updateProfileAndAddressMutation.isLoading
                  }
                  data={profileInfo}
                  handleEditMode={setIsHeaderEditMode}
                  handleUploadProfilePicture={handleEditHeader}
                />
              </div>
            </Fade>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        size={12}
        direction={"row"}
        sx={{
          flexGrow: 8,
          boxShadow: "0px 0px 4px 0px #00000040",
          borderRadius: "10px",
        }}
      >
        <Grid size={12} sx={{}}>
          <Item sx={{ height: "100%", borderRadius: "10px" }}>
            {(isBodyEditMode || isCreateAccountMode) && (
              <Fade
                timeout={350}
                in={isBodyEditMode}
                style={{
                  height: "100%",
                }}
              >
                <div>
                  <EditAccountProfileBody
                    defaultValues={
                      !isCreateAccountMode ? profileInfo : undefined
                    }
                    handleEdit={handleEditBody}
                    handleDiscard={() => {
                      setIsBodyEditMode(false);
                    }}
                  />
                </div>
              </Fade>
            )}
            {!isBodyEditMode && (
              <Fade
                timeout={350}
                in={!isBodyEditMode}
                style={{
                  height: "100%",
                }}
              >
                <div>
                  <ViewAccountProfileBody
                    isLoading={
                      (!isCreateAccountMode &&
                        (fetchAccountInfoQuery.isLoading ||
                          fetchAccountInfoQuery.isFetching ||
                          uploadFileToS3Mutation.isLoading ||
                          !profileInfo)) ||
                      updateProfileAndAddressMutation.isLoading
                    }
                    data={profileInfo}
                    handleEditMode={setIsBodyEditMode}
                  />
                </div>
              </Fade>
            )}
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
};
