import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { PdfNavigation } from "./fragments";
import { Box } from "@mui/material";

const PDFDocumentWrapper = styled.div`
  width: 100%;
  overflow: auto;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

type Props = {
  url: string;
};

export const PdfViewer = ({ url }: Props) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const navigateLeft = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  };

  const navigateRight = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const handleSwipe = (event: any) => {
    const touch = event.touches[0];
    const startX = touch.clientX;

    const handleTouchMove = (e: any) => {
      const moveX = e.touches[0].clientX;
      if (moveX - startX > 50) {
        navigateLeft();
        removeTouchListeners();
      } else if (startX - moveX > 50) {
        navigateRight();
        removeTouchListeners();
      }
    };

    const removeTouchListeners = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", removeTouchListeners);
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", removeTouchListeners);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (wrapper) {
      wrapper.addEventListener("touchstart", handleSwipe);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("touchstart", handleSwipe);
      }
    };
  }, [numPages]);

  return (
    <Box
      sx={{ position: "relative", px: 3 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={wrapperRef}
    >
      <PDFDocumentWrapper>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={pageNumber}
            scale={1}
          />
        </Document>
      </PDFDocumentWrapper>
      {isHovered && (
        <PdfNavigation
          navigateLeft={navigateLeft}
          navigateRight={navigateRight}
        />
      )}
    </Box>
  );
};
