import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button, DateRangePicker, SelectBox } from "../../common";
import {
  DateRange,
  DropdownItem,
  FilterItem,
  ReportFilters,
} from "../../../types";
import {
  selectedOrgIdAtom,
  userAtom,
  reportFilterStateAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { getDateRange } from "../../../utils/dateUtil";
import { DATE_RANGE_OPTIONS } from "../../../constants";
import { Moment } from "moment";
import {
  useFetchAccountsQuery,
  useFetchAllAccountsSummaryQuery,
  useFetchOrganizationsQuery,
} from "../../../api";
import {
  AccountModel,
  LocationModel,
  OrganizationModel,
} from "../../../models";
import Loading from "../../common/loading/Loading";
import { Filter } from "../../common/filter";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  DateRangeOptions,
  ReportType,
  Role,
  StatusCodes,
} from "../../../enums";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

type ReportsToolBarProps = {
  onDownload?: () => void;
  onGetWashDetailsReport?: () => void;
  reportType?: ReportType;
  reportFilters: ReportFilters;
  setReportFilters: React.Dispatch<React.SetStateAction<ReportFilters>>;
  resetPage: () => void;
  selectedDateRange: string;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<string>>;
};

export default function ReportsToolBar({
  onDownload,
  reportType,
  onGetWashDetailsReport,
  reportFilters,
  setReportFilters,
  resetPage,
  selectedDateRange,
  setSelectedDateRange,
}: ReportsToolBarProps) {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [queryParams] = useSearchParams();

  const [selectedOrgIdFilter, setSelectedOrgIdFilter] = useState(
    user?.orgId || 0
  );

  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });
  const fetchAccountsQuery = useFetchAccountsQuery(
    selectedOrgIdFilter ? selectedOrgIdFilter : user?.orgId || 0
  );
  const fetchAccountsSummaryQuery = useFetchAllAccountsSummaryQuery(
    selectedOrgIdFilter ? selectedOrgIdFilter : user?.orgId || 0
  );

  const [filterValues, setFilterValues] = useAtom(reportFilterStateAtom);
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);
  const [locationsDropdown, setLocationsDropdown] = useState<DropdownItem[]>(
    []
  );
  const [summary, setSummary] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);

  const filters = useMemo<FilterItem[]>(() => {
    const baseFilters: FilterItem[] = [
      {
        type: "list",
        label: "Account",
        values: accountsDropdown,
        keyword: "accountId",
      },
    ];

    if (user?.roleCode === Role.SuperAdmin) {
      switch (reportType) {
        case ReportType.WashDetails:
          return [
            {
              type: "list",
              label: "Organization",
              values: organizationsDropdown,
              keyword: "orgId",
            },
            ...baseFilters,
            {
              type: "multiList",
              label: "Location",
              values: locationsDropdown,
              keyword: "locationNumber",
            },
          ];
        default:
          return [
            {
              type: "list",
              label: "Organization",
              values: organizationsDropdown,
              keyword: "orgId",
            },
            ...baseFilters,
          ];
      }
    }
    switch (reportType) {
      case ReportType.WashDetails:
        return [
          ...baseFilters,
          {
            type: "multiList",
            label: "Location",
            values: locationsDropdown,
            keyword: "locationNumber",
          },
        ];
      default:
        return baseFilters;
    }
  }, [
    user?.roleCode,
    organizationsDropdown,
    accountsDropdown,
    locationsDropdown,
    reportType,
  ]);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const dateRanges: DropdownItem[] = [
    ...DATE_RANGE_OPTIONS.map((option) => ({
      key: option,
      value: option.replace(/\s+/g, "").replace(/^\w/, (c) => c.toLowerCase()),
    })),
  ];

  const handleApplyFilters = () => {
    const appliedFilters: ReportFilters = { ...reportFilters };
    filters.forEach((filter) => {
      const filterKeyword = filter.keyword as keyof ReportFilters;
      const filterValue = filterValues[filter.keyword];
      if (filterKeyword === "orgId") {
        setSelectedOrgId(filterValue);
      }
      if (filterValue !== undefined && filterValue !== null) {
        appliedFilters[filterKeyword] = filterValue;
      } else {
        delete appliedFilters[filterKeyword];
      }
    });
    setReportFilters(appliedFilters);
    handleFilterClose();
  };

  const handleOnFilterChange = (changedFilter: { [key: string]: any }) => {
    const keyName = Object.keys(changedFilter)[0];
    if (keyName === "accountId") {
      summary.map((summary) => {
        if (summary.accountId === changedFilter?.accountId) {
          const dropdown = LocationModel.buildLocationDropdown(
            summary.locations
          );
          setLocationsDropdown(dropdown);
        }
      });

      setFilterValues((prev) => ({
        ...prev,
        [keyName]: changedFilter?.accountId,
      }));
    }
    if (keyName === "orgId") {
      setFilterValues({
        [keyName]: changedFilter.orgId,
      });
    }
  };

  const handleDateChange = (selectedDateRange: string) => {
    resetPage();
    setSelectedDateRange(selectedDateRange);
    if (selectedDateRange !== "customDate") {
      const dateRange: DateRange = getDateRange(selectedDateRange);
      setReportFilters({
        ...reportFilters,
        fromDate: dateRange.startDate.toString(),
        toDate: dateRange.endDate.toString(),
        dateRange: Object.values(DateRangeOptions).includes(
          selectedDateRange as DateRangeOptions
        )
          ? selectedDateRange
          : undefined,
      });
    } else {
      setReportFilters({
        ...reportFilters,
        dateRange: selectedDateRange,
      });
    }
  };

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (!startDate) {
      startDate = moment(reportFilters.fromDate);
    }
    if (!endDate) {
      endDate = moment(reportFilters.toDate);
    }
    if (startDate && endDate) {
      const startDateStartOfTheDay = startDate.startOf("day").toISOString();
      const endDateEndOfTheDay = endDate.endOf("day").toISOString();

      resetPage();
      setReportFilters({
        ...reportFilters,
        fromDate: startDateStartOfTheDay,
        toDate: endDateEndOfTheDay,
      });
    }
  };

  useEffect(() => {
    if (user?.orgId) {
      setSelectedOrgIdFilter(user.orgId);
    }
  }, [user]);

  useEffect(() => {
    handleApplyFilters();
    return () => {
      const appliedFilters: ReportFilters = {};
      filters.forEach((filter) => {
        const filterKeyword = filter.keyword as keyof ReportFilters;
        const filterValue = filterValues[filter.keyword];
        if (filterKeyword === "orgId") {
          if (filterValue !== undefined && filterValue !== null) {
            appliedFilters[filterKeyword] = filterValue;
          } else {
            delete appliedFilters[filterKeyword];
          }
        }
      });
      setFilterValues({});
      setReportFilters({ ...reportFilters, ...appliedFilters });
    };
  }, []);

  useEffect(() => {
    if (user?.roleCode === Role.Admin || user?.roleCode === Role.SuperAdmin) {
      setSelectedOrgIdFilter(filterValues["orgId"]);
      setLocationsDropdown([]);
    } else setSelectedOrgIdFilter(selectedOrgId);
  }, [filterValues["orgId"]]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      const orgId = queryParams.get("orgId")
        ? Number(queryParams.get("orgId"))
        : fetchOrganizationsQuery.data[0].orgId;
      setSelectedOrgId(orgId);
      setSelectedOrgIdFilter(orgId);
      const filters = {
        ...filterValues,
        orgId: orgId,
      };
      setFilterValues(filters);
      setReportFilters({
        ...reportFilters,
        orgId: orgId,
      });
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
      const error = fetchOrganizationsQuery.error as {
        status: number;
      };
      if (error.status === StatusCodes.Forbidden) {
        const filters = {};
        setFilterValues(filters);
      }
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
    fetchOrganizationsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const dropdown = AccountModel.buildAccountsDropdown(
        fetchAccountsQuery.data
      );
      setAccountsDropdown(dropdown);
    } else if (fetchAccountsQuery.isError) {
      setAccountsDropdown([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isError,
  ]);

  useEffect(() => {
    if (fetchAccountsSummaryQuery.isSuccess) {
      setSummary(fetchAccountsSummaryQuery.data);
    } else if (fetchAccountsQuery.isError) {
      setSummary([]);
    }
  }, [
    fetchAccountsSummaryQuery.isSuccess,
    fetchAccountsSummaryQuery.data,
    fetchAccountsSummaryQuery.isError,
  ]);

  useEffect(() => {
    if (user?.roleCode === Role.SuperAdmin) {
      const filters = {
        orgId: organizationsDropdown[0]?.value || user?.orgId || 0,
      };
      setFilterValues(filters);
    }
  }, [reportType]);

  useEffect(() => {
    const accountId = Number(queryParams.get("accountId")) || undefined;
    if (accountId) {
      summary.map((summary) => {
        if (summary.accountId === accountId) {
          const dropdown = LocationModel.buildLocationDropdown(
            summary.locations
          );
          setLocationsDropdown(dropdown);
        }
      });
    }
  }, [summary]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pl={1}
          size={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}
          container
          alignItems="flex-end"
          justifyContent={"flex-start"}
        >
          <Grid2 size={{ lg: 4.5, sm: 6, xs: 12 }}>
            <SelectBox
              label="Date"
              name="date"
              dropdown={dateRanges}
              value={selectedDateRange}
              onChange={(e) => handleDateChange(e.target.value)}
              sx={{ width: "100%", height: "33px" }}
            />
          </Grid2>
          {selectedDateRange === "customDate" && (
            <Grid2 size={{ lg: 6, sm: 12, xs: 12 }} alignSelf={"end"}>
              <DateRangePicker
                start={moment(reportFilters.fromDate)}
                end={moment(reportFilters.toDate)}
                onDateChange={(startDate, endDate) => {
                  handleCustomDateChange(startDate, endDate);
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2
          pr={1}
          container
          alignItems="center"
          size={{ xl: 6, lg: 6, md: 12, xs: 12 }}
          justifyContent="flex-end"
        >
          <Grid2 size={{ xs: 12, sm: 4, lg: 4 }}>
            <Button
              label={
                reportType === ReportType.WashDetails
                  ? "Download as CSV"
                  : "Download"
              }
              variantType={"secondary"}
              icon={FileDownloadOutlinedIcon}
              sx={{
                width: "100%",
                height: "30px",
                padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
              }}
              onClick={onDownload}
            />
          </Grid2>
          {reportType === ReportType.WashDetails && reportFilters.accountId && (
            <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
              <Button
                label={"Download"}
                variantType={"secondary"}
                icon={FileDownloadOutlinedIcon}
                sx={{
                  width: "100%",
                  height: "30px",
                  padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
                }}
                onClick={onGetWashDetailsReport}
              />
            </Grid2>
          )}
          <Grid2 size={{ xs: 1, sm: 1, lg: 1 }} sx={{ flexBasis: "content" }}>
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge
                badgeContent={Object.values(filterValues).length}
                color="secondary"
              >
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <>
          <Filter
            onApplyFilters={() => {
              handleApplyFilters();
              resetPage();
            }}
            onFilterChange={handleOnFilterChange}
            filters={filters}
            onClose={handleFilterClose}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            preservedFilters={["orgId"]}
          />
          {(fetchAccountsQuery.isLoading ||
            fetchOrganizationsQuery.isLoading) && (
            <Loading
              message=" "
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
                backgroundColor: "rgba(255, 255, 255, 0.6)",
              }}
            />
          )}
        </>
      </Popover>
    </Box>
  );
}
