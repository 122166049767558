import { Grid2 as Grid } from "@mui/material";
import { Address, OrganizationAddressInfo } from "../../types";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  useFetchOrganizationInfoQuery,
  useUpdateOrganizationAddressMutation,
} from "../../api";
import { selectedOrgIdAtom, toastOptionsAtom } from "../../atoms";
import { OrganizationModel } from "../../models";
import { EditAddressInfo, ViewAddressInfo } from "./fragments";

export const OrganizationAddress = () => {
  const [isPrimaryAddressEditMode, setIsPrimaryAddressEditMode] =
    useState(false);
  const [isRemittanceAddressEditMode, setIsRemittanceAddressEditMode] =
    useState(false);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);

  const [addressInfo, setAddressInfo] = useState<OrganizationAddressInfo>();
  const fetchOrganizationInfoQuery =
    useFetchOrganizationInfoQuery(selectedOrgId);
  const updateOrganizationAddressMutation =
    useUpdateOrganizationAddressMutation();

  useEffect(() => {
    if (fetchOrganizationInfoQuery.isSuccess) {
      const addressInfo = OrganizationModel.buildOrganizationAddresses(
        fetchOrganizationInfoQuery.data
      );
      setAddressInfo(addressInfo);
    }
  }, [
    fetchOrganizationInfoQuery.isSuccess,
    fetchOrganizationInfoQuery.isError,
    fetchOrganizationInfoQuery.data,
    fetchOrganizationInfoQuery.error,
    fetchOrganizationInfoQuery.isLoading,
  ]);

  useEffect(() => {
    if (updateOrganizationAddressMutation.isSuccess) {
      fetchOrganizationInfoQuery.refetch();

      if (isRemittanceAddressEditMode) setIsRemittanceAddressEditMode(false);
      if (isPrimaryAddressEditMode) setIsPrimaryAddressEditMode(false);

      setToastOptions({
        open: true,
        message: "Address updated",
        severity: "success",
      });
    }

    if (updateOrganizationAddressMutation.isError) {
      if (isRemittanceAddressEditMode) setIsRemittanceAddressEditMode(false);
      if (isPrimaryAddressEditMode) setIsPrimaryAddressEditMode(false);

      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateOrganizationAddressMutation.isSuccess,
    updateOrganizationAddressMutation.isError,
    updateOrganizationAddressMutation.isLoading,
    updateOrganizationAddressMutation.data,
    updateOrganizationAddressMutation.error,
  ]);

  const handleEditPrimaryAddress = (data: Address) => {
    if (isPrimaryAddressEditMode) setIsPrimaryAddressEditMode(false);
    updateOrganizationAddressMutation.mutate({
      orgId: selectedOrgId,
      primaryAddress: data,
    });
  };

  const handleEditRemittanceAddress = (data: Address) => {
    if (isRemittanceAddressEditMode) setIsRemittanceAddressEditMode(false);
    updateOrganizationAddressMutation.mutate({
      orgId: selectedOrgId,
      remittanceAddress: data,
    });
  };

  const handleDiscardEditPrimaryAddress = () => {
    setIsPrimaryAddressEditMode(false);
  };

  const handleDiscardEditRemittanceAddress = () => {
    setIsRemittanceAddressEditMode(false);
  };

  return (
    <Grid
      container
      direction={"row"}
      spacing={3}
      gap={3}
      sx={{
        direction: "row",
        borderRadius: "10px",
        px: "4px",
      }}
    >
      {!isPrimaryAddressEditMode ? (
        <ViewAddressInfo
          data={addressInfo?.primaryAddress}
          title={"Primary Address"}
          isLoading={
            fetchOrganizationInfoQuery.isLoading ||
            fetchOrganizationInfoQuery.isFetching ||
            updateOrganizationAddressMutation.isLoading
          }
          handleEditMode={setIsPrimaryAddressEditMode}
        />
      ) : (
        addressInfo && (
          <EditAddressInfo
            data={addressInfo.primaryAddress}
            handleEdit={handleEditPrimaryAddress}
            handleDiscard={handleDiscardEditPrimaryAddress}
          />
        )
      )}
      {!isRemittanceAddressEditMode ? (
        <ViewAddressInfo
          data={addressInfo?.remittanceAddress}
          title={"Remittance Address"}
          isLoading={
            fetchOrganizationInfoQuery.isLoading ||
            fetchOrganizationInfoQuery.isFetching ||
            updateOrganizationAddressMutation.isLoading
          }
          handleEditMode={setIsRemittanceAddressEditMode}
        />
      ) : (
        addressInfo && (
          <EditAddressInfo
            data={addressInfo.remittanceAddress}
            handleEdit={handleEditRemittanceAddress}
            handleDiscard={handleDiscardEditRemittanceAddress}
          />
        )
      )}
    </Grid>
  );
};
