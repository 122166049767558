import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button, DateRangePicker, SelectBox } from "../../../common";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import {
  DateRange,
  DropdownItem,
  FilterItem,
  InvoiceFilters,
} from "../../../../types";
import { DateRangeOptions, InvoiceStatus, Role } from "../../../../enums";
import {
  dpUserAtom,
  invoiceFilterStateAtom,
  selectedAccountIdAtom,
} from "../../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { getDateRange } from "../../../../utils/dateUtil";
import {
  CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN,
  DATE_RANGE_OPTIONS,
} from "../../../../constants";
import moment, { Moment } from "moment";
import { Filter } from "../../../common/filter";
import { theme } from "../../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";

type InvoiceToolbarProps = {
  invoiceFilters: InvoiceFilters;
  selectedDateRange: string;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<string>>;
  setInvoiceFilters: React.Dispatch<React.SetStateAction<InvoiceFilters>>;
  onRefresh?: () => void;
  resetPage: () => void;
};

export const InvoiceToolbar = ({
  invoiceFilters,
  selectedDateRange,
  setSelectedDateRange,
  setInvoiceFilters,
  onRefresh,
  resetPage,
}: InvoiceToolbarProps) => {
  const [user] = useAtom(dpUserAtom);
  const [invoiceStatusDropDown, setInvoiceStatusDropDown] = useState<
    DropdownItem[]
  >([]);
  const [filterValues, setFilterValues] = useAtom(invoiceFilterStateAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);
  const dateRanges: DropdownItem[] = [
    { key: "Select", value: "select" },
    ...DATE_RANGE_OPTIONS.map((option) => ({
      key: option,
      value: option.replace(/\s+/g, "").replace(/^\w/, (c) => c.toLowerCase()),
    })),
  ];

  const filters: FilterItem[] = [
    {
      type: "list",
      label: "Status",
      keyword: "status",
      values: invoiceStatusDropDown,
    },
  ];

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilters = () => {
    const appliedFilters: InvoiceFilters = { ...invoiceFilters };
    filters.forEach((filter) => {
      const filterKeyword = filter.keyword as keyof InvoiceFilters;
      const filterValue = filterValues[filter.keyword];
      if (filterValue !== undefined && filterValue !== null) {
        appliedFilters[filterKeyword] = filterValue;
      } else {
        delete appliedFilters[filterKeyword];
      }
    });
    setInvoiceFilters({ ...appliedFilters });
    handleFilterClose();
  };

  const generateInvoiceStatusDropdownItems = (): DropdownItem[] => {
    return CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN;
  };

  const handleDateChange = (selectedDateRange: string) => {
    resetPage();
    setSelectedDateRange(selectedDateRange);
    if (selectedDateRange !== "customDate") {
      const dateRange: DateRange = getDateRange(selectedDateRange);
      setInvoiceFilters({
        ...invoiceFilters,
        fromDate: dateRange.startDate.toString(),
        toDate: dateRange.endDate.toString(),
        dateRange: Object.values(DateRangeOptions).includes(
          selectedDateRange as DateRangeOptions
        )
          ? selectedDateRange
          : undefined,
      });
    } else {
      setInvoiceFilters({
        ...invoiceFilters,
        dateRange: selectedDateRange,
      });
    }
  };

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (!startDate) {
      startDate = moment(invoiceFilters.fromDate);
    }
    if (!endDate) {
      endDate = moment(invoiceFilters.toDate);
    }
    if (startDate && endDate) {
      const startDateStartOfTheDay = startDate.startOf("day").toISOString();
      const endDateEndOfTheDay = endDate.endOf("day").toISOString();

      resetPage();
      setInvoiceFilters({
        ...invoiceFilters,
        fromDate: startDateStartOfTheDay,
        toDate: endDateEndOfTheDay,
      });
    }
  };

  useEffect(() => {
    const dropdownItems = generateInvoiceStatusDropdownItems();
    setInvoiceStatusDropDown(dropdownItems);
  }, []);

  useEffect(() => {
    handleApplyFilters();
    const filterValues = {
      status: CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value,
    };
    setFilterValues(filterValues);
    setInvoiceFilters((prev) => ({
      ...prev,
      filterValues,
    }));
    return () => {
      setFilterValues({});
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pl={1}
          size={{ lg: 6, md: 12, xs: 12 }}
          container
          alignItems="flex-end"
          justifyContent={"flex-start"}
        >
          <Grid2 size={{ lg: 4.5, sm: 6, xs: 12 }}>
            <SelectBox
              label="Date"
              name="date"
              dropdown={dateRanges}
              value={selectedDateRange}
              onChange={(e) => handleDateChange(e.target.value)}
              sx={{ width: "100%", height: "33px" }}
            />
          </Grid2>
          {selectedDateRange === "customDate" && (
            <Grid2 size={{ lg: 12, sm: 12, xs: 12 }} alignSelf={"end"}>
              <DateRangePicker
                start={moment(invoiceFilters.fromDate)}
                end={moment(invoiceFilters.toDate)}
                onDateChange={(startDate, endDate) => {
                  handleCustomDateChange(startDate, endDate);
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2
          pr={1}
          size={{ lg: 6, md: 12, xs: 12 }}
          container
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-end", lg: "flex-end" }}
        >
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Button
              label={"Refresh"}
              variantType={"secondary"}
              icon={AutorenewOutlinedIcon}
              onClick={onRefresh}
              sx={{ height: "30px", p: 1, width: "100%" }}
            />
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 4, lg: 1 }}
            sx={{ flexBasis: { xs: "content", sm: "content", lg: "content" } }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge
                badgeContent={Object.values(filterValues).length}
                color="secondary"
              >
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Filter
          onApplyFilters={() => {
            handleApplyFilters();
            resetPage();
          }}
          filters={filters}
          onClose={handleFilterClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          preservedFilters={["status"]}
        />
      </Popover>
    </Box>
  );
};
