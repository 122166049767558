import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  Pagination,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  useFetchAccountsQuery,
  useFetchOmnitokensQuery,
  useFetchOrganizationsQuery,
} from "../../api";
import { useAtom } from "jotai";
import {
  organizationsDropdownItemsAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
  userAtom,
} from "../../atoms";
import { useEffect, useState } from "react";
import { ConfirmationModal, HorizontalStepper } from "../common";
import { theme } from "../../utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { DropdownItem, Omnitoken } from "../../types";
import { CardInfoTile, ManageCardsToolbar } from "./fragments";
import { Role } from "../../enums";
import { AccountModel, OrganizationModel } from "../../models";
import { useSearchParams } from "react-router-dom";
import {
  ACCOUNT_ID_QUERY_PARAM_KEY,
  ORG_ID_QUERY_PARAM_KEY,
} from "../../routes";
import { HostedPaymentsPage } from "../payment/fragments";

export const ManageCards = () => {
  const [user] = useAtom(userAtom);
  const [queryParams, setQueryParams] = useSearchParams();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [savedCards, setSavedCards] = useState<Omnitoken[]>([]);
  const [paginatedSavedCards, setPaginatedSavedCards] = useState<Omnitoken[]>();
  const [organizationsDropdown, setOrganizationsDropdown] = useAtom(
    organizationsDropdownItemsAtom
  );
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId, setSelectedAccountId] = useAtom(
    selectedAccountIdAtom
  );
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const fetchOmnitokensQuery = useFetchOmnitokensQuery(
    selectedOrgId,
    selectedAccountId || 0,
    {
      enabled: selectedOrgId && selectedAccountId ? true : false,
    }
  );
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });
  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgId, {
    enabled: selectedOrgId ? true : false,
  });

  const handleCloseWrapper = () => {
    setIsDialogOpen(false);
  };

  const getPaginatedItems = (
    array: Omnitoken[],
    page: number,
    pageSize: number
  ) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return (array || []).slice(startIndex, endIndex);
  };

  const handleClick = (orgId: number) => {
    console.log("clicked");
  };

  const handleCreate = () => {
    setIsDialogOpen(true);
  };

  const handleChangeOrganizationDropdown = (event: SelectChangeEvent) => {
    const orgId = Number(event.target.value);
    queryParams.set(ORG_ID_QUERY_PARAM_KEY, orgId.toString());
    setQueryParams(queryParams);
    setSelectedOrgId(orgId);
  };

  const handleChangeAccountsDropdown = (event: SelectChangeEvent) => {
    const accountId = Number(event.target.value);
    queryParams.set(ACCOUNT_ID_QUERY_PARAM_KEY, accountId.toString());
    setQueryParams(queryParams);
    setSelectedAccountId(accountId);
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setSelectedOrgId(0);
    setIsModalOpen(false);
  };

  const handleClickDelete = (id: number) => {
    handleOpenModal();
  };

  const handleConfirmDelete = () => {
    handleCloseModal();
  };

  useEffect(() => {
    if (isXs) setPageSize(10);
    else if (isSm) setPageSize(10);
    else if (isLg) setPageSize(12);
    else if (isXl) setPageSize(16);
  }, [isXs, isSm, isLg, isXl]);

  useEffect(() => {
    if (savedCards) {
      setPaginatedSavedCards(getPaginatedItems(savedCards, page, pageSize));
    }
  }, [savedCards, page, pageSize]);

  useEffect(() => {
    if (fetchOmnitokensQuery.isSuccess) {
      setSavedCards(fetchOmnitokensQuery.data.omnitokens);
    }

    if (fetchOmnitokensQuery.isError || fetchOmnitokensQuery.isLoading) {
      setSavedCards([]);
    }
  }, [
    fetchOmnitokensQuery.isSuccess,
    fetchOmnitokensQuery.isError,
    fetchOmnitokensQuery.data,
    fetchOmnitokensQuery.error,
    fetchOmnitokensQuery.isLoading,
    fetchOmnitokensQuery.isFetching,
    fetchOmnitokensQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      const orgIdFromQueryParams = queryParams.get("orgId");
      const orgIdFromQueryParamsExists = fetchOrganizationsQuery.data.some(
        (e) => orgIdFromQueryParams && e.orgId === Number(orgIdFromQueryParams)
      );
      setOrganizationsDropdown(dropdown);
      setSelectedOrgId(
        orgIdFromQueryParamsExists
          ? Number(orgIdFromQueryParams)
          : fetchOrganizationsQuery.data[0].orgId
      );
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const dropdown = AccountModel.buildAccountsDropdown(
        fetchAccountsQuery.data
      );
      const accountIdFromQueryParams = queryParams.get("accountId");
      const accountIdFromQueryParamsExists = fetchAccountsQuery.data.some(
        (e) =>
          accountIdFromQueryParams &&
          e.accountId === Number(accountIdFromQueryParams)
      );
      setAccountsDropdown(dropdown);
      setSelectedAccountId(
        accountIdFromQueryParamsExists
          ? Number(accountIdFromQueryParams)
          : fetchAccountsQuery.data[0].accountId
      );
    } else if (fetchAccountsQuery.isError) {
      setAccountsDropdown([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isError,
  ]);

  return (
    <>
      <Box sx={{ width: "100%", height: "calc(100vh - 150px)" }}>
        <Box
          sx={{
            overflow: "auto",
            px: "5px",
            display: "flex",
            width: "100%",
            height: "100%",
            flexGrow: 1,
            flexDirection: "column",
          }}
        >
          <ManageCardsToolbar
            handleCreate={handleCreate}
            organizationsDropdown={organizationsDropdown}
            organizationsDropdownValue={selectedOrgId}
            organizationsDropdownOnChange={handleChangeOrganizationDropdown}
            accountsDropdown={accountsDropdown}
            accountsDropdownValue={selectedAccountId}
            accountsDropdownOnChange={handleChangeAccountsDropdown}
          />
          <Grid
            container
            direction={"row"}
            spacing={2}
            sx={{
              width: "100%",
              height: "100%",
              flexGrow: 1,
              alignItems:
                fetchOmnitokensQuery.isLoading ||
                fetchOmnitokensQuery.isRefetching ||
                (fetchOmnitokensQuery.isSuccess && !paginatedSavedCards) ||
                fetchOmnitokensQuery.isError
                  ? "center"
                  : "none",
              alignContent:
                fetchOmnitokensQuery.isLoading ||
                fetchOmnitokensQuery.isRefetching ||
                (fetchOmnitokensQuery.isSuccess && !paginatedSavedCards) ||
                fetchOmnitokensQuery.isError
                  ? "none"
                  : "baseline",
            }}
          >
            {fetchOmnitokensQuery.isLoading ||
            fetchOmnitokensQuery.isFetching ||
            fetchOmnitokensQuery.isRefetching ||
            (fetchOmnitokensQuery.isSuccess && !paginatedSavedCards) ? (
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (fetchOmnitokensQuery.isSuccess &&
                savedCards &&
                paginatedSavedCards &&
                paginatedSavedCards.length === 0) ||
              !paginatedSavedCards ||
              fetchOmnitokensQuery.isError ? (
              <Grid
                size={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                Nothing to show
              </Grid>
            ) : (
              paginatedSavedCards.map((item, index) => (
                <Grid key={index} size={{ lg: 4, md: 6, sm: 6, xs: 12, xl: 3 }}>
                  <CardInfoTile
                    data={item}
                    handleClick={handleClick}
                    handleDelete={handleClickDelete}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50px",
          }}
        >
          <Pagination
            count={
              savedCards && savedCards.length > 0
                ? Math.ceil(savedCards.length / pageSize)
                : 1
            }
            page={page}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setPage(value);
            }}
            color="primary"
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Box>
        <ConfirmationModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          icon={
            <DeleteOutlineOutlinedIcon
              fontSize="medium"
              sx={{ color: theme.palette.primary.main }}
            />
          }
          title={"Delete Card"}
          message={"Are you sure that you want to delete this card?"}
        />
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseWrapper}
        fullWidth={true}
        maxWidth={"lg"}
        sx={{}}
      >
        {fetchOrganizationsQuery.isLoading || fetchAccountsQuery.isLoading ? (
          <Grid
            container
            size={12}
            sx={{
              width: "100%",
              height: "100%",
              minHeight: "75vh",
              direction: "row",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 0px 4px 0px #00000040",
              p: 3,
              justifyContent:
                fetchOrganizationsQuery.isLoading ||
                fetchAccountsQuery.isLoading
                  ? "center"
                  : "flex-start",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box sx={{ pt: 4, pb: 4, overflow: "auto" }}>
            <DialogTitle>
              <HorizontalStepper
                steps={["Enter Card Details"]}
                activeStep={2}
              />
              <IconButton
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  border: "none",
                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  cursor: "pointer",
                  zIndex: 1000,
                }}
                onClick={handleCloseWrapper}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <HostedPaymentsPage
                payload={undefined}
                orgId={selectedOrgId}
                authTransaction={true}
              />
            </DialogContent>
          </Box>
        )}
      </Dialog>
    </>
  );
};
