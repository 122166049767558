import { useEffect } from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { AccountMenuItem } from "../../types";
import { Box } from "@mui/material";
import {
  expandedAccountAccordionItemAtom,
  isCreateAccountModeAtom,
  isCreateButtonActiveAtom,
  isCreateContactComponentActiveAtom,
  isCreateLocationComponentActiveAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { Button } from "../common";
import { useSearchParams } from "react-router-dom";
import { ACCOUNT_DETAILS_TAB_KEY } from "../../constants";
import { accountMenuDefault } from "./AccountMenus";
import {
  ACCOUNT_ID_QUERY_PARAM_KEY,
  ORG_ID_QUERY_PARAM_KEY,
} from "../../routes";

export const AccountAccordion = ({ menu }: { menu: AccountMenuItem }) => {
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId, setSelectedAccountId] = useAtom(
    selectedAccountIdAtom
  );
  const [isCreateButtonActive, setCreateButtonInActive] = useAtom(
    isCreateButtonActiveAtom
  );
  const [isCreateAccountMode] = useAtom(isCreateAccountModeAtom);
  const [expandedAccordion, setExpandedAccordion] = useAtom(
    expandedAccountAccordionItemAtom
  );
  const [, setLocationCreateComponentActive] = useAtom(
    isCreateLocationComponentActiveAtom
  );
  const [, setContactCreateComponentActive] = useAtom(
    isCreateContactComponentActiveAtom
  );
  const [queryParams, setQueryParams] = useSearchParams();

  useEffect(() => {
    const orgId = queryParams.get(ORG_ID_QUERY_PARAM_KEY);
    const accountId = queryParams.get(ACCOUNT_ID_QUERY_PARAM_KEY);

    if (orgId) {
      setSelectedOrgId(Number(orgId));
    } else {
      queryParams.set(ORG_ID_QUERY_PARAM_KEY, String(selectedOrgId));
    }

    if (accountId) {
      setSelectedAccountId(Number(accountId));
    } else {
      queryParams.set(ACCOUNT_ID_QUERY_PARAM_KEY, String(selectedAccountId));
    }

    setQueryParams(queryParams);
  }, []);

  useEffect(() => {
    const tab = queryParams.get(ACCOUNT_DETAILS_TAB_KEY);

    if (tab === menu.name) {
      setExpandedAccordion(menu);
    }

    if (!tab) {
      setExpandedAccordion(accountMenuDefault);
    }
  }, [queryParams]);

  const handleCreate = () => {
    if (menu.name === "location") {
      setLocationCreateComponentActive(true);
    } else if (menu.name === "contact") {
      setContactCreateComponentActive(true);
    }
  };

  const handleExpansion = () => {
    setCreateButtonInActive();
    if (expandedAccordion && menu.name === expandedAccordion.name) {
      setExpandedAccordion(null);
    } else {
      setQueryParams({
        [ORG_ID_QUERY_PARAM_KEY]: String(selectedOrgId),
        ...Object.fromEntries(queryParams),
        [ACCOUNT_DETAILS_TAB_KEY]: menu.name,
      });
      setExpandedAccordion(menu);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Accordion
        disabled={isCreateAccountMode && menu.name !== "profile"}
        expanded={
          expandedAccordion !== null && menu.name === expandedAccordion.name
        }
        onChange={handleExpansion}
        slots={{ transition: Fade as AccordionSlots["transition"] }}
        slotProps={{ transition: { timeout: 400, unmountOnExit: true } }}
        sx={[
          expandedAccordion && menu.name === expandedAccordion.name
            ? {
                my: 1,
                "& .MuiAccordion-region": {
                  height: "auto",
                },
                "& .MuiAccordionDetails-root": {
                  display: "block",
                },
                pb: 4,
              }
            : {
                my: 1,
                "& .MuiAccordion-region": {
                  height: 0,
                },
                "& .MuiAccordionDetails-root": {
                  display: "none",
                },
                ...(isCreateAccountMode && {
                  "&.Mui-disabled": {
                    boxShadow: "none",
                  },
                }),
              },
        ]}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{menu.displayName}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box
            sx={{
              width: "100%",
              px: 2,
              pb: 2,
              mt: -2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {menu.allowCreate && (
              <Button
                label={"Add New " + menu.displayName}
                variantType="primary"
                sx={{
                  padding: "0.3rem",
                  px: "1rem",
                  width: "fit-content",
                }}
                disabled={isCreateButtonActive}
                onClick={handleCreate}
              />
            )}
          </Box>
          {menu.component}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
