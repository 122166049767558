import {
  Box,
  FormControl,
  Grid2 as Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "../search";
import { GridIcon, ListIcon } from "../icons";
import { useAtom } from "jotai";
import { layoutTypeAtom } from "../../atoms";
import { Button } from "../common";
import { DropdownItem } from "../../types";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../enums";

type BusinessViewToolbarProps = {
  addLabel: "organization" | "account";
  dropdown?: DropdownItem[];
  dropdownValue?: DropdownItem["value"];
  dropdownOnChange?: (event: SelectChangeEvent) => void;
  handleCreate?: () => void;
  handleSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const BusinessViewToolbar = ({
  addLabel,
  dropdown,
  dropdownValue,
  dropdownOnChange,
  handleCreate,
  handleSearch,
}: BusinessViewToolbarProps) => {
  const [layoutType, setLayoutType] = useAtom(layoutTypeAtom);

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      size={12}
      spacing={2}
      sx={{
        pb: 3,
      }}
    >
      <Grid
        size={{ xs: 3, sm: 1 }}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Grid size={6}>
          <Box
            sx={{
              ":hover": {
                cursor: "pointer",
              },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pl: 0.5,
            }}
            onClick={() => {
              setLayoutType("grid");
            }}
          >
            <GridIcon width={"40px"} active={layoutType === "grid"} />
          </Box>
        </Grid>
        <Grid size={6}>
          <Box
            sx={{
              ":hover": {
                cursor: "pointer",
              },
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pl: 0.5,
              
            }}
            onClick={() => {
              setLayoutType("list");
            }}
          >
            <ListIcon width={"40px"} active={layoutType === "list"} />
          </Box>
        </Grid>
      </Grid>
      <Grid size={{ lg: 2.5, md: 4, sm: 4, xs: 9 }}>
        <Box
          sx={{
            px: { xs: 0, sm: 1 },
            width: "100%",
          }}
        >
          <Search onInputChange={handleSearch} />
        </Box>
      </Grid>
      {dropdown && (
        <Grid size={{ lg: 2.5, md: 4, sm: 4, xs: 12 }}>
          <AllowedAccess
            roles={[Role.SuperAdmin]}
            permissions={[
              { module: Module.OrganizationList, action: Action.Read },
            ]}
          >
            <Box
              sx={{
                px: { xs: 0, sm: 1 },
                display: "flex",
                alignItems: { xs: "flex-end", sm: "center" },
                width: "100%",
              }}
            >
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                }}
              >
                {dropdownValue && (
                  <Select
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "39px",
                      width: "100%",
                    }}
                    value={dropdownValue ? dropdownValue.toString() : undefined}
                    onChange={dropdownOnChange}
                  >
                    {dropdown.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.key}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </AllowedAccess>
        </Grid>
      )}
      <Grid
        size={{ lg: dropdown ? 6 : 8.5, sm: dropdown ? 3 : 7, xs: 12 }}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          label={"add " + addLabel}
          variantType="primary"
          icon={AddIcon}
          sx={{
            textTransform: "capitalize !important",
          }}
          onClick={handleCreate}
        />
      </Grid>
    </Grid>
  );
};
