import {
  Box,
  FormControl,
  Grid2 as Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../../common";
import { DropdownItem } from "../../../types";
import { Action, Module, Role } from "../../../enums";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";

type ManageCardsToolbarProps = {
  handleCreate?: () => void;
  organizationsDropdown?: DropdownItem[];
  organizationsDropdownValue?: DropdownItem["value"];
  organizationsDropdownOnChange?: (event: SelectChangeEvent) => void;
  accountsDropdown?: DropdownItem[];
  accountsDropdownValue?: DropdownItem["value"];
  accountsDropdownOnChange?: (event: SelectChangeEvent) => void;
};

export const ManageCardsToolbar = ({
  handleCreate,
  organizationsDropdown,
  organizationsDropdownValue,
  organizationsDropdownOnChange,
  accountsDropdown,
  accountsDropdownValue,
  accountsDropdownOnChange,
}: ManageCardsToolbarProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");

  return (
    <Grid
      container
      size={12}
      sx={{
        pb: 3,
      }}
    >
      {organizationsDropdown && (
        <Grid size={{ lg: 2, md: 4, sm: 4, xs: 12 }}>
          <AllowedAccess
            roles={[Role.SuperAdmin]}
            permissions={[
              { module: Module.OrganizationList, action: Action.Read },
            ]}
          >
            <Box
              sx={{
                px: { xs: 0, sm: 1 },
                display: "flex",
                alignItems: { xs: "flex-end", sm: "center" },
                width: "100%",
              }}
            >
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                }}
              >
                {organizationsDropdownValue && (
                  <Select
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "39px",
                      width: "100%",
                    }}
                    value={
                      organizationsDropdownValue
                        ? organizationsDropdownValue.toString()
                        : undefined
                    }
                    onChange={organizationsDropdownOnChange}
                  >
                    {organizationsDropdown.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.key}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </AllowedAccess>
        </Grid>
      )}
      {accountsDropdown && (
        <Grid size={{ lg: 2, md: 4, sm: 4, xs: 12 }}>
          <AllowedAccess
            roles={[Role.SuperAdmin, Role.Admin]}
            permissions={[{ module: Module.AccountList, action: Action.Read }]}
          >
            <Box
              sx={{
                px: { xs: 0, sm: 1 },
                display: "flex",
                alignItems: { xs: "flex-end", sm: "center" },
                width: "100%",
              }}
            >
              <FormControl
                variant="standard"
                sx={{
                  width: "100%",
                }}
              >
                {accountsDropdownValue && (
                  <Select
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "39px",
                      width: "100%",
                    }}
                    value={
                      accountsDropdownValue
                        ? accountsDropdownValue.toString()
                        : undefined
                    }
                    onChange={accountsDropdownOnChange}
                  >
                    {accountsDropdown.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.key}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </AllowedAccess>
        </Grid>
      )}
      <Grid
        size={{ lg: 8, sm: 12, xs: 12 }}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: isSmallScreen ? "1rem" : 0,
        }}
      >
        <Button
          label={"Add Card"}
          variantType="primary"
          icon={AddIcon}
          sx={{
            textTransform: "capitalize !important",
          }}
          onClick={handleCreate}
        />
      </Grid>
    </Grid>
  );
};
