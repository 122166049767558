import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid2 as Grid } from "@mui/material";
import { Button, DatePicker, SelectBox, TextField } from "../../common";
import { INVOICE_FREQUENCIES } from "../../../constants";
import { useValidateForm } from "../../../hooks";
import { createAccountFinancialBasicSchema } from "../../../validations";
import moment, { Moment } from "moment";
import { FinancialInfo } from "../../../types";
import { useCreateAccountFinancialMutation } from "../../../api";
import { useAtom } from "jotai";
import {
  isCreateBillingModeAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
} from "../../../atoms";

type CreateFinancialInfoProps = {
  refetch: () => void;
};
export const CreateFinancialInfo = ({ refetch }: CreateFinancialInfoProps) => {
  const [invoiceDate, setInvoiceDate] = useState<Moment>(
    moment().add(1, "days")
  );
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [, setIsCreateBillingMode] = useAtom(isCreateBillingModeAtom);

  const { register, handleSubmit, errors } = useValidateForm(
    createAccountFinancialBasicSchema
  );
  const createAccountFinancialMutation = useCreateAccountFinancialMutation();

  useEffect(() => {
    if (createAccountFinancialMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Financial information saved",
        severity: "success",
      });
      setIsCreateBillingMode(false);
      refetch();
    }

    if (createAccountFinancialMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    createAccountFinancialMutation.isSuccess,
    createAccountFinancialMutation.isError,
    createAccountFinancialMutation.isLoading,
    createAccountFinancialMutation.data,
    createAccountFinancialMutation.error,
  ]);

  const handleCreateAccountFinancial = (
    data: Pick<
      FinancialInfo,
      "purchaseOrder" | "invoiceFrequency" | "quickbooksId"
    >
  ) => {
    createAccountFinancialMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      financialInfo: {
        ...data,
        invoiceDate: moment(invoiceDate)
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      },
    });
  };

  return createAccountFinancialMutation.isLoading ? (
    <Grid
      size={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      container
      direction={"column"}
      spacing={3}
      sx={{
        width: "100%",
        height: "100%",
        direction: "row",
        borderRadius: "10px",
        px: "4px",
      }}
    >
      <Box
        onSubmit={handleSubmit(handleCreateAccountFinancial)}
        component={"form"}
        noValidate
      >
        <Grid
          container
          size={12}
          direction={"row"}
          spacing={4}
          sx={{
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px",
            p: 4,
            position: "relative",
          }}
        >
          <Grid container size={{ lg: 9, xs: 12 }}>
            <Grid container size={12}>
              <TextField
                label="Quickbooks ID"
                name="quickbooksId"
                register={register}
                error={errors["quickbooksId"]}
                maxLength={50}
              />
            </Grid>
            <Grid container size={12}>
              <TextField
                label="Purchase Order"
                name="purchaseOrder"
                register={register}
                error={errors["purchaseOrder"]}
                maxLength={50}
              />
            </Grid>
            <Grid
              container
              size={{ xs: 12, sm: 6 }}
              sx={{
                px: 0,
              }}
            >
              <DatePicker
                disablePast
                minDate={moment().add(1, "day").startOf("day").utc()}
                label="Invoice Date"
                name="invoiceDate"
                value={invoiceDate}
                onChange={(e) => {
                  setInvoiceDate(e);
                }}
              />
            </Grid>
            <Grid
              container
              size={{ xs: 12, sm: 6 }}
              sx={{
                px: 0,
              }}
            >
              <SelectBox
                label="Invoice Frequency"
                name="invoiceFrequency"
                register={register}
                error={errors["invoiceFrequency"]}
                dropdown={[
                  { key: "Select", value: "select" },
                  ...INVOICE_FREQUENCIES,
                ]}
                defaultValue={0}
              />
            </Grid>
          </Grid>
          <Grid
            container
            size={{ lg: 3, xs: 12 }}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: { lg: "column", xs: "row" },
            }}
          >
            <Grid size={{ xs: 12, md: 6, lg: 12 }}>
              <Button
                type={"submit"}
                label="Save Changes"
                sx={{
                  padding: "0.5rem",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
