import { atom } from "jotai";
import { AccountBasicInfo } from "../types";

export const customerDashboardDrawerOpenAtom = atom(false);
export const currentAccountIdAtom = atom<number>(0);
export const accountsAtom = atom<AccountBasicInfo[]>([]);
export const fleetFilterStateAtom = atom<{ [key: string]: any }>([]);
export const washFilterStateAtom = atom<{ [key: string]: any }>([]);
export const invoiceFilterStateAtom = atom<{ [key: string]: any }>([]);
export const userFilterStateAtom = atom<{ [key: string]: any }>([]);
export const washTypeFilterStateAtom = atom<{ [key: string]: any }>([]);
export const reportFilterStateAtom = atom<{ [key: string]: any }>([]);
export const paymentsFilterStateAtom = atom<{ [key: string]: any }>([]);