import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button } from "../../common";
import { Search } from "../../search";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { Action, Module, Role } from "../../../enums";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import React, { useState, useMemo, useEffect } from "react";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import { Filter } from "../../common/filter";
import { DropdownItem, FilterItem } from "../../../types";
import { useAtom } from "jotai";
import { selectedOrgIdAtom, userAtom } from "../../../atoms";
import { OrganizationModel, UserModel } from "../../../models";
import { useFetchOrganizationsQuery } from "../../../api";
import { useSearchParams } from "react-router-dom";

type userToolbarProps = {
  onApplyFilters: () => void;
  handleSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedUserIds: number[];
  handleResetPassword: () => void;
  resetPage: () => void;
  filterValues: {
    [key: string]: any;
  };
  setFilterValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >;
};

export const UserToolbar = ({
  onApplyFilters,
  handleSearch,
  selectedUserIds,
  handleResetPassword,
  resetPage,
  filterValues,
  setFilterValues,
}: userToolbarProps) => {
  const [queryParams] = useSearchParams();
  const [user] = useAtom(userAtom);
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(
    Object.keys(filterValues).length || 0
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);
  const userStatusDropDown = UserModel.buildUserStatusDropdown();
  let userRoleDropDown = UserModel.buildUserRoleDropdown();
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  if (user?.roleCode !== Role.SuperAdmin) {
    userRoleDropDown = userRoleDropDown.filter(
      (role) => role.value !== Role.SuperAdmin
    );
  }

  const filters = useMemo<FilterItem[]>(() => {
    const baseFilters: FilterItem[] = [
      {
        type: "list",
        label: "Role",
        keyword: "roleCode",
        values: userRoleDropDown,
      },
      {
        type: "list",
        label: "Status",
        keyword: "employeeStatus",
        values: userStatusDropDown,
      },
    ];

    if (user?.roleCode === Role.SuperAdmin) {
      return [
        {
          type: "list",
          label: "Organization",
          values: organizationsDropdown,
          keyword: "orgId",
        },
        ...baseFilters,
      ];
    }

    return baseFilters;
  }, [
    user,
    user?.roleCode,
    organizationsDropdown,
    userRoleDropDown,
    userStatusDropDown,
  ]);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilters = () => {
    setAppliedFiltersCount(Object.keys(filterValues).length);
    onApplyFilters();
    filters.forEach((filter: { keyword: any }) => {
      const filterKeyword = filter.keyword;
      const filterValue = filterValues[filter.keyword];
      if (filterKeyword === "orgId") {
        setSelectedOrgId(filterValue);
      }
    });
    handleFilterClose();
  };

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      const orgId = queryParams.get("orgId")
        ? Number(queryParams.get("orgId"))
        : user?.orgId || 0;
      setSelectedOrgId(orgId);
      const filters = {
        ...filterValues,
        orgId: orgId,
      };
      setFilterValues(filters);
      setAppliedFiltersCount(Object.keys(filters).length);
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="end"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pr={1}
          size={{ lg: 6, md: 5, xs: 12 }}
          container
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-end", lg: "flex-end" }}
        >
          <Grid2 size={{ xs: 12, sm: 4, lg: 4 }}>
            <AllowedAccess
              roles={[Role.Admin, Role.SuperAdmin]}
              permissions={[{ module: Module.User, action: Action.Write }]}
            >
              <Button
                label={"Reset Password"}
                variantType={"secondary"}
                icon={AutorenewOutlinedIcon}
                onClick={handleResetPassword}
                sx={{ height: "30px", p: 1, width: "100%" }}
                disabled={selectedUserIds.length !== 1}
              />
            </AllowedAccess>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Search onInputChange={handleSearch} />
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 4, lg: 1 }}
            sx={{ flexBasis: { xs: "content", sm: "content", lg: "content" } }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge badgeContent={appliedFiltersCount} color="secondary">
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Filter
          onApplyFilters={() => {
            handleApplyFilters();
            resetPage();
          }}
          filters={filters}
          onClose={handleFilterClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          preservedFilters={["orgId"]}
        />
      </Popover>
    </Box>
  );
};
