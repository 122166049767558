import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import CommuteIcon from "@mui/icons-material/Commute";
import { AccountDetail, AccountLayout } from "../components/account";
import { Fleet } from "../components/fleet";
import { Invoice, InvoiceDetails, CreateInvoice } from "../components/invoice";
import { Login } from "../components/login";
import {
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  UserProfile,
  User,
} from "../components/users";
import { WashInventory } from "../components/washes";
import { Route } from "../types";
import {
  ROUTE_ADM_ACCOUNTS,
  ROUTE_ADM_DASHBOARD,
  ROUTE_ADM_INVOICES,
  ROUTE_ADM_CREATE_INVOICE,
  ROUTE_ADM_ORGANIZATION_DETAILS,
  ROUTE_ADM_REPORTS,
  ROUTE_ADM_REPORTS_ACCOUNT_ACTIVITY,
  ROUTE_ADM_REPORTS_LEAD,
  ROUTE_ADM_REPORTS_WASH_DETAILS,
  ROUTE_ADM_USERS,
  ROUTE_ADM_WASHES,
  ROUTE_ADM_PAYMENTS,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_CUST_ACCOUNT_DETAILS,
  ROUTE_CUST_DASHBOARD,
  ROUTE_CUST_FLEET,
  ROUTE_CUST_INVOICES,
  ROUTE_CUST_PAYMENTS,
  ROUTE_CUST_WASHES,
  ROUTE_ERROR,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SADM_ACCOUNTS,
  ROUTE_SADM_DASHBOARD,
  ROUTE_SADM_INVOICES,
  ROUTE_SADM_CREATE_INVOICE,
  ROUTE_SADM_ORGANIZATIONS,
  ROUTE_SADM_REPORTS,
  ROUTE_SADM_REPORTS_ACCOUNT_ACTIVITY,
  ROUTE_SADM_REPORTS_LEAD,
  ROUTE_SADM_REPORTS_WASH_DETAILS,
  ROUTE_SADM_USERS,
  ROUTE_SADM_WASHES,
  ROUTE_USER_PROFILE,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_RESET_PASSWORD,
  ROUTE_SADM_INVOICE_DETAILS,
  ROUTE_ADM_INVOICE_DETAILS,
  ROUTE_CUST_INVOICE_DETAILS,
  ROUTE_SADM_ACCOUNT_INVOICE_DETAILS,
  ROUTE_SADM_ACCOUNT_DETAILS,
  ROUTE_ADM_ACCOUNT_DETAILS,
  ROUTE_ADM_ACCOUNT_INVOICE_DETAILS,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_SADM_WASH_TYPES,
  ROUTE_CUST_DETAILS_INVOICES,
  ROUTE_SADM_ORGANIZATION_DETAILS,
  ROUTE_SADM_CREATE_ORGANIZATION,
  ORG_ID_QUERY_PARAM_KEY,
  ACCOUNT_ID_QUERY_PARAM_KEY,
  ROUTE_SADM_PAYMENTS,
  ROUTE_DIRECT_PAYMENT,
  ROUTE_SADM_MANAGE_CARDS,
} from "./constants";
import {
  MdAccountTree,
  MdDashboard,
  MdInfoOutline,
  MdLocalCarWash,
  MdMonetizationOn,
  MdOutlineDirectionsBus,
  MdPayment,
  MdPeople,
  MdReceipt,
  MdSchema,
} from "react-icons/md";
import { DashboardErrorPage, ErrorPage } from "../components/error";
import {
  AdminDashboard,
  SuperAdminDash,
  UserDashboard,
} from "../components/dashboard";
import {
  OrganizationDetail,
  OrganizationLayout,
} from "../components/organization";
import { WashTypes } from "../components/wash-types";
import { Reports } from "../components/reports";
import { ReportType } from "../enums";
import { Payments } from "../components/payments";
import { DirectPayment } from "../components/direct-payment";
import { ManageCards } from "../components/manage-cards";

export const LoginRoute: Route = {
  name: "Login",
  displayName: "login",
  path: ROUTE_LOGIN,
  component: <Login />,
  exact: true,
};

export const HomeRoute: Route = {
  name: "Home",
  displayName: "home",
  path: ROUTE_HOME,
  component: <></>,
};

export const ErrorRoute: Route = {
  name: "error",
  displayName: "Error",
  icon: <MdAccountTree />,
  path: ROUTE_ERROR,
  component: <ErrorPage />,
};

export const DashboardErrorRoute: Route = {
  name: "Dashboard Error",
  displayName: "Dashboard Error",
  icon: <MdAccountTree />,
  path: ROUTE_ERROR,
  component: <DashboardErrorPage />,
  isVisible: false,
};

export const CustomerDashboardRoute: Route = {
  name: "dashboard",
  displayName: "Dashboard",
  icon: <MdDashboard />,
  path: ROUTE_CUST_DASHBOARD,
  component: <UserDashboard />,
};

export const CustomerFleetRoute: Route = {
  name: "fleet",
  displayName: "Fleet",
  icon: <MdOutlineDirectionsBus />,
  path: ROUTE_CUST_FLEET,
  component: <Fleet />,
};

export const CustomerInvoiceDetailsRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_CUST_INVOICE_DETAILS,
  isVisible: false,
};

export const CustomerDetailsInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  path: ROUTE_CUST_DETAILS_INVOICES,
  component: <Invoice />,
  childRoutes: [CustomerInvoiceDetailsRoute],
};

export const CustomerAccountDetailRoute: Route = {
  name: "myDetails",
  displayName: "Account Details",
  icon: <MdInfoOutline />,
  path: ROUTE_CUST_ACCOUNT_DETAILS,
  component: <AccountDetail />,
  childRoutes: [CustomerDetailsInvoicesRoute],
};

export const CustomerInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  path: ROUTE_CUST_INVOICES,
  component: <Invoice />,
  childRoutes: [CustomerInvoiceDetailsRoute],
};

export const CustomerPaymentsRoute: Route = {
  name: "payments",
  displayName: "Payments",
  icon: <MdPayment />,
  component: <Payments />,
  path: ROUTE_CUST_PAYMENTS,
};

export const CustomerWashesRoute: Route = {
  name: "washes",
  displayName: "Washes",
  icon: <MdLocalCarWash />,
  path: ROUTE_CUST_WASHES,
  component: <WashInventory />,
};

export const SadmDashboardRoute: Route = {
  name: "dashboard",
  displayName: "Dashboard",
  icon: <MdDashboard />,
  component: <SuperAdminDash />,
  path: ROUTE_SADM_DASHBOARD,
};

export const SadmInvoiceDetailsRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_SADM_INVOICE_DETAILS,
  isVisible: false,
};

export const SadmCreateInvoiceRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <CreateInvoice />,
  path: ROUTE_SADM_CREATE_INVOICE,
  isVisible: false,
};

export const SadmAccountInvoiceDetailsRoute: Route = {
  name: "accountInvoiceDetails",
  displayName: "Account Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_SADM_ACCOUNT_INVOICE_DETAILS,
  isVisible: false,
};

export const SadmAccountDetailsRoute: Route = {
  name: "accountDetails",
  displayName: "Account Details",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_SADM_ACCOUNT_DETAILS,
  childRoutes: [SadmAccountInvoiceDetailsRoute],
  isVisible: false,
};

export const SadmCreateAccountRoute: Route = {
  name: "createAccount",
  displayName: "Create Account",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_CREATE_ACCOUNT,
  isVisible: false,
};

export const SadmAccountsRoute: Route = {
  name: "accounts",
  displayName: "Accounts",
  icon: <MdAccountTree />,
  component: <AccountLayout />,
  path: ROUTE_SADM_ACCOUNTS,
  childRoutes: [SadmCreateAccountRoute, SadmAccountDetailsRoute],
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY],
};

export const SadmInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  component: <Invoice />,
  path: ROUTE_SADM_INVOICES,
  childRoutes: [SadmInvoiceDetailsRoute, SadmCreateInvoiceRoute],
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const SadmManageCardsRoute: Route = {
  name: "manage cards",
  displayName: "Manage Cards",
  icon: <MdPayment />,
  component: <ManageCards />,
  path: ROUTE_SADM_MANAGE_CARDS,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const SadmOrganizationDetailsRoute: Route = {
  name: "organizationDetails",
  displayName: "Organization Details",
  icon: <MdAccountTree />,
  component: <OrganizationDetail />,
  path: ROUTE_SADM_ORGANIZATION_DETAILS,
  isVisible: false,
};

export const SadmCreateOrganizationRoute: Route = {
  name: "createOrganization",
  displayName: "Create Organization",
  icon: <MdAccountTree />,
  component: <OrganizationDetail />,
  path: ROUTE_SADM_CREATE_ORGANIZATION,
  isVisible: false,
};

export const SadmOrganizationsRoute: Route = {
  name: "organizations",
  displayName: "Organizations",
  icon: <MdSchema />,
  component: <OrganizationLayout />,
  path: ROUTE_SADM_ORGANIZATIONS,
  childRoutes: [SadmCreateOrganizationRoute, SadmOrganizationDetailsRoute],
};

export const SadmAccountActivityReportsRoute = {
  name: "accountActivityReport",
  displayName: "Account Activity",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.AccountActivity} />,
  path: ROUTE_SADM_REPORTS_ACCOUNT_ACTIVITY,
  isVisible: false,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const SadmLeadReportsRoute = {
  name: "washDetailsReport",
  displayName: "Wash Details",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.WashDetails} />,
  path: ROUTE_SADM_REPORTS_WASH_DETAILS,
  isVisible: false,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const SadmWashDetailsReportsRoute = {
  name: "leadReport",
  displayName: "Lead",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.Lead} />,
  path: ROUTE_SADM_REPORTS_LEAD,
  isVisible: false,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const SadmReportsRoute: Route = {
  name: "reports",
  displayName: "Reports",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.AccountActivity} />,
  path: ROUTE_SADM_REPORTS,
  hasSubMenu: true,
  childRoutes: [
    SadmAccountActivityReportsRoute,
    SadmLeadReportsRoute,
    SadmWashDetailsReportsRoute,
  ],
};

export const SadmUsersRoute: Route = {
  name: "users",
  displayName: "Users",
  icon: <MdPeople />,
  component: <User />,
  path: ROUTE_SADM_USERS,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY],
};

export const SadmWashTypesRoute: Route = {
  name: "wash-types",
  displayName: "Wash Types",
  icon: <CommuteIcon />,
  component: <WashTypes />,
  path: ROUTE_SADM_WASH_TYPES,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY],
};

export const SadmWashesRoute: Route = {
  name: "washes",
  displayName: "Washes",
  icon: <MdLocalCarWash />,
  component: <WashInventory />,
  path: ROUTE_SADM_WASHES,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const SadmPaymentsRoute: Route = {
  name: "payments",
  displayName: "Payments",
  icon: <MdPayment />,
  component: <Payments />,
  path: ROUTE_SADM_PAYMENTS,
  queryParamsToKeep: [ORG_ID_QUERY_PARAM_KEY, ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const AdminDashboardRoute: Route = {
  name: "dashboard",
  displayName: "Dashboard",
  icon: <MdDashboard />,
  component: <AdminDashboard />,
  path: ROUTE_ADM_DASHBOARD,
};

export const AdminInvoiceDetailsRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_ADM_INVOICE_DETAILS,
  isVisible: false,
};

export const AdminCreateInvoiceRoute: Route = {
  name: "invoiceDetails",
  displayName: "Invoice Details",
  icon: <MdMonetizationOn />,
  component: <CreateInvoice />,
  path: ROUTE_ADM_CREATE_INVOICE,
  isVisible: false,
};

export const AdminAccountInvoiceDetailsRoute: Route = {
  name: "accountInvoiceDetails",
  displayName: "Account Invoice Details",
  icon: <MdMonetizationOn />,
  component: <InvoiceDetails />,
  path: ROUTE_ADM_ACCOUNT_INVOICE_DETAILS,
  isVisible: false,
};

export const AdminAccountDetailsRoute: Route = {
  name: "accountDetails",
  displayName: "Account Details",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_ADM_ACCOUNT_DETAILS,
  childRoutes: [AdminAccountInvoiceDetailsRoute],
  isVisible: false,
};

export const AdminCreateAccountRoute: Route = {
  name: "createAccount",
  displayName: "Create Account",
  icon: <MdAccountTree />,
  component: <AccountDetail />,
  path: ROUTE_CREATE_ACCOUNT,
  isVisible: false,
};

export const AdminAccountsRoute: Route = {
  name: "accounts",
  displayName: "Accounts",
  icon: <MdAccountTree />,
  component: <AccountLayout />,
  path: ROUTE_ADM_ACCOUNTS,
  childRoutes: [AdminCreateAccountRoute, AdminAccountDetailsRoute],
};

export const AdminInvoicesRoute: Route = {
  name: "invoices",
  displayName: "Invoices",
  icon: <MdMonetizationOn />,
  component: <Invoice />,
  path: ROUTE_ADM_INVOICES,
  childRoutes: [AdminInvoiceDetailsRoute, AdminCreateInvoiceRoute],
  queryParamsToKeep: [ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const AdminOrganizationDetailRoute: Route = {
  name: "orgDetails",
  displayName: "Org Details",
  icon: <MdInfoOutline />,
  path: ROUTE_ADM_ORGANIZATION_DETAILS,
  component: <OrganizationDetail />,
};

export const AdminAccountActivityReportsRoute = {
  name: "accountActivityReport",
  displayName: "Account Activity",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.AccountActivity} />,
  path: ROUTE_ADM_REPORTS_ACCOUNT_ACTIVITY,
  isVisible: false,
  queryParamsToKeep: [ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const AdminLeadReportsRoute = {
  name: "washDetailsReport",
  displayName: "Wash Details",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.WashDetails} />,
  path: ROUTE_ADM_REPORTS_WASH_DETAILS,
  isVisible: false,
  queryParamsToKeep: [ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const AdminWashDetailsReportsRoute = {
  name: "leadReport",
  displayName: "Lead",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.Lead} />,
  path: ROUTE_ADM_REPORTS_LEAD,
  isVisible: false,
  queryParamsToKeep: [ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const AdminReportsRoute: Route = {
  name: "reports",
  displayName: "Reports",
  icon: <MdReceipt />,
  component: <Reports reportType={ReportType.AccountActivity} />,
  path: ROUTE_ADM_REPORTS,
  hasSubMenu: true,
  childRoutes: [
    AdminAccountActivityReportsRoute,
    AdminLeadReportsRoute,
    AdminWashDetailsReportsRoute,
  ],
};

export const AdminUsersRoute: Route = {
  name: "users",
  displayName: "Users",
  icon: <MdPeople />,
  component: <User />,
  path: ROUTE_ADM_USERS,
};

export const AdminWashTypesRoute: Route = {
  name: "wash-types",
  displayName: "Wash Types",
  icon: <CommuteIcon />,
  component: <WashTypes />,
  path: ROUTE_SADM_WASH_TYPES,
};

export const AdminWashesRoute: Route = {
  name: "washes",
  displayName: "Washes",
  icon: <MdLocalCarWash />,
  component: <WashInventory />,
  path: ROUTE_ADM_WASHES,
  queryParamsToKeep: [ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const AdminPaymentsRoute: Route = {
  name: "payments",
  displayName: "Payments",
  icon: <MdPayment />,
  component: <Payments />,
  path: ROUTE_ADM_PAYMENTS,
  queryParamsToKeep: [ACCOUNT_ID_QUERY_PARAM_KEY],
};

export const UserProfileRoute: Route = {
  name: "profile",
  displayName: "Profile",
  icon: <Person2OutlinedIcon />,
  path: ROUTE_USER_PROFILE,
  component: <UserProfile />,
  isVisible: false,
};

export const ChangePasswordRoute: Route = {
  name: "change-password",
  displayName: "Change Password",
  path: ROUTE_CHANGE_PASSWORD,
  component: <ChangePassword />,
  isVisible: false,
};

export const ForgotPasswordRoute: Route = {
  name: "forgot-password",
  displayName: "Forgot Password",
  path: ROUTE_FORGOT_PASSWORD,
  component: <ForgotPassword />,
  isVisible: false,
};

export const ResetPasswordRoute: Route = {
  name: "reset-password",
  displayName: "Reset Password",
  path: ROUTE_RESET_PASSWORD,
  component: <ResetPassword />,
  isVisible: false,
};

export const DirectPaymentRoute: Route = {
  name: "direct-payment",
  displayName: "Direct Payment",
  path: ROUTE_DIRECT_PAYMENT,
  component: <DirectPayment />,
  isVisible: false,
};

export const openRoutes = [
  LoginRoute,
  ForgotPasswordRoute,
  ResetPasswordRoute,
  DirectPaymentRoute,
];
export const adminRoutes = [
  AdminDashboardRoute,
  AdminOrganizationDetailRoute,
  AdminAccountsRoute,
  AdminWashesRoute,
  AdminInvoicesRoute,
  AdminPaymentsRoute,
  AdminUsersRoute,
  AdminWashTypesRoute,
  AdminReportsRoute,
  UserProfileRoute,
];
export const defaultRoutes = [
  CustomerDashboardRoute,
  CustomerAccountDetailRoute,
  CustomerFleetRoute,
  CustomerWashesRoute,
  CustomerInvoicesRoute,
  CustomerPaymentsRoute,
  UserProfileRoute,
];
export const superAdminRoutes = [
  SadmDashboardRoute,
  SadmOrganizationsRoute,
  SadmAccountsRoute,
  SadmWashesRoute,
  SadmInvoicesRoute,
  SadmManageCardsRoute,
  SadmPaymentsRoute,
  SadmUsersRoute,
  SadmWashTypesRoute,
  SadmReportsRoute,
  UserProfileRoute,
];
export const allDashboardRoutes = [
  HomeRoute,
  ...defaultRoutes,
  ...superAdminRoutes,
  ...adminRoutes,
];
