import { useMutation, useQuery } from "react-query";
import { config } from "../config";
import {
  ApiError,
  ChangePasswordPayload,
  CustomerInfo,
  ForgotPasswordPayload,
  LeadUser,
  QueryOptions,
  User,
} from "../types";
import { client } from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import { DEFAULT_QUERY_OPTIONS } from "../constants";

export const useFetchLeadsQuery = (
  orgId: number,
  fetchOnMount: boolean = true
) => {
  const fetchLeads = async (): Promise<LeadUser[]> => {
    const path = config.api.userService.getAllLeads.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<any>(path);
    return response.data.response;
  };

  return useQuery(["fetchLeads", orgId], fetchLeads, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchUserQuery = (
  orgId: number,
  userId: number,
  fetchOnMount: boolean = true
) => {
  const fetchUser = async (): Promise<CustomerInfo> => {
    const path = config.api.userService.getUser
      .replace("{orgId}", orgId.toString())
      .replace("{userId}", userId.toString());
    const response = await client.get<any>(path);
    return response.data.response[0];
  };

  return useQuery(["fetchUser", orgId, userId], fetchUser, {
    retry: false,
    enabled: fetchOnMount,
  });
};

export const useFetchAllUsersQuery = (
  orgId: number,
  queryOptions?: QueryOptions<User[]>
) => {
  const fetchAllUsers = async (): Promise<User[]> => {
    const path = config.api.userService.getAllUsers.replace(
      "{orgId}",
      orgId.toString()
    );
    const response = await client.get<{ response: User[] }>(path);
    return response.data.response;
  };

  return useQuery(["fetchAllUsers", orgId], fetchAllUsers, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useCreateUserProfileMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    { orgId: number } & Pick<
      CustomerInfo,
      | "firstName"
      | "lastName"
      | "emailId"
      | "phoneNumber"
      | "orgId"
      | "employeeId"
      | "roleCode"
      | "active"
    >,
    unknown
  >(
    async (
      data: { orgId: number } & Pick<
        CustomerInfo,
        | "firstName"
        | "lastName"
        | "emailId"
        | "phoneNumber"
        | "orgId"
        | "employeeId"
        | "roleCode"
        | "active"
      >
    ) => {
      const path = config.api.userService.createUser.replace(
        "{orgId}",
        data.orgId.toString()
      );

      const response = await client.post(path, data);
      return response;
    }
  );

  return mutation;
};

export const useDeleteUserProfileMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    { orgId: number; userId: number },
    unknown
  >(async (data: { orgId: number; userId: number }) => {
    const path = config.api.userService.deleteUser
      .replace("{orgId}", data.orgId.toString())
      .replace("{userId}", data.userId.toString());

    const response = await client.delete(path);
    return response;
  });

  return mutation;
};

export const useDeleteUserProfilesMutation = () => {
  const mutation = useMutation(
    async (data: { orgId: number; ids: number[] }) => {
      const { orgId, ...ids } = data;
      const path = config.api.userService.deleteUsers.replace(
        "{orgId}",
        orgId.toString()
      );
      const response = await client.delete(path, {
        data: ids,
      });
      return response;
    }
  );
  return mutation;
};

export const useAdminResetUserPasswordMutation = () => {
  const mutation = useMutation(async (data: { orgId: number; id: number }) => {
    const { orgId, id } = data;
    const path = config.api.userService.adminResetPassword
      .replace("{orgId}", orgId.toString())
      .replace("{userId}", id.toString());
    const response = await client.post(path);
    return response;
  });
  return mutation;
};

export const useUpdateUserProfileMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    { orgId: number } & Pick<
      CustomerInfo,
      | "firstName"
      | "lastName"
      | "emailId"
      | "phoneNumber"
      | "orgId"
      | "employeeId"
      | "roleCode"
      | "id"
      | "active"
    >,
    unknown
  >(
    async (
      data: { orgId: number } & Pick<
        CustomerInfo,
        | "firstName"
        | "lastName"
        | "emailId"
        | "phoneNumber"
        | "orgId"
        | "employeeId"
        | "roleCode"
        | "id"
        | "active"
      >
    ) => {
      const path = config.api.userService.updateUser
        .replace("{orgId}", data.orgId.toString())
        .replace("{userId}", data.id.toString());

      const response = await client.patch(path, data);
      return response;
    }
  );

  return mutation;
};

export const useUpdateUserProfilePicMutation = () => {
  const mutation = useMutation(
    async ({
      id,
      orgId,
      profilePic,
    }: {
      id: number;
      orgId: number;
      profilePic: string;
    }) => {
      const path = config.api.userService.updateUserProfilePic
        .replace("{orgId}", orgId.toString())
        .replace("{userId}", id.toString());

      const response = await client.post(path, { profilePic });
      return response;
    }
  );

  return mutation;
};

export const useChangeUserPasswordMutation = () => {
  const mutation = useMutation(
    async ({
      orgId,
      id,
      data,
    }: {
      orgId: number;
      id: number;
      data: ChangePasswordPayload;
    }) => {
      const path = config.api.userService.changePassword
        .replace("{orgId}", orgId.toString())
        .replace("{id}", id.toString());

      const response = await client.post(path, data);
      return response;
    }
  );

  return mutation;
};

export const useForgotUserPasswordMutation = () => {
  const mutation = useMutation(async (data: ForgotPasswordPayload) => {
    const path = config.api.userService.forgotPassword;
    const response = await client.post(path, data);
    return response;
  });

  return mutation;
};

export const useValidateResetPasswordToken = (
  token: string,
  fetchOnMount: boolean = true
) => {
  const fetchValidateResetPasswordToken = async (): Promise<{
    orgId: number;
    userId: number;
  }> => {
    const path =
      config.api.userService.validateResetPasswordToken + "?token=" + token;
    const response = await client.get<any>(path);
    return response.data;
  };

  return useQuery(
    ["fetchValidateResetPasswordToken"],
    fetchValidateResetPasswordToken,
    {
      retry: false,
      enabled: fetchOnMount,
    }
  );
};

export const useResetUserPasswordMutation = () => {
  const mutation = useMutation(
    async ({
      orgId,
      userId,
      data,
    }: {
      orgId: number;
      userId: number;
      data: ChangePasswordPayload;
    }) => {
      const path = config.api.userService.resetPassword
        .replace("{orgId}", orgId.toString())
        .replace("{id}", userId.toString());
      const response = await client.post(path, data);
      return response;
    }
  );

  return mutation;
};
