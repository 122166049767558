export const SearchIcon = ({ width = "16", height = "16" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7.58918C1.00003 4.44586 3.22054 1.74031 6.30346 1.1272C9.38645 0.514087 12.4732 2.16419 13.6758 5.06833C14.8786 7.9725 13.8622 11.3218 11.2485 13.0678C8.63479 14.8139 5.15153 14.4705 2.92915 12.2475C1.6939 11.0119 0.999977 9.33628 1 7.58918Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1761 14.1768L17.0001 17.0008"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
