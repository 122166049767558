import { Box, Grid2 as Grid, Skeleton, Typography } from "@mui/material";
import { TextField } from "../common";
import { Button, DatePicker, SelectBox } from "../common";
import { FinancialInfo } from "../../types";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  useFetchAccountInfoQuery,
  useUpdateAccountFinancialMutation,
} from "../../api";
import {
  selectedOrgIdAtom,
  selectedAccountIdAtom,
  isCreateBillingModeAtom,
  toastOptionsAtom,
  userAtom,
} from "../../atoms";
import { formatDate, utcStringToMoment } from "../../utils";
import { useValidateForm } from "../../hooks";
import {
  editAccountFinancialBasicSchema,
  editBillingCycleSchema,
} from "../../validations";
import { DUE_UPON, INVOICE_FREQUENCIES } from "../../constants";
import { EditIcon } from "../icons";
import { CreateFinancialInfo } from "./fragments";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../enums";
import moment from "moment";

export const Billing = () => {
  const [user] = useAtom(userAtom);
  const [isCreateBillingMode, setIsCreateBillingMode] = useAtom(
    isCreateBillingModeAtom
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [financialInfo, setFinancialInfo] = useState<FinancialInfo>();
  const [billingCycleHasChanges, setBillingCycleHasChanges] = useState(false);
  const [financialBasicHasChanges, setFinancialBasicHasChanges] =
    useState(false);
  const [dueDateHasChanges, setDueDateHasChanges] = useState(false);
  const [isBillingCycleEditable, setIsBillingCycleEditable] = useState(false);
  const [selectedDays, setSelectedDays] = useState("0");
  const [selectedDaysOption, setSelectedDaysOption] = useState("0");
  const {
    register: registerBillingCycle,
    control: controlBillingCycle,
    handleSubmit: handleSubmitBillingCycle,
    errors: errorsBillingCycle,
    setValue: setValueEditBillingCycle,
  } = useValidateForm(editBillingCycleSchema, {
    proposedInvoiceDate: null,
  });
  const {
    register: registerAccountFinancialBasic,
    handleSubmit: handleSubmitAccountFinancialBasic,
    errors: errorsAccountFinancialBasic,
    setValue: setValueAccountFinancialBasic,
  } = useValidateForm(editAccountFinancialBasicSchema);
  const fetchAccountInfoQuery = useFetchAccountInfoQuery(
    selectedOrgId,
    selectedAccountId || 0,
    { financial: true }
  );
  const updateAccountFinancialMutation = useUpdateAccountFinancialMutation();

  useEffect(() => {
    if (
      (fetchAccountInfoQuery.isSuccess || fetchAccountInfoQuery.isFetched) &&
      !fetchAccountInfoQuery.isFetching &&
      !fetchAccountInfoQuery.isLoading
    ) {
      if (fetchAccountInfoQuery.data) {
        setFinancialInfo(fetchAccountInfoQuery.data.financialInfo);

        if (fetchAccountInfoQuery.data.financialInfo === undefined) {
          setIsCreateBillingMode(true);
        } else {
          setValueAccountFinancialBasic(
            "quickbooksId",
            fetchAccountInfoQuery.data.financialInfo.quickbooksId
          );
          setValueAccountFinancialBasic(
            "purchaseOrder",
            fetchAccountInfoQuery.data.financialInfo.purchaseOrder
          );
          setSelectedDays(fetchAccountInfoQuery.data.financialInfo.billingTerm);
          if (
            DUE_UPON.some(
              (item) =>
                item.value ==
                fetchAccountInfoQuery.data.financialInfo?.billingTerm
            )
          ) {
            setSelectedDaysOption(
              fetchAccountInfoQuery.data.financialInfo.billingTerm
            );
          } else {
            setSelectedDaysOption("custom");
          }
        }

        if (fetchAccountInfoQuery.data.financialInfo?.proposedInvoiceDate) {
          setValueEditBillingCycle(
            "proposedInvoiceDate",
            utcStringToMoment(
              fetchAccountInfoQuery.data.financialInfo.proposedInvoiceDate
            ),
            { shouldValidate: true }
          );
        }
        if (
          fetchAccountInfoQuery.data.financialInfo?.proposedInvoiceFrequency
        ) {
          setValueEditBillingCycle(
            "proposedInvoiceFrequency",
            fetchAccountInfoQuery.data.financialInfo.proposedInvoiceFrequency,
            { shouldValidate: true }
          );
        }
      }
    }
  }, [
    fetchAccountInfoQuery.isSuccess,
    fetchAccountInfoQuery.isError,
    fetchAccountInfoQuery.data,
    fetchAccountInfoQuery.error,
    fetchAccountInfoQuery.isLoading,
    fetchAccountInfoQuery.isFetched,
    fetchAccountInfoQuery.isFetching,
  ]);

  useEffect(() => {
    if (updateAccountFinancialMutation.isSuccess) {
      fetchAccountInfoQuery.refetch();
      setToastOptions({
        open: true,
        message: "Billing information updated",
        severity: "success",
      });
    }

    if (updateAccountFinancialMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateAccountFinancialMutation.isSuccess,
    updateAccountFinancialMutation.isError,
    updateAccountFinancialMutation.isLoading,
    updateAccountFinancialMutation.data,
    updateAccountFinancialMutation.error,
  ]);

  useEffect(() => {
    return () => {
      setIsCreateBillingMode(false);
    };
  }, []);

  const handleChange = () => {
    setBillingCycleHasChanges(true);
  };

  const handleEditBillingCycle = (data: {
    proposedInvoiceDate?: any;
    proposedInvoiceFrequency: string;
  }) => {
    updateAccountFinancialMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      financialInfo: {
        proposedInvoiceDate: moment(data.proposedInvoiceDate)
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        proposedInvoiceFrequency: data.proposedInvoiceFrequency,
      },
    });

    setFinancialInfo(undefined);
    setBillingCycleHasChanges(false);
    setIsBillingCycleEditable(false);
  };

  const handleEditAccountFinancialBasic = ({
    quickbooksId,
    purchaseOrder,
  }: {
    quickbooksId: string;
    purchaseOrder: string;
  }) => {
    updateAccountFinancialMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      financialInfo: {
        quickbooksId,
        purchaseOrder,
      },
    });

    setFinancialInfo(undefined);
    setFinancialBasicHasChanges(false);
  };

  const handleEditDueUpon = () => {
    updateAccountFinancialMutation.mutate({
      orgId: selectedOrgId,
      accountId: selectedAccountId || 0,
      financialInfo: {
        billingTerm: selectedDays,
      },
    });

    setFinancialInfo(undefined);
    setDueDateHasChanges(false);
  };

  return isCreateBillingMode && !financialInfo ? (
    <CreateFinancialInfo refetch={fetchAccountInfoQuery.refetch} />
  ) : (
    <Grid
      container
      direction={"column"}
      spacing={3}
      sx={{
        width: "100%",
        height: "100%",
        direction: "row",
        borderRadius: "10px",
        px: "4px",
      }}
    >
      <Box
        onSubmit={handleSubmitAccountFinancialBasic(
          handleEditAccountFinancialBasic
        )}
        component={"form"}
        noValidate
      >
        <Grid
          container
          size={12}
          direction={"row"}
          spacing={4}
          sx={{
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px",
            p: 4,
            position: "relative",
            mb: {
              lg:
                updateAccountFinancialMutation.isLoading ||
                fetchAccountInfoQuery.isLoading ||
                fetchAccountInfoQuery.isFetching ||
                !financialInfo
                  ? "-2rem"
                  : "0rem",
              xs: "0rem",
            },
          }}
        >
          <Grid container size={{ lg: 9, xs: 12 }}>
            <Grid container size={12}>
              {updateAccountFinancialMutation.isLoading ||
              fetchAccountInfoQuery.isLoading ||
              fetchAccountInfoQuery.isFetching ||
              !financialInfo ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <TextField
                  disabled={
                    !financialBasicHasChanges ||
                    user?.roleCode !== Role.SuperAdmin
                  }
                  label="Quickbooks ID"
                  name="quickbooksId"
                  register={registerAccountFinancialBasic}
                  error={errorsAccountFinancialBasic["quickbooksId"]}
                  defaultValue={financialInfo.quickbooksId}
                  maxLength={50}
                />
              )}
            </Grid>
            <Grid container size={12}>
              {updateAccountFinancialMutation.isLoading ||
              fetchAccountInfoQuery.isLoading ||
              fetchAccountInfoQuery.isFetching ||
              !financialInfo ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <TextField
                    disabled={!financialBasicHasChanges}
                    label="Purchase Order"
                    name="purchaseOrder"
                    register={registerAccountFinancialBasic}
                    error={errorsAccountFinancialBasic["purchaseOrder"]}
                    defaultValue={financialInfo.purchaseOrder}
                    maxLength={50}
                  />
                </>
              )}
            </Grid>
          </Grid>
          {financialBasicHasChanges && (
            <Grid
              container
              size={{ lg: 3, xs: 12 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Grid container size={{ xs: 12, md: 6, lg: 12 }}>
                <Button
                  label="Discard"
                  variantType="secondary"
                  sx={{
                    padding: "0.5rem",
                    width: "100%",
                  }}
                  onClick={() => {
                    setFinancialBasicHasChanges(false);
                  }}
                />
              </Grid>
              <Grid container size={{ xs: 12, md: 6, lg: 12 }}>
                <Button
                  type={"submit"}
                  label="Save Changes"
                  sx={{
                    padding: "0.5rem",
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          )}
          <AllowedAccess
            roles={[Role.SuperAdmin, Role.Admin]}
            permissions={[
              { module: Module.AccountFinancial, action: Action.Write },
            ]}
          >
            {!financialBasicHasChanges && (
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    ":hover": { cursor: "pointer" },
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                  }}
                  onClick={() => {
                    setFinancialBasicHasChanges(true);
                  }}
                >
                  <EditIcon
                    width={"18px"}
                    style={{
                      transform: "translateY(-3px)",
                    }}
                    active={true}
                  />
                </Box>
              </Grid>
            )}
          </AllowedAccess>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          size={12}
          direction={"row"}
          spacing={4}
          sx={{
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px",
            p: 4,
            position: "relative",
            mb: {
              lg:
                updateAccountFinancialMutation.isLoading ||
                fetchAccountInfoQuery.isLoading ||
                fetchAccountInfoQuery.isFetching ||
                !financialInfo
                  ? "-2rem"
                  : "0rem",
              xs: "0rem",
            },
          }}
        >
          <Grid container size={{ lg: 9, xs: 12 }}>
            {updateAccountFinancialMutation.isLoading ||
            fetchAccountInfoQuery.isLoading ||
            fetchAccountInfoQuery.isFetching ||
            !financialInfo ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                {dueDateHasChanges ? (
                  <>
                    <SelectBox
                      disabled={!dueDateHasChanges}
                      label="Due Upon"
                      name="dueUpon"
                      dropdown={DUE_UPON}
                      value={selectedDaysOption}
                      onChange={(e) => {
                        if (e.target.value !== "custom") {
                          setSelectedDays(e.target.value);
                        } else {
                          setSelectedDays("15");
                        }
                        setSelectedDaysOption(e.target.value);
                      }}
                    />
                    {selectedDaysOption === "custom" && (
                      <TextField
                        disabled={!dueDateHasChanges}
                        label="Days"
                        name="days"
                        value={selectedDays}
                        onChange={(e) => {
                          setSelectedDays(e.target.value);
                        }}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <TextField
                      disabled={!dueDateHasChanges}
                      label="Due Upon"
                      name="dueUpon"
                      value={
                        selectedDays == "0"
                          ? `Immediate Payment`
                          : `Net ${selectedDays}`
                      }
                      onChange={(e) => {
                        setSelectedDays(e.target.value);
                      }}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
          {dueDateHasChanges && (
            <Grid
              container
              size={{ lg: 3, xs: 12 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Grid container size={{ xs: 12, md: 6, lg: 12 }}>
                <Button
                  label="Discard"
                  variantType="secondary"
                  sx={{
                    padding: "0.5rem",
                    width: "100%",
                  }}
                  onClick={() => {
                    setDueDateHasChanges(false);
                  }}
                />
              </Grid>
              <Grid container size={{ xs: 12, md: 6, lg: 12 }}>
                <Button
                  onClick={handleEditDueUpon}
                  type={"submit"}
                  label="Save Changes"
                  sx={{
                    padding: "0.5rem",
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          )}
          <AllowedAccess
            roles={[Role.SuperAdmin, Role.Admin]}
            permissions={[
              { module: Module.AccountFinancial, action: Action.Write },
            ]}
          >
            {!dueDateHasChanges && (
              <Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    ":hover": { cursor: "pointer" },
                    position: "absolute",
                    top: "1rem",
                    right: "1rem",
                  }}
                  onClick={() => {
                    setDueDateHasChanges(true);
                  }}
                >
                  <EditIcon
                    width={"18px"}
                    style={{
                      transform: "translateY(-3px)",
                    }}
                    active={true}
                  />
                </Box>
              </Grid>
            )}
          </AllowedAccess>
        </Grid>
      </Box>
      <Box>
        <Grid
          container
          size={12}
          spacing={3}
          sx={{
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px",
            p: 4,
          }}
        >
          <Grid
            container
            size={{ lg: 3, xs: 12, sm: 6 }}
            sx={{
              px: 0,
            }}
          >
            {updateAccountFinancialMutation.isLoading ||
            fetchAccountInfoQuery.isLoading ||
            fetchAccountInfoQuery.isFetching ||
            !financialInfo ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <DatePicker
                  disabled={true}
                  label="Invoice Date"
                  name="invoiceDate"
                  defaultValue={utcStringToMoment(financialInfo.invoiceDate)}
                />
              </>
            )}
          </Grid>
          <Grid
            container
            size={{ lg: 3, xs: 12, sm: 6 }}
            sx={{
              px: 0,
            }}
          >
            {updateAccountFinancialMutation.isLoading ||
            fetchAccountInfoQuery.isLoading ||
            fetchAccountInfoQuery.isFetching ||
            !financialInfo ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <SelectBox
                  disabled={true}
                  label="Invoice Frequency"
                  name="invoiceFrequency"
                  dropdown={INVOICE_FREQUENCIES}
                  defaultValue={financialInfo.invoiceFrequency}
                />
              </>
            )}
          </Grid>
          <Grid
            container
            size={{ lg: 3, xs: 12 }}
            sx={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              label="Change Billing Cycle"
              variantType="secondary"
              sx={{
                padding: "0.5rem",
                width: "100%",
              }}
              onClick={() => {
                setIsBillingCycleEditable(true);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container size={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            position: "relative",
            px: 4,
          }}
        >
          {updateAccountFinancialMutation.isLoading ||
          fetchAccountInfoQuery.isLoading ||
          fetchAccountInfoQuery.isFetching ||
          !financialInfo ? (
            <Skeleton variant="text" sx={{ fontSize: "2rem", width: "100%" }} />
          ) : (
            <Typography>
              Current invoice Period : {"    "}
              <span style={{ fontWeight: "bold" }}>
                {formatDate(
                  financialInfo.lastInvoiceDate,
                  "ddd MMM DD YYYY HH:mm:ss"
                ) +
                  " to " +
                  formatDate(
                    financialInfo.proposedInvoiceDate
                      ? moment.min(
                          moment(financialInfo.invoiceDate),
                          moment(financialInfo.proposedInvoiceDate)
                        )
                      : moment(financialInfo.invoiceDate),
                    "ddd MMM DD YYYY HH:mm:ss"
                  )}
              </span>
            </Typography>
          )}
        </Box>
      </Grid>
      {financialInfo &&
        (isBillingCycleEditable ||
          (financialInfo.proposedInvoiceDate &&
            financialInfo.proposedInvoiceFrequency)) && (
          <Box
            onSubmit={handleSubmitBillingCycle(handleEditBillingCycle)}
            component={"form"}
            noValidate
          >
            <Grid
              container
              size={12}
              spacing={3}
              sx={{
                boxShadow: "0px 0px 4px 0px #00000040",
                borderRadius: "10px",
                p: 4,
              }}
            >
              <Grid
                container
                size={{ lg: 3, xs: 12, sm: 6 }}
                sx={{
                  px: 0,
                }}
              >
                {updateAccountFinancialMutation.isLoading ||
                fetchAccountInfoQuery.isLoading ||
                fetchAccountInfoQuery.isFetching ||
                !financialInfo ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: 67, width: "100%" }}
                  />
                ) : (
                  <>
                    <DatePicker
                      disablePast
                      minDate={moment().add(1, "day").startOf("day").utc()}
                      disabled={
                        isBillingCycleEditable
                          ? false
                          : financialInfo.proposedInvoiceDate &&
                            financialInfo.proposedInvoiceFrequency
                          ? true
                          : false
                      }
                      label="New Invoice Date"
                      name="proposedInvoiceDate"
                      control={controlBillingCycle}
                      error={errorsBillingCycle["proposedInvoiceDate"]}
                      onChange={handleChange}
                    />
                  </>
                )}
              </Grid>
              <Grid
                container
                size={{ lg: 3, xs: 12, sm: 6 }}
                sx={{
                  px: 0,
                }}
              >
                {updateAccountFinancialMutation.isLoading ||
                fetchAccountInfoQuery.isLoading ||
                fetchAccountInfoQuery.isFetching ||
                fetchAccountInfoQuery.isRefetching ||
                !financialInfo ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{ height: 67, width: "100%" }}
                  />
                ) : (
                  financialInfo.proposedInvoiceFrequency !== undefined && (
                    <SelectBox
                      disabled={
                        isBillingCycleEditable
                          ? false
                          : financialInfo.proposedInvoiceDate !== undefined &&
                            financialInfo.proposedInvoiceDate !== null &&
                            financialInfo.proposedInvoiceFrequency !==
                              undefined &&
                            financialInfo.proposedInvoiceFrequency !== null
                          ? true
                          : false
                      }
                      label="New Invoice Frequency"
                      name="proposedInvoiceFrequency"
                      dropdown={INVOICE_FREQUENCIES}
                      defaultValue={financialInfo.proposedInvoiceFrequency}
                      register={registerBillingCycle}
                      error={errorsBillingCycle["proposedInvoiceFrequency"]}
                      onChange={() => {
                        handleChange();
                      }}
                    />
                  )
                )}
              </Grid>
              <Grid
                container
                size={{ lg: 3, xs: 12 }}
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  type={"submit"}
                  label="Save Changes"
                  disabled={!billingCycleHasChanges}
                  sx={{
                    padding: "0.5rem",
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
    </Grid>
  );
};
