import React, { useState, useEffect } from "react";
import { Box, Typography, Link, LinearProgress, Alert } from "@mui/material";
import { Modal } from "./Modal";
import {
  useFetchSignedUrlForUpload,
  useFetchSignedUrlUploadStatus,
  useUploadFileToS3Mutation,
} from "../../../api";
import { SignedUrlInfo } from "../../../types";
import { FileUploadStatus } from "../../../enums";
import { theme } from "../../../utils";

type UploadCsvModalProps = {
  open: boolean;
  onClose: () => void;
  onUploadComplete?: () => void;
  title: string;
  message: string;
  sampleCsvUrl: string;
  uploadUrl: string;
  statusUrl: string;
};

export const UploadCsvModal: React.FC<UploadCsvModalProps> = ({
  open,
  onClose,
  title,
  message,
  sampleCsvUrl,
  uploadUrl,
  statusUrl,
}) => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(
    FileUploadStatus.NotStarted
  );
  const fetchSignedUrl = useFetchSignedUrlForUpload(uploadUrl);
  const uploadCsvFileMutation = useUploadFileToS3Mutation();
  const [signedUrlKey, setSignedUrlKey] = useState<string>("");
  const fetchSignedUrlUploadStatus = useFetchSignedUrlUploadStatus(
    statusUrl,
    signedUrlKey
  );

  const resetAll = () => {
    setProgress(0);
    setSignedUrlKey("");
    setUploading(false);
    setCsvFile(null);
    setUploadStatus(FileUploadStatus.NotStarted);
  };

  const handleClose = () => {
    resetAll();
    if (onClose) {
      onClose();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCsvFile(file);
    }
  };

  useEffect(() => {
    if (fetchSignedUrl.isSuccess) {
      setSignedUrlKey(fetchSignedUrl.data.key);
      uploadFile(fetchSignedUrl.data);
      updateProgress(FileUploadStatus.InProgress);
    }
    if (fetchSignedUrl.isError) {
      updateProgress(FileUploadStatus.Failed);
    }
  }, [
    fetchSignedUrl.isSuccess,
    fetchSignedUrl.isError,
    fetchSignedUrl.isRefetching,
  ]);

  useEffect(() => {
    if (uploadCsvFileMutation.isSuccess) {
      checkUploadStatus();
      updateProgress(FileUploadStatus.InProgress);
    }
    if (uploadCsvFileMutation.isError) {
      updateProgress(FileUploadStatus.Failed);
    }
  }, [uploadCsvFileMutation.isSuccess, uploadCsvFileMutation.isError]);

  useEffect(() => {
    if (fetchSignedUrlUploadStatus.isSuccess) {
      const status = fetchSignedUrlUploadStatus.data.status;
      if (status === FileUploadStatus.Completed) {
        updateProgress(FileUploadStatus.Completed);
      } else if (status === FileUploadStatus.Failed) {
        updateProgress(FileUploadStatus.Failed);
      }
    }
    if (fetchSignedUrlUploadStatus.isError) {
      updateProgress(FileUploadStatus.Failed);
    }
  }, [
    fetchSignedUrlUploadStatus.isSuccess,
    fetchSignedUrlUploadStatus.isError,
  ]);

  const checkUploadStatus = () => {
    const intervalId = setInterval(async () => {
      if (signedUrlKey) {
        const statusResult = await fetchSignedUrlUploadStatus.refetch();
        const status = statusResult.data?.status;

        if (
          status === FileUploadStatus.Completed ||
          status === FileUploadStatus.Failed
        ) {
          clearInterval(intervalId);
          if (status === FileUploadStatus.Completed) {
            setProgress(100);
          } else {
            setProgress(0);
          }
        }
      }
    }, 5000);
    return () => clearInterval(intervalId);
  };

  const uploadFile = async (signedUrlInfo: SignedUrlInfo) => {
    if (!csvFile) {
      updateProgress(FileUploadStatus.Failed);
      return;
    }
    uploadCsvFileMutation.mutate({
      signedUrl: signedUrlInfo.url,
      file: csvFile,
    });
  };

  const handleUpload = () => {
    if (!csvFile) {
      updateProgress(FileUploadStatus.Failed);
      return;
    }
    setUploading(true);
    updateProgress(FileUploadStatus.InProgress);
    fetchSignedUrl.refetch();
  };

  const updateProgress = (status: FileUploadStatus) => {
    setUploadStatus(status);
    let progressValue = 0;
    switch (status) {
      case FileUploadStatus.NotStarted:
        progressValue = 0;
        break;
      case FileUploadStatus.InProgress:
        progressValue = Math.min(progress + 20, 100);
        break;
      case FileUploadStatus.Completed:
        progressValue = 100;
        break;
      case FileUploadStatus.Failed:
        progressValue = 0;
        break;
    }

    setProgress(progressValue);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={title}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
            textAlign: "center",
          }}
        >
          {uploading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                }}
              >
                {progress === 100
                  ? "Upload completed"
                  : "Uploading your file..."}
              </Typography>

              <Typography
                sx={{
                  color: "#6c757d",
                }}
              >
                Please wait, this may take a few moments.
              </Typography>

              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  width: "100%",
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor:
                      progress < 100 ? theme.palette.primary.dark : "#4caf50",
                  },
                }}
              />

              <Typography
                sx={{
                  marginTop: 1,
                  color: progress < 100 ? "#6c757d" : "#4caf50",
                  fontWeight: 500,
                }}
              >
                {progress}% completed
              </Typography>

              {uploadStatus === FileUploadStatus.Failed && (
                <Alert severity="error" sx={{ marginTop: 2 }}>
                  {"An error occurred during the upload. Please try again."}
                </Alert>
              )}
            </Box>
          ) : (
            <>
              <Typography sx={{ fontWeight: 400 }}>{message}</Typography>

              {uploadStatus === FileUploadStatus.Failed && (
                <Alert severity="error" sx={{ marginTop: 2, width: "100%" }}>
                  {"An error occurred during the upload. Please try again."}
                </Alert>
              )}

              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{
                  marginTop: "20px",
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "4px",
                  cursor: "pointer",
                  width: "100%",
                }}
              />

              <Link
                href={sampleCsvUrl}
                download="sample.csv"
                sx={{
                  marginTop: 2,
                  textDecoration: "none",
                  textDecorationLine: "underline",
                  fontSize: "12px",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                Download sample CSV file
              </Link>
            </>
          )}
        </Box>
      }
      primaryButtonText="Upload"
      disablePrimaryButton={uploading}
      onPrimaryButtonClick={handleUpload}
      sx={{
        modal: {
          width: { lg: "40%", xs: "80%", sm: "80%" },
        },
        footer: { justifyContent: "flex-end" },
      }}
    />
  );
};
