export const BallIcon = ({ width = "18", height = "18", active = false }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15.25C13.1234 15.25 15.25 13.1234 15.25 10.5C15.25 7.87665 13.1234 5.75 10.5 5.75C7.87665 5.75 5.75 7.87665 5.75 10.5C5.75 13.1234 7.87665 15.25 10.5 15.25Z"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0625 6.9375L17.0313 3.96875"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0625 14.0625L17.0313 17.0313"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9375 14.0625L3.96875 17.0313"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9375 6.9375L3.96875 3.96875"
        stroke={active ? "#3e8bd2" : "#a1a1a1"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
