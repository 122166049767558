import {
  Box,
  CircularProgress,
  Grid2 as Grid,
  Pagination,
  useMediaQuery,
} from "@mui/material";
import { BusinessInfoTile } from "../common/tiles";
import {
  useDeleteOrganizationMutation,
  useFetchOrganizationsQuery,
} from "../../api";
import { useAtom } from "jotai";
import { selectedOrgIdAtom, toastOptionsAtom } from "../../atoms";
import { ChangeEvent, useEffect, useState } from "react";
import { OrganizationModel } from "../../models";
import { BusinessViewToolbar } from "../toolbar";
import { ConfirmationModal } from "../common";
import { theme } from "../../utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { SadmCreateOrganizationRoute } from "../../routes";
import { useNavigate } from "react-router-dom";
import { BusinessInfo } from "../../types";

export const OrganizationLayout = () => {
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    BusinessInfo[]
  >([]);
  const [paginatedOrganizations, setPaginatedOrganizations] =
    useState<BusinessInfo[]>();
  const [organizations, setOrganizations] = useState<BusinessInfo[]>();
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery();
  const deleteOrganizationMutation = useDeleteOrganizationMutation();

  useEffect(() => {
    if (isXs) setPageSize(10);
    else if (isSm) setPageSize(10);
    else if (isLg) setPageSize(12);
    else if (isXl) setPageSize(16);
  }, [isXs, isSm, isLg, isXl]);

  useEffect(() => {
    setFilteredOrganizations(
      (organizations || []).filter((item) =>
        item.name.toLowerCase().includes(searchTerm)
      )
    );
    setPage(1);
  }, [searchTerm, organizations]);

  useEffect(() => {
    setSearchTerm("");
  }, [page]);

  useEffect(() => {
    if (filteredOrganizations) {
      setPaginatedOrganizations(
        getPaginatedItems(filteredOrganizations, page, pageSize)
      );
    }
  }, [filteredOrganizations, page, pageSize]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const organizations = OrganizationModel.buildOrganizationBusinessInfo(
        fetchOrganizationsQuery.data
      );
      setOrganizations(organizations);
    }

    if (fetchOrganizationsQuery.isError || fetchOrganizationsQuery.isLoading) {
      setOrganizations([]);
      setFilteredOrganizations([]);
    }

    setPage(1);
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (deleteOrganizationMutation.isSuccess) {
      fetchOrganizationsQuery.refetch();

      setToastOptions({
        open: true,
        message: "Organization deleted successfully",
        severity: "success",
      });
    }

    if (deleteOrganizationMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    deleteOrganizationMutation.isSuccess,
    deleteOrganizationMutation.isError,
    deleteOrganizationMutation.isLoading,
    deleteOrganizationMutation.data,
    deleteOrganizationMutation.error,
  ]);

  const getPaginatedItems = (
    array: BusinessInfo[],
    page: number,
    pageSize: number
  ) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return (array || []).slice(startIndex, endIndex);
  };

  const handleClick = (orgId: number) => {
    navigate({
      pathname: orgId.toString(),
      search: `orgId=${orgId}`,
    });
  };

  const handleCreate = () => {
    navigate(SadmCreateOrganizationRoute.path);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setSelectedOrgId(0);
    setIsModalOpen(false);
  };

  const handleClickDelete = (id: number) => {
    setSelectedOrgId(id);
    handleOpenModal();
  };

  const handleConfirmDelete = () => {
    deleteOrganizationMutation.mutate({
      orgId: selectedOrgId,
    });
    handleCloseModal();
  };

  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 150px)" }}>
      <Box
        sx={{
          overflow: "auto",
          px: "5px",
          display: "flex",
          width: "100%",
          height: "100%",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <BusinessViewToolbar
          addLabel={"organization"}
          handleCreate={handleCreate}
          handleSearch={handleSearch}
        />
        <Grid
          container
          direction={"row"}
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            flexGrow: 1,
            alignItems:
              fetchOrganizationsQuery.isLoading ||
              deleteOrganizationMutation.isLoading ||
              fetchOrganizationsQuery.isRefetching ||
              (fetchOrganizationsQuery.isSuccess && !paginatedOrganizations) ||
              fetchOrganizationsQuery.isError
                ? "center"
                : "none",
            alignContent:
              fetchOrganizationsQuery.isLoading ||
              deleteOrganizationMutation.isLoading ||
              fetchOrganizationsQuery.isRefetching ||
              (fetchOrganizationsQuery.isSuccess && !paginatedOrganizations) ||
              fetchOrganizationsQuery.isError
                ? "none"
                : "baseline",
          }}
        >
          {fetchOrganizationsQuery.isLoading ||
          fetchOrganizationsQuery.isFetching ||
          fetchOrganizationsQuery.isRefetching ||
          deleteOrganizationMutation.isLoading ||
          (fetchOrganizationsQuery.isSuccess && !paginatedOrganizations) ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (fetchOrganizationsQuery.isSuccess &&
              organizations &&
              paginatedOrganizations &&
              paginatedOrganizations.length === 0) ||
            !paginatedOrganizations ||
            fetchOrganizationsQuery.isError ? (
            <Grid
              size={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              Nothing to show
            </Grid>
          ) : (
            paginatedOrganizations.map((item, index) => (
              <Grid key={index} size={{ lg: 4, md: 6, sm: 6, xs: 12, xl: 3 }}>
                <BusinessInfoTile
                  data={item}
                  handleClick={handleClick}
                  handleDelete={handleClickDelete}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
        }}
      >
        <Pagination
          count={
            filteredOrganizations && filteredOrganizations.length > 0
              ? Math.ceil(filteredOrganizations.length / pageSize)
              : 1
          }
          page={page}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            setPage(value);
          }}
          color="primary"
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={"Delete Organization"}
        message={"Are you sure that you want to delete this organization?"}
      />
    </Box>
  );
};
