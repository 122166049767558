import { Box, Grid2 } from "@mui/material";
import { DataGrid } from "../common";
import { Report, ReportFilters, ReportRequestParams } from "../../types";
import {
  cleanString,
  convertToCSV,
  downloadFile,
  exportBlob,
  theme,
} from "../../utils";
import { useAtom } from "jotai";
import {
  reportFilterStateAtom,
  reportsDataGridPaginationAtom,
  selectedAccountAtom,
  selectedOrgIdAtom,
  toastOptionsAtom,
  userAtom,
} from "../../atoms";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../utils/dateUtil";
import ReportsToolBar from "./fragments/ReportsToolBar";
import {
  useFetchAccountActivityReportQuery,
  useFetchWashDetailsReportQuery,
  useFetchLeadReportQuery,
  useFetchDownloadWashDetailsReportQuery,
} from "../../api";
import { Role, ReportType, StatusCodes, DateRangeOptions } from "../../enums";
import { ReportModel } from "../../models";
import { useSearchParams } from "react-router-dom";

type ReportProps = {
  reportType: ReportType;
};

export const Reports = ({ reportType }: ReportProps) => {
  const [user] = useAtom(userAtom);
  const [queryParams, setQueryParams] = useSearchParams();
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [reportList, setReportList] = useState<Report[]>([]);
  const [reportsPaginationModel, setReportsPaginationModel] = useAtom(
    reportsDataGridPaginationAtom
  );
  const [selectedDateRange, setSelectedDateRange] =
    useState<string>("thisMonth");
  const [filterValues, setFilterValues] = useAtom(reportFilterStateAtom);
  const currentDate = getDateRange("thisMonth");
  const [isDownloading, setIsDownloading] = useState(false);
  const [reportFilters, setReportFilters] = useState<ReportFilters>({
    fromDate: currentDate.startDate,
    toDate: currentDate.endDate,
    sortBy: "",
    sortOrder: "",
  });
  const [, setToastOptions] = useAtom(toastOptionsAtom);

  const resetPage = () => {
    setReportsPaginationModel({ ...reportsPaginationModel, page: 0 });
  };

  const buildReportRequestParams = (): ReportRequestParams => {
    return {
      orgId: selectedOrgId,
      accountId:
        user?.roleCode === Role.SuperAdmin || user?.roleCode === Role.Admin
          ? reportFilters?.accountId || 0
          : selectedAccount?.accountId || 0,
      filters: {
        fromDate: reportFilters?.fromDate,
        toDate: reportFilters?.toDate,
        locationNumber: reportFilters?.locationNumber,
      },
    };
  };

  const fetchAccountActivityReportQuery = useFetchAccountActivityReportQuery(
    buildReportRequestParams(),
    selectedOrgId
      ? reportType === ReportType.AccountActivity
        ? true
        : false
      : false
  );

  const fetchWashDetailsReportQuery = useFetchWashDetailsReportQuery(
    buildReportRequestParams(),
    selectedOrgId
      ? reportType === ReportType.WashDetails
        ? true
        : false
      : false
  );

  const fetchDownloadWashDetailsReportQuery =
    useFetchDownloadWashDetailsReportQuery(buildReportRequestParams(), false);

  const fetchLeadReportQuery = useFetchLeadReportQuery(
    buildReportRequestParams(),
    selectedOrgId ? (reportType === ReportType.Lead ? true : false) : false
  );

  const accountActivityReportColumns: GridColDef[] = [
    {
      field: "endTime",
      headerName: "Date",
      width: 210,
      editable: false,
      valueFormatter: (value) => formatDate(value, "dddd - MMMM DD, YYYY"),
    },
    {
      field: "accountName",
      headerName: "Account Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "name",
      headerName: "Wash Type",
      width: 150,
      editable: false,
    },
    {
      field: "numberOfWash",
      headerName: "Quantity",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
  ];

  const leadReportColumns: GridColDef[] = [
    {
      field: "leadName",
      headerName: "Lead Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "washDate",
      headerName: "Date of washing",
      width: 210,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "name",
      headerName: "Wash Type",
      width: 150,
      editable: false,
    },
    {
      field: "numberOfWash",
      headerName: "Quantity",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
  ];

  const washDetailsReportColumns: GridColDef[] = [
    {
      field: "washDate",
      headerName: "Date",
      width: 210,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "leadName",
      headerName: "Lead",
      width: 170,
      editable: false,
    },
    {
      field: "washTypeName",
      headerName: "Wash Type",
      width: 140,
      editable: false,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 170,
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      field: "vehicleNumber",
      headerName: "Fleet Number",
      width: 170,
      editable: false,
    },
  ];

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setReportFilters({
          ...reportFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else if (reportFilters.sortBy !== "") {
      setReportFilters({
        ...reportFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };

  const handleDownload = () => {
    const csvData = convertToCSV(
      reportType === ReportType.AccountActivity
        ? accountActivityReportColumns
        : reportType === ReportType.WashDetails
        ? washDetailsReportColumns
        : leadReportColumns,
      reportList,
      []
    );
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const reportName = reportType;
    const accountName = selectedAccount ? selectedAccount.accountName : "";
    const fromDate = reportFilters.fromDate ? reportFilters.fromDate : "";
    const toDate = reportFilters.toDate ? reportFilters.toDate : "";
    const filename =
      cleanString(reportName) +
      "_report_" +
      cleanString(accountName) +
      "_" +
      cleanString(fromDate) +
      "-" +
      cleanString(toDate) +
      ".csv";
    exportBlob(blob, filename);
    setToastOptions({
      open: true,
      message: "Report exported successfully!",
      severity: "success",
    });
  };

  const getWashDetailsReport = () => {
    setIsDownloading(true);
    fetchDownloadWashDetailsReportQuery.refetch();
  };

  useEffect(() => {
    setReportFilters({
      ...reportFilters,
      page: reportsPaginationModel.page,
      pageSize: reportsPaginationModel.pageSize,
    });
  }, [reportsPaginationModel]);

  useEffect(() => {
    if (fetchAccountActivityReportQuery.isSuccess) {
      const reports = ReportModel.buildAccountActivityReport(
        fetchAccountActivityReportQuery.data
      );
      if (reportType === ReportType.AccountActivity) {
        setReportList(reports);
      }
    }

    if (fetchAccountActivityReportQuery.isError) {
      const error = fetchAccountActivityReportQuery.error as { status: number };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      if (reportType === ReportType.AccountActivity) {
        setReportList([]);
      }
    }
  }, [
    fetchAccountActivityReportQuery.isSuccess,
    fetchAccountActivityReportQuery.data,
    fetchAccountActivityReportQuery.isError,
    fetchAccountActivityReportQuery.isLoading,
    fetchAccountActivityReportQuery.isFetching,
    fetchAccountActivityReportQuery.isFetched,
    fetchAccountActivityReportQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchWashDetailsReportQuery.isSuccess) {
      if (reportType === ReportType.WashDetails) {
        setReportList(fetchWashDetailsReportQuery.data);
      }
    }

    if (fetchWashDetailsReportQuery.isError) {
      const error = fetchWashDetailsReportQuery.error as { status: number };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      if (reportType === ReportType.WashDetails) {
        setReportList([]);
      }
    }
  }, [
    fetchWashDetailsReportQuery.isSuccess,
    fetchWashDetailsReportQuery.data,
    fetchWashDetailsReportQuery.isError,
    fetchWashDetailsReportQuery.isLoading,
    fetchWashDetailsReportQuery.isFetching,
    fetchWashDetailsReportQuery.isFetched,
    fetchWashDetailsReportQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchLeadReportQuery.isSuccess) {
      const reports = ReportModel.buildLeadReport(fetchLeadReportQuery.data);
      if (reportType === ReportType.Lead) {
        setReportList(reports);
      }
    }

    if (fetchLeadReportQuery.isError) {
      const error = fetchLeadReportQuery.error as { status: number };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
      if (reportType === ReportType.Lead) {
        setReportList([]);
      }
    }
  }, [
    fetchLeadReportQuery.isSuccess,
    fetchLeadReportQuery.data,
    fetchLeadReportQuery.isError,
    fetchLeadReportQuery.isLoading,
    fetchLeadReportQuery.isFetching,
    fetchLeadReportQuery.isFetched,
    fetchLeadReportQuery.isRefetching,
  ]);

  useEffect(() => {
    if (
      fetchDownloadWashDetailsReportQuery.isSuccess &&
      fetchDownloadWashDetailsReportQuery.data
    ) {
      const dataToArrayBuffer = new Uint8Array(
        fetchDownloadWashDetailsReportQuery.data
      );
      const linkToPdf = URL.createObjectURL(
        new Blob([dataToArrayBuffer], {
          type: "application/pdf",
        })
      );
      if (isDownloading) {
        downloadFile(
          linkToPdf,
          "wash-details-" +
            reportFilters.accountId +
            "-" +
            reportFilters.fromDate +
            "-to-" +
            reportFilters.toDate +
            ".pdf"
        );
        setIsDownloading(false);
      }
    }

    if (fetchDownloadWashDetailsReportQuery.isError) {
      const error = fetchDownloadWashDetailsReportQuery.error as {
        status: number;
      };
      if (error.status !== StatusCodes.NotFound) {
        setToastOptions({
          open: true,
          message: "Something went wrong",
          severity: "error",
        });
      }
    }
  }, [
    fetchDownloadWashDetailsReportQuery.isSuccess,
    fetchDownloadWashDetailsReportQuery.data,
    fetchDownloadWashDetailsReportQuery.isError,
    fetchDownloadWashDetailsReportQuery.isLoading,
    fetchDownloadWashDetailsReportQuery.isFetching,
    fetchDownloadWashDetailsReportQuery.isFetched,
    fetchDownloadWashDetailsReportQuery.isRefetching,
  ]);

  useEffect(() => {
    const filters = Object.entries(reportFilters).reduce(
      (acc: any, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    setQueryParams({
      ...filters,
    });
  }, [reportFilters]);

  useEffect(() => {
    setReportList([]);
    setSelectedOrgId(
      selectedOrgId !== 0 ? selectedOrgId : user?.orgId ? user?.orgId : 0
    );
    switch (reportType) {
      case ReportType.AccountActivity:
        fetchAccountActivityReportQuery.refetch();
        break;
      case ReportType.WashDetails:
        fetchWashDetailsReportQuery.refetch();
        break;
      case ReportType.Lead:
        fetchLeadReportQuery.refetch();
        break;
    }

    setSelectedDateRange("thisMonth");

    const dateRange = queryParams.get("dateRange");
    const dateFilters: Partial<ReportFilters> = {
      fromDate: queryParams.get("fromDate") || undefined,
      toDate: queryParams.get("toDate") || undefined,
    };

    if (
      dateRange &&
      Object.values(DateRangeOptions).includes(dateRange as DateRangeOptions)
    ) {
      if (dateRange !== DateRangeOptions.CustomDate) {
        dateFilters.fromDate = getDateRange(dateRange).startDate;
        dateFilters.toDate = getDateRange(dateRange).endDate;
      }
      dateFilters.dateRange = dateRange;

      setSelectedDateRange(dateRange);
    }

    const queryFilters: ReportFilters = {
      orgId: Number(queryParams.get("orgId")) || undefined,
      accountId: Number(queryParams.get("accountId")) || undefined,
      locationNumber:
        queryParams.getAll("locationNumber").map(Number) || undefined,
      page: Number(queryParams.get("page")) || undefined,
      pageSize: Number(queryParams.get("pageSize")) || undefined,
      sortBy: queryParams.get("sortBy") || undefined,
      sortOrder: queryParams.get("sortOrder") || undefined,
      ...dateFilters,
    };

    const tempReportFiltersFromQueryStrings = Object.entries(
      queryFilters
    ).reduce((acc: any, [key, value]) => {
      if (value && (!Array.isArray(value) || value.length > 0)) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const tempFilterValuesFromQueryStrings = Object.entries({
      orgId: tempReportFiltersFromQueryStrings.orgId,
      accountId: tempReportFiltersFromQueryStrings.accountId,
      locationNumber: tempReportFiltersFromQueryStrings.locationNumber,
    }).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    Object.values(tempFilterValuesFromQueryStrings).length > 0 &&
      setFilterValues({
        ...filterValues,
        ...tempFilterValuesFromQueryStrings,
      });
    setReportFilters({
      fromDate: currentDate.startDate,
      toDate: currentDate.endDate,
      ...tempReportFiltersFromQueryStrings,
    });
    setReportsPaginationModel({
      ...reportsPaginationModel,
      page: tempReportFiltersFromQueryStrings.page || 0,
      pageSize: tempReportFiltersFromQueryStrings.pageSize || 10,
    });
  }, [reportType]);

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <Box>
        <Grid2
          container
          direction={"column"}
          spacing={2}
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "#f5f5f5",
          }}
        >
          <ReportsToolBar
            onDownload={handleDownload}
            onGetWashDetailsReport={getWashDetailsReport}
            reportType={reportType}
            reportFilters={reportFilters}
            setReportFilters={setReportFilters}
            resetPage={resetPage}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
          />
        </Grid2>
        <Grid2 container direction={"column"} spacing={2}>
          <DataGrid
            columns={
              reportType === ReportType.AccountActivity
                ? accountActivityReportColumns
                : reportType === ReportType.WashDetails
                ? washDetailsReportColumns
                : leadReportColumns
            }
            rows={reportList || []}
            loading={
              fetchAccountActivityReportQuery.isLoading ||
              fetchAccountActivityReportQuery.isRefetching ||
              fetchWashDetailsReportQuery.isLoading ||
              fetchWashDetailsReportQuery.isRefetching ||
              fetchLeadReportQuery.isLoading ||
              fetchLeadReportQuery.isRefetching
            }
            sortModel={[
              {
                field: reportFilters.sortBy || "",
                sort: (reportFilters.sortOrder as GridSortDirection) || null,
              },
            ]}
            onSortModelChange={handleSortModelChange}
            paginationModel={reportsPaginationModel}
            setPaginationModel={setReportsPaginationModel}
            sx={{
              border: "none",
              backgroundColor: theme.palette.secondary.light,
              padding: "20px",
              paddingTop: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 16,
              },
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              height: "70vh",
            }}
          />
        </Grid2>
      </Box>
    </Box>
  );
};
