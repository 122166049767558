import React, { useState } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import {
  CurrencyExchange as RefundIcon,
  Restore as ReverseIcon,
} from "@mui/icons-material";
import { theme } from "../../../utils";
import { PaymentRecord } from "../../../types";
import { ConfirmationModal } from "../../common";

type PaymentsActionIconsProps = {
  paymentRecord: PaymentRecord;
  handleRefund: (payment: PaymentRecord) => void;
  handleReverse: (payment: PaymentRecord) => void;
};

export const PaymentsActionIcons: React.FC<PaymentsActionIconsProps> = ({
  paymentRecord,
  handleRefund,
  handleReverse,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefund, setIsRefund] = useState(false);

  return (
    <>
      <Box
        sx={{
          display: "flow",
          width: "50px",
        }}
      >
        <Tooltip title="Refund">
          <IconButton
            onClick={() => {
              setIsModalOpen(true);
              setIsRefund(true);
            }}
            disabled={!paymentRecord.allowReturn}
          >
            <RefundIcon
              fontSize="small"
              sx={{
                ...(paymentRecord.allowReturn && {
                  color: theme.palette.primary.main,
                }),
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Reverse Payment">
          <IconButton
            onClick={() => {
              setIsModalOpen(true);
              setIsRefund(false);
            }}
            disabled={!paymentRecord.allowReversal}
          >
            <ReverseIcon
              fontSize="large"
              sx={{
                ...(paymentRecord.allowReversal && {
                  color: theme.palette.primary.main,
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          setIsModalOpen(false);
          isRefund ? handleRefund(paymentRecord) : handleReverse(paymentRecord);
        }}
        icon={
          isRefund ? (
            <RefundIcon
              fontSize="medium"
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          ) : (
            <ReverseIcon
              fontSize="medium"
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          )
        }
        title={`${isRefund ? "Refund" : "Reverse"} Payment`}
        message={`Are you sure you want to ${
          isRefund ? "refund" : "reverse"
        } this payment?`}
      />
    </>
  );
};
