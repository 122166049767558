import {
  Box,
  Divider,
  Grid2 as Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { Address } from "../../../types";
import { EditIcon } from "../../icons";

type ViewAddressInfoProps = {
  data?: Address;
  title: string;
  isLoading: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewAddressInfo = ({
  data,
  title,
  isLoading = false,
  handleEditMode,
}: ViewAddressInfoProps) => {
  return (
    <Grid
      container
      size={{ sm: 12, md: 6 }}
      direction={"column"}
      spacing={4}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        p: 4,
        position: "relative",
        justifyContent: "flex-start",
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "600", pb: 1 }}>{title}</Typography>
        <Divider />
      </Box>
      <Box
        onClick={() => handleEditMode(true)}
        sx={{
          ":hover": {
            cursor: "pointer",
          },
          position: "absolute",
          right: "1rem",
          top: "1rem",
        }}
      >
        <EditIcon width={"21px"} active={true} />
      </Box>
      <Grid container size={12}>
        <Grid container size={6}>
          <Grid container size={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#606060",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.email ? data.email : "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid container size={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#606060",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Website
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.website ? data.website : "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid container size={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#606060",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Instruction
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.instruction ? data.instruction : "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid container size={6}>
          <Grid container size={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#606060",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Address Line 1
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.addressLine1 ? data.addressLine1 : "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid container size={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#606060",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Address Line 2
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.addressLine2 ? data.addressLine2 : "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid container size={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ height: 67, width: "100%" }}
                />
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "#606060",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    City, State, ZIP
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      textAlign: "left",
                      wordWrap: "break-word",
                    }}
                  >
                    {data && data.city && data.state && data.zip
                      ? data.city + ", " + data.state + ", " + data.zip
                      : "N/A"}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
