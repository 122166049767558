import { Moment } from "moment";
import {
  InvoiceStatus,
  InvoicePaymentStatus,
  Role,
  PaymentStatus,
} from "../enums";
import { ExtendedInvoiceStatus } from "../types";
import { hasDatePassed } from "./dateUtil";

export const getInvoiceStatus = (
  status: InvoiceStatus,
  dueDate: string | Moment,
  role: Role
): ExtendedInvoiceStatus => {
  if (
    [
      InvoiceStatus.InProgress,
      InvoiceStatus.SendToCustomer,
      InvoiceStatus.SendToQuickbooks,
    ].includes(status)
  ) {
    return hasDatePassed(dueDate)
      ? InvoicePaymentStatus.Overdue
      : InvoicePaymentStatus.WithinDue;
  }

  if (status === InvoiceStatus.PaymentReceived && role === Role.Customer) {
    return InvoicePaymentStatus.Paid;
  }

  return status;
};

export const getPaymentStatus = (status: PaymentStatus): string => {
  switch (status) {
    case PaymentStatus.PaymentSuccess:
      return "Payment Received";
    case PaymentStatus.ReturnSuccess:
      return "Refund Completed";
    case PaymentStatus.ReturnFailed:
      return "Refund Failed";
    default:
      return status
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
  }
};
