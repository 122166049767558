import { useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
} from "@mui/material";
import { HorizontalStepper } from "../common";
import { FaMoneyCheckAlt, FaCreditCard, FaMoneyBillWave } from "react-icons/fa";
import { RiBankFill } from "react-icons/ri";
import { InvoicePaymentCallbackMessage, PaymentMethod } from "../../enums";
import {
  DropdownItem,
  InvoiceOverviewInfo,
  InvoicePaymentInitiatePayload,
  PaymentDetails,
} from "../../types";
import { IconType } from "react-icons";
import {
  CreditCardSale,
  CashPayment,
  DetailsForm,
  HostedPaymentsPage,
  CheckPayment,
} from "./fragments";
import CloseIcon from "@mui/icons-material/Close";
import { useValidateForm } from "../../hooks";
import { getPaymentDetailsSchema } from "../../validations";

export const paymentMethods: (DropdownItem & { icon: IconType })[] = [
  { key: "Card", value: PaymentMethod.Card, icon: FaCreditCard },
  { key: "Check", value: PaymentMethod.Check, icon: FaMoneyCheckAlt },
  { key: "ACH", value: PaymentMethod.Ach, icon: RiBankFill },
  { key: "Cash", value: PaymentMethod.Cash, icon: FaMoneyBillWave },
];

type PaymentDialogProps = {
  invoiceOverview?: InvoiceOverviewInfo;
  open: boolean;
  handleClose: (message: InvoicePaymentCallbackMessage) => void;
  loading?: boolean;
};

export const PaymentDialog = ({
  invoiceOverview,
  open,
  handleClose,
  loading,
}: PaymentDialogProps) => {
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Card);
  const [callbackMessage, setCallbackMessage] = useState(
    InvoicePaymentCallbackMessage.None
  );
  const [activeStep, setActiveStep] = useState(1);
  const [invoicePaymentPayload, setInvoicePaymentPayload] = useState<
    InvoicePaymentInitiatePayload | undefined
  >(invoiceOverview);
  const schema = useMemo(() => {
    return getPaymentDetailsSchema(
      invoiceOverview?.balanceAmount,
      paymentMethod
    );
  }, [invoiceOverview, paymentMethod]);

  const { register, handleSubmit, errors, reset, setValue } =
    useValidateForm(schema);

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (
        (event.data.status as InvoicePaymentCallbackMessage) ===
        InvoicePaymentCallbackMessage.PaymentSuccess
      ) {
        setCallbackMessage(InvoicePaymentCallbackMessage.PaymentSuccess);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleProceed = (data: PaymentDetails) => {
    if (invoiceOverview) {
      setInvoicePaymentPayload({
        ...invoiceOverview,
        address: {
          addressLine1: data.addressLine1 || "",
          addressLine2: data.addressLine2,
          city: data.city || "",
          state: data.state || "",
          zip: data.zip || "",
        },
        bankName: data.bankName,
        checkNumber: data.checkNumber,
        invoiceAmount: data.amount || 0,
        paymentMethod: data.paymentMethod,
        omnitokenId: data.omnitokenId,
        tokenId: data.tokenId,
        netTransactionId: data.netTransactionId,
        expirationMonth: data.expirationMonth,
        expirationYear: data.expirationYear,
        saveCard: data.saveCard,
        cardLogo: data.cardLogo,
      });
    }

    if (activeStep < 3) setActiveStep(activeStep + 1);
  };

  const handleCloseWrapper = () => {
    setActiveStep(1);
    reset();
    handleClose(callbackMessage);
    setCallbackMessage(InvoicePaymentCallbackMessage.None);
    setPaymentMethod(PaymentMethod.Card);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseWrapper}
      fullWidth={true}
      maxWidth={"lg"}
      sx={{}}
    >
      {loading || !invoiceOverview ? (
        <Grid
          container
          size={12}
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "75vh",
            direction: "row",
            borderRadius: "10px",
            backgroundColor: "white",
            boxShadow: "0px 0px 4px 0px #00000040",
            p: 3,
            justifyContent: loading ? "center" : "flex-start",
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Box sx={{ pt: 4, pb: 4, overflow: "auto" }}>
          <DialogTitle>
            <HorizontalStepper
              steps={["Enter Payment Details", "Complete Payment"]}
              activeStep={activeStep}
            />
            <IconButton
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                border: "none",
                padding: "8px 12px",
                fontSize: "14px",
                fontWeight: "bold",
                borderRadius: "4px",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={handleCloseWrapper}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {(() => {
              switch (activeStep) {
                case 1:
                  return (
                    <Box
                      component="form"
                      noValidate
                      id="paymentDetailsForm"
                      onSubmit={handleSubmit(handleProceed)}
                    >
                      <DetailsForm
                        register={register}
                        errors={errors}
                        invoice={invoiceOverview}
                        setValue={setValue}
                        orgId={invoiceOverview.orgId}
                        accountId={invoiceOverview.accountId}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                      />
                    </Box>
                  );
                case 2:
                  if (
                    invoicePaymentPayload?.omnitokenId === "" &&
                    invoicePaymentPayload?.paymentMethod === PaymentMethod.Card
                  )
                    return (
                      <HostedPaymentsPage
                        payload={invoicePaymentPayload}
                        orgId={invoiceOverview.orgId}
                      />
                    );
                  if (
                    invoicePaymentPayload?.paymentMethod === PaymentMethod.Check
                  )
                    return (
                      <CheckPayment
                        payload={invoicePaymentPayload}
                        orgId={invoiceOverview.orgId}
                      />
                    );
                  if (
                    invoicePaymentPayload?.paymentMethod === PaymentMethod.Cash
                  )
                    return (
                      <CashPayment
                        payload={invoicePaymentPayload}
                        orgId={invoiceOverview.orgId}
                      />
                    );
                  return (
                    <CreditCardSale
                      payload={invoicePaymentPayload}
                      orgId={invoiceOverview.orgId}
                    />
                  );

                default:
                  return (
                    <CreditCardSale
                      payload={invoicePaymentPayload}
                      orgId={invoiceOverview.orgId}
                    />
                  );
              }
            })()}
          </DialogContent>

          <DialogActions sx={{ px: 3 }}>
            {activeStep === 1 && (
              <>
                <Button variant="outlined" onClick={handleCloseWrapper}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  form="paymentDetailsForm"
                  variant="contained"
                >
                  Proceed
                </Button>
              </>
            )}
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};
