export const ListIcon = ({ width = "16", height = "16", active = false }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="26"
        height="4"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        y="10"
        width="26"
        height="4"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        y="20"
        width="26"
        height="4"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
    </svg>
  );
};
