import { Typography, Grid2 as Grid, Skeleton } from "@mui/material";
import { Address } from "./Address";
import { OrganizationInfo, AccountInfo } from "../../../types";

type Props = {
  organizationInfo?: OrganizationInfo;
  accountInfo?: AccountInfo;
  isLoading: boolean;
};

export const AccountDetails = ({
  organizationInfo,
  accountInfo,
  isLoading,
}: Props) => {
  return (
    <Grid
      size={12}
      gap={2}
      container
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "4px",
        backgroundColor: "white",
        p: 2,
      }}
    >
      <Grid size={12}>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={{ height: 32, width: "20%" }} />
        ) : (
          <Typography component="div" variant="h5">
            {organizationInfo?.name || ""}
          </Typography>
        )}
      </Grid>
      <Address
        title="To"
        address={organizationInfo?.remittanceAddress?.addressLine1 || ""}
        contactNumber={organizationInfo?.contactNumber || ""}
        email={organizationInfo?.primaryAddress?.email || ""}
        isLoading={isLoading}
      />
      <Address
        title="From"
        address={accountInfo?.baseInfo.accountAddress.addressLine1 || ""}
        contactNumber={accountInfo?.baseInfo.contactNumber || ""}
        email={accountInfo?.baseInfo.accountAddress.email || ""}
        isLoading={isLoading}
      />
    </Grid>
  );
};
