import { Box, Grid2, Typography } from "@mui/material";

type SummaryComponentProps = {
  key: string;
  value: string;
  color?: string;
}[];

export const SummaryComponent: React.FC<{ data: SummaryComponentProps }> = ({
  data,
}) => {
  return (
    <Grid2 container spacing={2}>
      {data.map((item, index) => (
        <Box key={index} display="flex" alignItems="center">
          <Typography
            variant="body2"
            color={item.color ? item.color : "textSecondary"}
          >
            {item.key}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ ml: 0.5 }}>
            : {item.value}{" "}
          </Typography>
        </Box>
      ))}
    </Grid2>
  );
};
