export const GridIcon = ({ width = "16", height = "16", active = false }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7" height="7" rx="2" fill={active ? "#3e8bd2" : "#d2d2d2"} />
      <rect
        y="9"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        y="18"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        x="9"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        x="9"
        y="9"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        x="9"
        y="18"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        x="18"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        x="18"
        y="9"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
      <rect
        x="18"
        y="18"
        width="7"
        height="7"
        rx="2"
        fill={active ? "#3e8bd2" : "#d2d2d2"}
      />
    </svg>
  );
};
