import {
  Box,
  Card,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
  Grid2 as Grid,
} from "@mui/material";
import { TrashIcon } from "../../icons";
import { Omnitoken } from "../../../types";
import { CARD_LOGOS } from "../../../constants";

type CardInfoTileProps = {
  data: Omnitoken;
  handleClick?: (id: any) => any;
  handleDelete?: (id: any) => any;
};

export const CardInfoTile = ({
  data,
  handleClick,
  handleDelete,
}: CardInfoTileProps) => {
  const { id, cardLast4, expirationMonth, expirationYear, cardLogo } = data;

  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1200px)");

  return (
    <Card
      sx={{
        width: "100%",
        paddingX: "2rem",
        paddingTop: "1rem",
        paddingBottom: "1.938rem",
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        ":hover": {
          cursor: "pointer",
          boxShadow: "0px 0px 8px 0px #00000040",
        },
      }}
      onClick={() => {
        handleClick && handleClick(id);
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            paddingBottom: "0.3rem",
            fontWeight: "700",
            fontSize: isSmallScreen ? "14px" : "16px",
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {cardLogo && (
            <Tooltip title={cardLogo} arrow placement="top">
              <div style={{ width: "fit-content" }}>{cardLogo}</div>
            </Tooltip>
          )}
        </Typography>
        <Box
          onClick={(event) => {
            event.stopPropagation();
            handleDelete && handleDelete(id);
          }}
        >
          <TrashIcon />
        </Box>
      </Box>
      <Divider sx={{ borderBottomWidth: "1px", borderColor: "#A1A1A1" }} />
      <Grid
        container
        spacing={2}
        sx={{
          paddingTop: "1rem",
          alignItems: "center",
          textAlign: isSmallScreen ? "center" : "left",
        }}
      >
        <Grid
          size={{ xs: 12, sm: 4 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={CARD_LOGOS[cardLogo] || CARD_LOGOS["Other"]}
            sx={{
              maxWidth: isSmallScreen
                ? "60px"
                : isMediumScreen
                ? "80px"
                : "110px",
              maxHeight: isSmallScreen
                ? "60px"
                : isMediumScreen
                ? "80px"
                : "90px",
              objectFit: "contain",
              padding: "0.2rem",
            }}
            alt="logo"
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 8 }}>
          <Box sx={{ overflow: "hidden" }}>
            {cardLast4 && (
              <Typography
                sx={{
                  fontSize: isSmallScreen ? "0.9rem" : "1rem",
                  whiteSpace: "nowrap",
                  marginTop: "10px",
                }}
              >
                {"xxxx xxxx xxxx " + cardLast4}
              </Typography>
            )}
            {expirationMonth && expirationYear && (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ fontSize: "1rem" }}>{cardLogo}</Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  {`${expirationMonth}/${expirationYear}`}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};
