import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Search } from "../../search";
import { Action, Module, Role } from "../../../enums";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import React, { useState, useEffect } from "react";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import { Filter } from "../../common/filter";
import { DropdownItem, FilterItem } from "../../../types";
import { useAtom } from "jotai";
import {
  selectedOrgIdAtom,
  userAtom,
  washTypeFilterStateAtom,
} from "../../../atoms";
import { OrganizationModel } from "../../../models";
import { useFetchOrganizationsQuery } from "../../../api";
import { useSearchParams } from "react-router-dom";

type washTypeToolbarProps = {
  onApplyFilters: () => void;
  handleSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetPage: () => void;
};

export const WashTypeToolbar = ({
  onApplyFilters,
  handleSearch,
  resetPage,
}: washTypeToolbarProps) => {
  const [filterValues, setFilterValues] = useAtom(washTypeFilterStateAtom);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(
    Object.keys(filterValues).length || 0
  );
  const [user] = useAtom(userAtom);
  const [queryParams] = useSearchParams();
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  const filters: FilterItem[] = [
    {
      type: "list",
      label: "Organization",
      values: organizationsDropdown,
      keyword: "orgId",
    },
  ];

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleApplyFilters = () => {
    setAppliedFiltersCount(Object.keys(filterValues).length);
    onApplyFilters();
    filters.forEach((filter: { keyword: any }) => {
      const filterKeyword = filter.keyword;
      const filterValue = filterValues[filter.keyword];
      if (filterKeyword === "orgId") {
        setSelectedOrgId(filterValue);
      }
    });
    handleFilterClose();
  };

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      const filters = {
        ...filterValues,
        orgId: queryParams.get("orgId")
          ? Number(queryParams.get("orgId"))
          : user?.orgId,
      };
      setFilterValues(filters);
      setSelectedOrgId(
        queryParams.get("orgId")
          ? Number(queryParams.get("orgId"))
          : user?.orgId || 0
      );
      setAppliedFiltersCount(Object.keys(filters).length);
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="end"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pr={1}
          size={{ lg: 6, md: 5, xs: 12 }}
          container
          alignItems="center"
          justifyContent={{ xs: "flex-end", md: "flex-end", lg: "flex-end" }}
        >
          <AllowedAccess
            roles={[Role.Admin, Role.SuperAdmin]}
            permissions={[{ module: Module.User, action: Action.Write }]}
          >
            <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
              <Search onInputChange={handleSearch} />
            </Grid2>
          </AllowedAccess>
          <AllowedAccess
            roles={[Role.SuperAdmin]}
            permissions={[{ module: Module.User, action: Action.Write }]}
          >
            <Grid2
              size={{ xs: 12, sm: 4, lg: 1 }}
              sx={{
                flexBasis: { xs: "content", sm: "content", lg: "content" },
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "60px",
                  height: "30px",
                  width: "50px",
                  cursor: "pointer",
                }}
                onClick={handleFilterClick}
              >
                <Badge badgeContent={appliedFiltersCount} color="secondary">
                  <MdOutlineFilterAlt
                    style={{ fontSize: "22px", color: "white" }}
                  />
                </Badge>
              </Box>
            </Grid2>
          </AllowedAccess>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Filter
          onApplyFilters={() => {
            handleApplyFilters();
            resetPage();
          }}
          filters={filters}
          onClose={handleFilterClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          preservedFilters={["orgId"]}
        />
      </Popover>
    </Box>
  );
};
