import { Box, Typography } from "@mui/material";
import { Grid2 as Grid, Paper, styled } from "@mui/material";
import { SignedUrlInfo, ToastOptions } from "../../../../types";
import { Button, ImageCropModal, Toast } from "../../../common";
import { ChangeEvent, useEffect, useState } from "react";
import { config } from "../../../../config";
import { useAtom } from "jotai";
import { userAtom } from "../../../../atoms";
import {
  useFetchSignedUrlForUpload,
  useUploadFileToS3Mutation,
} from "../../../../api";
import { AutoRetryImage } from "../../../common/image/AutoRetryImage";
import { defaultProfileImage } from "../../../../assets";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  flexGrow: 1,
  borderRadius: "10px",
  boxShadow: "none",
}));

type ProfilePicHandlerProps = {
  profilePicUrl: string;
  isDisableButton: boolean;
  updateImage: (url: string) => void;
};

export const ProfilePicHandler = ({
  profilePicUrl,
  isDisableButton,
  updateImage,
}: ProfilePicHandlerProps) => {
  const [user, setUser] = useAtom(userAtom);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const [uploadProfilePicUrl, setUploadProfilePicUrl] = useState(
    config.api.userService.uploadProfilePic
  );
  const [previewUrl, setPreviewUrl] = useState<string | null>(profilePicUrl);
  const uploadProfilePicMutation = useUploadFileToS3Mutation();
  const fetchSignedUrl = useFetchSignedUrlForUpload(uploadProfilePicUrl);

  useEffect(() => {
    if (profilePicUrl) {
      setPreviewUrl(profilePicUrl);
    }
  }, [profilePicUrl]);

  useEffect(() => {
    if (fetchSignedUrl.isSuccess) {
      uploadFile(fetchSignedUrl.data);
    }
    if (fetchSignedUrl.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [fetchSignedUrl.isSuccess, fetchSignedUrl.isError]);

  useEffect(() => {
    if (uploadProfilePicMutation.isSuccess) {
      if (previewUrl && user) setUser({ ...user, profilePic: previewUrl });

      setToastOptions({
        open: true,
        message: "Profile picture updated successfully.",
        severity: "success",
      });
    }
    if (uploadProfilePicMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [uploadProfilePicMutation.isSuccess, uploadProfilePicMutation.isError]);

  const uploadFile = async (signedUrlInfo: SignedUrlInfo) => {
    if (!uploadedImage) return;

    try {
      setPreviewUrl(signedUrlInfo.url.split("?")[0]);
      updateImage(uploadedImage.name);
      await uploadProfilePicMutation.mutateAsync({
        signedUrl: signedUrlInfo.url,
        file: uploadedImage,
      });
    } catch (error) {
      setToastOptions({
        open: true,
        message: "Something went wrong during upload.",
        severity: "error",
      });
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setUploadedImage(null);
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.type.startsWith("image/")) {
      const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
      const fileExtension = file.name.split(".").pop();
      const fileName = `logo_${timestamp}.${fileExtension}`;

      const urlWithParams = config.api.userService.uploadProfilePic
        .replace("{orgId}", (user?.orgId || 0).toString())
        .replace("{userId}", (user?.id || 0).toString())
        .replace("{fileName}", fileName);

      setUploadProfilePicUrl(urlWithParams);

      const renamedFile = new File([file], fileName, {
        type: file.type,
        lastModified: file.lastModified,
      });

      setUploadedImage(renamedFile);
      setCropModalOpen(true);
    } else {
      setToastOptions({
        open: true,
        message: "Please select a valid image file (JPEG/PNG).",
        severity: "error",
      });
    }

    event.target.value = "";
  };

  const handleConfirm = (file: File) => {
    setPreviewUrl(URL.createObjectURL(file));
    setUploadedImage(file);
    setCropModalOpen(false);
    fetchSignedUrl.refetch();
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "100%",
        minHeight: "150px",
        justifyContent: "center",
      }}
    >
      <Toast setOptions={setToastOptions} options={toastOptions} />
      <Grid container size={{ xs: 6, sm: 12 }} direction={"row"}>
        <Grid
          container
          size={{ sm: 6, md: 6, lg: 4, xs: 12 }}
          sx={{
            display: {
              xs: "flow",
              sm: "flex",
            },
          }}
        >
          <Item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: { xs: 80, sm: 120 },
                height: { xs: 80, sm: 120 },
              }}
            >
              {previewUrl && (
                <AutoRetryImage
                  sx={{
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    objectFit: "cover",
                    cursor: "pointer",
                    borderRadius: "50%",
                  }}
                  src={previewUrl}
                  alt={"User profile pic"}
                  defaultImage={defaultProfileImage}
                />
              )}
            </Box>
          </Item>
        </Grid>
        <Grid
          container
          size={{ sm: 6, md: 6, lg: 8, xs: 12 }}
          sx={{
            display: {
              xs: "flow",
              sm: "flex",
            },
          }}
        >
          <Item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              id="profile-pic-upload"
              onChange={handleFileUpload}
              disabled={
                fetchSignedUrl.isLoading ||
                uploadProfilePicMutation.isLoading ||
                isDisableButton
              }
            />
            <label htmlFor="profile-pic-upload">
              <Button
                component="span"
                label={"Change Picture"}
                sx={{ width: "200px", marginBottom: "10px" }}
                disabled={
                  fetchSignedUrl.isLoading ||
                  uploadProfilePicMutation.isLoading ||
                  isDisableButton
                }
              />
            </label>
            <Typography
              sx={{
                color: "#a6a6a6",
                textAlign: "center",
              }}
            >
              (Image must be in jpeg/png format)
            </Typography>
          </Item>
        </Grid>
      </Grid>
      {uploadedImage && (
        <ImageCropModal
          open={cropModalOpen}
          file={uploadedImage}
          onClose={() => setCropModalOpen(false)}
          onConfirm={handleConfirm}
        />
      )}
    </Grid>
  );
};
