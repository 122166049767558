import { Box, Typography } from "@mui/material";
import { Modal } from "../../../common";
import { useLogoffMutation } from "../../../../api";
import { isLoaderActiveAtom, storage, userAtom } from "../../../../atoms";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoginRoute } from "../../../../routes";

type LogoutModalProps = {
  open: boolean;
  userName: string;
  message: string;
};

export const LogoutModal = ({ open, userName, message }: LogoutModalProps) => {
  const { mutate, isSuccess, isError, isLoading } = useLogoffMutation();
  const navigate = useNavigate();
  const [, setUser] = useAtom(userAtom);
  const [, setIsLoaderActive] = useAtom(isLoaderActiveAtom);

  useEffect(() => {
    if (isSuccess || isError) {
      setIsLoaderActive(false);
      setUser(null);
      storage.removeItem("userAtom");
      navigate(LoginRoute.path);
    }
    if (isLoading) {
      setIsLoaderActive(true);
    }
  }, [isSuccess, isError, isLoading]);

  return (
    <Modal
      open={open}
      onClose={() => {}}
      title={"Logout User"}
      content={
        <Box
          sx={{
            p: 1,
          }}
        >
          <Typography fontSize={14}>{message}</Typography>
        </Box>
      }
      primaryButtonText={"Log Out"}
      onPrimaryButtonClick={() => {
        mutate({ userName });
      }}
      sx={{
        modal: { width: { lg: "450px", xs: "80%", sm: "50%" } },
        footer: { flexDirection: "row-reverse" },
      }}
    />
  );
};
