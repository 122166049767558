import { atom } from "jotai";
import { ToastOptions } from "../types";
import { userAtom } from "./userAtoms";
import { Role } from "../enums";
import { adminRoutes, defaultRoutes, superAdminRoutes } from "../routes";

export const toastOptionsAtom = atom<ToastOptions>({
  open: false,
  message: "",
  severity: "info",
});
export const routesAtom = atom((get) => {
  const user = get(userAtom);

  if (user) {
    switch (user.roleCode) {
      case Role.SuperAdmin:
        return superAdminRoutes;
      case Role.Admin:
        return adminRoutes;
      default:
        return defaultRoutes;
    }
  }

  return undefined;
});

export const isReceivePaymentFormActiveAtom = atom(false);
export const breadcrumbsAtom = atom<{ name: string; path: string }[]>([]);

export const isLoaderActiveAtom = atom(false);