import React from "react";
import { Box, Typography } from "@mui/material";
import { theme } from "../../../utils";

type TopItemsProps<T> = {
  title: string;
  items: T[];
  displayKey: keyof T;
  displayValue: keyof T;
  limit?: number;
};

export const TopItemsList = <T extends { [key: string]: any }>({
  title,
  items,
  displayKey,
  displayValue,
  limit = 5,
}: TopItemsProps<T>) => {
  const sortedItems = [...items]
    .sort((a, b) => b[displayValue] - a[displayValue])
    .slice(0, limit);

  return (
    <Box
      sx={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        padding: "16px",
        backgroundColor: theme.palette.background.default,
        height: "400px",
      }}
    >
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "20px",
          paddingBottom: "10px",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        {title}
      </Typography>
      <Box sx={{ paddingTop: "10px" }}>
        {sortedItems.map((item, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={1}
            p={5}
            sx={{ padding: "5px" }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {item[displayKey]}
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              {item[displayValue]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
