import React from "react";
import ListItem from "@mui/material/ListItem";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { RefreshIcon, EditIcon, TrashIcon2 as TrashIcon } from "../../icons";
import { AccountContact } from "../../../types";

type ContactListItemProps = {
  data: AccountContact;
  handleEdit: (key: number) => void;
  handleDelete: (key: number) => void;
};

export const ContactListItem = ({
  data,
  handleEdit,
  handleDelete,
}: ContactListItemProps) => {
  return (
    <ListItem
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        px: 4,
        py: 3,
        mb: 3,
      }}
    >
      <Grid
        container
        direction={"row"}
        spacing={1.5}
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          size={{ md: 2, xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"}>{data.name}</Typography>
        </Grid>
        <Grid
          size={{ md: 2.7, xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"}>{data.email}</Typography>
        </Grid>
        <Grid
          size={{ md: 2, xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"}>{data.number}</Typography>
        </Grid>
        <Grid
          size={{ md: 1.2, xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"}>SMS</Typography>
          {data.isSmsRequired ? (
            <IoCheckmarkCircleOutline
              color="#40AA0E"
              fontSize={"1.7rem"}
              style={{
                transform: "translateY(-3px)",
              }}
            />
          ) : (
            <IoCloseCircleOutline
              color="#DA2902"
              fontSize={"1.7rem"}
              style={{
                transform: "translateY(-3px)",
              }}
            />
          )}
        </Grid>
        <Grid
          size={{ md: 1.2, xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"}>Email</Typography>
          {data.isEmailRequired ? (
            <IoCheckmarkCircleOutline
              color="#40AA0E"
              fontSize={"1.7rem"}
              style={{
                transform: "translateY(-3px)",
              }}
            />
          ) : (
            <IoCloseCircleOutline
              color="#DA2902"
              fontSize={"1.7rem"}
              style={{
                transform: "translateY(-3px)",
              }}
            />
          )}
        </Grid>
        <Grid
          size={{ md: 1.2, xs: 12 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography fontSize={"14px"}>Portal</Typography>
          {data.isLoginRequired ? (
            <IoCheckmarkCircleOutline
              color="#40AA0E"
              fontSize={"1.7rem"}
              style={{
                transform: "translateY(-3px)",
              }}
            />
          ) : (
            <IoCloseCircleOutline
              color="#DA2902"
              fontSize={"1.7rem"}
              style={{
                transform: "translateY(-3px)",
              }}
            />
          )}
        </Grid>
        <Grid
          size={{ md: 1.7, xs: 12 }}
          sx={{
            display: {
              md: "flex",
              xs: "none",
            },
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid size={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                ":hover": { cursor: "pointer" },
              }}
            >
              <RefreshIcon
                width={"21px"}
                style={{
                  transform: "translateY(-3px)",
                }}
              />
            </Box>
          </Grid>
          <Grid size={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => {
                handleEdit(data.id);
              }}
            >
              <EditIcon
                width={"21px"}
                style={{
                  transform: "translateY(-3px)",
                }}
                active={true}
              />
            </Box>
          </Grid>
          <Grid size={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                ":hover": { cursor: "pointer" },
              }}
              onClick={() => {
                handleDelete(data.id);
              }}
            >
              <TrashIcon
                width={"21px"}
                style={{
                  transform: "translateY(-3px)",
                }}
                active={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            display: {
              xs: "flex",
              md: "none",
            },
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              ":hover": { cursor: "pointer" },
            }}
            onClick={() => {
              handleEdit(data.id);
            }}
          >
            <EditIcon
              width={"18px"}
              style={{
                transform: "translateY(-3px)",
              }}
              active={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              ":hover": { cursor: "pointer" },
            }}
            onClick={() => {
              handleDelete(data.id);
            }}
          >
            <TrashIcon
              width={"18px"}
              style={{
                transform: "translateY(-3px)",
              }}
              active={true}
            />
          </Box>
        </Box>
      </Grid>
    </ListItem>
  );
};
