import {
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from "@mui/material";

type BreadcrumbsProps = MuiBreadcrumbsProps & {
  items: { name: string; path: string }[];
};

export const Breadcrumbs = ({ items, ...props }: BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs {...props}>
      {items.map((e, index) => (
        <Link
          key={index}
          underline="hover"
          color="primary"
          href={e.path}
          sx={{
            fontWeight: 700,
          }}
        >
          {e.name}
        </Link>
      ))}
      <span />
    </MuiBreadcrumbs>
  );
};
