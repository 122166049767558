import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  ApiBasePathParams,
  ApiError,
  QueryOptions,
  QuickbooksCredentials,
} from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { AxiosError, AxiosResponse } from "axios";

export const useFetchQuickbooksAuthUriQuery = (
  orgId: number,
  queryOptions?: QueryOptions<string>
) => {
  const fetchQuickbooksAuthUri = async (): Promise<string> => {
    const path = config.api.quickbooksIntegrationService.getQBAuthUri.replace(
      "{orgId}",
      orgId.toString()
    );

    const response = await client.get<{ uri: string }>(path);

    return response.data.uri;
  };

  return useQuery(["fetchQuickbooksAuthUri", orgId], fetchQuickbooksAuthUri, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchQuickbooksCredentialsQuery = (
  orgId: number,
  queryOptions?: QueryOptions<string>
) => {
  const fetchQuickbooksCredentials =
    async (): Promise<QuickbooksCredentials> => {
      const path =
        config.api.quickbooksIntegrationService.getQBCredentials.replace(
          "{orgId}",
          orgId.toString()
        );

      const response = await client.get<{ credentials: QuickbooksCredentials }>(
        path
      );

      return response.data.credentials;
    };

  return useQuery(
    ["fetchQuickbooksCredentials", orgId],
    fetchQuickbooksCredentials,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useUpdateQuickbooksCredentialsMutation = () => {
  const mutation = useMutation<any, AxiosError<ApiError>, any, any>(
    async (
      data: Pick<ApiBasePathParams, "orgId"> & Partial<QuickbooksCredentials>
    ) => {
      const { orgId, ...quickbooksCredentials } = data;
      const path =
        config.api.quickbooksIntegrationService.createOrUpdateQBCreds.replace(
          "{orgId}",
          orgId.toString()
        );

      const response = await client.post(path, { ...quickbooksCredentials });

      return response;
    }
  );

  return mutation;
};

export const useAttachPdfToQuickbooksMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    ApiBasePathParams & { invoiceNumber: string },
    unknown
  >(async (data: ApiBasePathParams & { invoiceNumber: string }) => {
    const { orgId, accountId, invoiceNumber } = data;
    const path = config.api.quickbooksIntegrationService.attachPdfToQuickbooks
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{invoiceNumber}", invoiceNumber);

    const response = await client.post(path);

    return response;
  });

  return mutation;
};

export const useSendInvoiceToQuickbooksMutation = () => {
  const mutation = useMutation<
    any,
    AxiosError<ApiError>,
    ApiBasePathParams & { invoiceNumber: string },
    any
  >(async (data: ApiBasePathParams & { invoiceNumber: string }) => {
    const { orgId, accountId, invoiceNumber } = data;
    const path = config.api.quickbooksIntegrationService.sendInvoiceToQuickbooks
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString())
      .replace("{invoiceNumber}", invoiceNumber);

    const response = await client.post(path);

    return response;
  });

  return mutation;
};
