import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useFetchHostedPaymentsPageMutation } from "../../../api";
import { toastOptionsAtom } from "../../../atoms";
import { InvoicePaymentInitiatePayload } from "../../../types";
import Loading from "../../common/loading/Loading";

type Props = {
  payload: InvoicePaymentInitiatePayload | undefined;
  orgId: number;
  accountId?: number;
  authTransaction?: boolean;
};

export const HostedPaymentsPage = ({
  payload,
  orgId,
  accountId,
  authTransaction,
}: Props) => {
  const hasMutated = useRef(false);
  const [hostedPaymentsPageUrl, setHostedPaymentsPageUrl] = useState<string>();
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const fetchHostedPaymentsPageMutation = useFetchHostedPaymentsPageMutation();
  useEffect(() => {
    if (authTransaction) {
      fetchHostedPaymentsPageMutation.mutate({
        orgId: orgId,
        accountId: accountId || 0,
        invoiceNumber: "0",
        amount: 0,
        saveCard: true,
        authTransaction: true,
      });
    }

    if (payload && !hasMutated.current && !authTransaction) {
      hasMutated.current = true;
      setTimeout(() => {
        if (!authTransaction) {
          fetchHostedPaymentsPageMutation.mutate({
            orgId: orgId,
            accountId: payload?.accountId,
            invoiceNumber: payload?.invoiceNumber,
            amount: payload?.invoiceAmount,
            addressLine1: payload?.address.addressLine1,
            addressLine2: payload?.address.addressLine2 ?? "",
            city: payload?.address.city,
            state: payload?.address.state,
            zip: payload?.address.zip,
            saveCard: payload?.saveCard,
          });
        }
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (fetchHostedPaymentsPageMutation.isSuccess) {
      const hostedPaymentsPageUrl = fetchHostedPaymentsPageMutation.data;
      setHostedPaymentsPageUrl(hostedPaymentsPageUrl);
    }

    if (fetchHostedPaymentsPageMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    fetchHostedPaymentsPageMutation.isSuccess,
    fetchHostedPaymentsPageMutation.isError,
    fetchHostedPaymentsPageMutation.isLoading,
    fetchHostedPaymentsPageMutation.data,
    fetchHostedPaymentsPageMutation.error,
  ]);

  return (
    <Box
      sx={{
        borderRadius: "10px",
        minHeight: "630px",
        py: "auto",
      }}
    >
      <iframe
        style={{
          height: "630px",
          width: "100%",
          border: "none",
        }}
        src={hostedPaymentsPageUrl}
        onLoad={() => setIsIframeLoading(false)}
      ></iframe>
      {(fetchHostedPaymentsPageMutation.isLoading || isIframeLoading) && (
        <Loading
          message=" "
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
