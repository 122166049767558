import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { InvoiceGroup } from "../../../types";
import { Box } from "@mui/material";
import { theme } from "../../../utils";

const chartSetting = {
  yAxis: [
    {
      min: 0,
      label: "Invoice Amount ($)",
      tickFormatter: (value: number) => `${value / 1000}k`,
    },
  ],
  height: 400,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: "translate(-50px, 0)",
    },
    padding: "30px",
    overflow: "hidden",
  },
};

type InvoiceBarChartProps = {
  groupedInvoice: InvoiceGroup[];
  label: string;
};

export const InvoiceBarChart: React.FC<InvoiceBarChartProps> = ({
  groupedInvoice = [],
  label,
}) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <BarChart
        dataset={groupedInvoice}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "key",
            tickPlacement: "extremities",
            tickLabelPlacement: "middle",
            label: label,
          },
        ]}
        series={[
          {
            dataKey: "invoiceAmount",
          },
        ]}
        {...chartSetting}
      />
    </Box>
  );
};
