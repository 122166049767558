import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button } from "../common";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Search } from "../search";
import { MdOutlineFilterAlt } from "react-icons/md";
import { useEffect, useState } from "react";
import { theme } from "../../utils";
import { Filter } from "../common/filter";
import { useFetchWashTypesQuery } from "../../api/washTypeService";
import { useAtom } from "jotai";
import {
  fleetFilterStateAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
} from "../../atoms";
import { DropdownItem, FilterItem } from "../../types";
import { WashTypeModel } from "../../models/WashTypeModel";
import Loading from "../common/loading/Loading";
import { UploadCsvModal } from "../common/modal/UploadCsvModal";
import { config } from "../../config";

type FleetToolbarProps = {
  locationNumber: number;
  disableSave?: boolean;
  disableRefresh?: boolean;
  disableUpload?: boolean;
  disableDownload?: boolean;
  onRefresh?: () => void;
  onSave?: () => void;
  onDownload?: () => void;
  onSearch?: (searchItem: string) => void;
  onApplyFilters: () => void;
};

export const FleetToolbar = ({
  locationNumber,
  disableSave,
  disableRefresh,
  disableUpload,
  disableDownload,
  onRefresh,
  onSave,
  onDownload,
  onSearch,
  onApplyFilters,
}: FleetToolbarProps) => {
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [currentAccountId] = useAtom(selectedAccountIdAtom);
  const [uploadUrl, setUploadUrl] = useState(
    config.api.accountService.uploadFleet
  );
  const [uploadStatus, setUploadStatus] = useState(
    config.api.accountService.uploadStatus
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const fetchWashTypesQuery = useFetchWashTypesQuery(
    selectedOrgId,
    selectedOrgId && selectedOrgId !== 0 ? true : false
  );
  const [washTypeDropDown, setWashTypeDropDown] = useState<DropdownItem[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValues, setFilterValues] = useAtom(fleetFilterStateAtom);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(
    Object.keys(filterValues).length || 0
  );
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (Object.keys(filterValues).length === 0) {
      setAppliedFiltersCount(0);
    }
  }, [filterValues]);

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const isFilterOpen = Boolean(anchorEl);

  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );
      setWashTypeDropDown(dropdown);
    }
  }, [
    fetchWashTypesQuery.isSuccess,
    fetchWashTypesQuery.isError,
    fetchWashTypesQuery.isLoading,
    fetchWashTypesQuery.data,
    fetchWashTypesQuery.error,
  ]);

  const handleApplyFilters = () => {
    setAppliedFiltersCount(Object.keys(filterValues).length);
    onApplyFilters();
    handleFilterClose();
  };

  const filters: FilterItem[] = [
    {
      type: "text",
      label: "Vehicle Number",
      keyword: "vehicleNumber",
    },
    {
      type: "list",
      label: "Wash Type",
      values: washTypeDropDown,
      keyword: "washTypeId",
    },
    {
      type: "list",
      label: "Preselect",
      values: [
        { key: "All", value: "all" },
        { key: "Pre-selected", value: "true" },
        { key: "Not Pre-selected", value: "false" },
      ],
      keyword: "isPreSelected",
    },
  ];

  function handleUpload() {
    setUploadUrl(
      uploadUrl
        .replace("{orgId}", (selectedOrgId || 0).toString())
        .replace("{accountId}", (currentAccountId || 0).toString())
        .replace("{locationNumber}", locationNumber.toString())
    );
    setUploadStatus(
      uploadStatus
        .replace("{orgId}", (selectedOrgId || 0).toString())
        .replace("{accountId}", (currentAccountId || 0).toString())
        .replace("{locationNumber}", locationNumber.toString())
    );
    setIsModalOpen(true);
  }

  function handleUploadModalClose(): void {
    setIsModalOpen(false);
    if (onRefresh) {
      onRefresh();
    }
  }

  return (
    <Box>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        size={12}
        direction={"row"}
        sx={{ px: 2 }}
      >
        <Grid2
          container
          size={{ sm: 7, xs: 12 }}
          alignItems="center"
          direction="row"
        >
          <Grid2 size={{ xs: 12, sm: 6, lg: 3.5 }}>
            <Button
              label={"Save Preselection"}
              disabled={disableSave}
              variantType={"secondary"}
              icon={TurnedInIcon}
              sx={{ width: "100%", height: "30px", padding: 0 }}
              onClick={onSave}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, lg: 2.5 }}>
            <Button
              label={"Refresh"}
              disabled={disableRefresh}
              variantType={"secondary"}
              icon={AutorenewOutlinedIcon}
              sx={{ width: "100%", height: "30px" }}
              onClick={onRefresh}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <Button
              label={"Upload"}
              disabled={disableUpload}
              variantType={"secondary"}
              icon={FileUploadOutlinedIcon}
              sx={{ width: "100%", height: "30px" }}
              onClick={() => {
                handleUpload();
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
            <Button
              label={"Download"}
              disabled={disableDownload}
              variantType={"secondary"}
              icon={FileDownloadOutlinedIcon}
              sx={{ width: "100%", height: "30px" }}
              onClick={onDownload}
            />
          </Grid2>
        </Grid2>

        <Grid2
          container
          size={{ xs: 10, sm: 4, lg: 3 }}
          sx={{
            borderRight: { sm: "1px solid #000000", xs: "none" },
            borderLeft: { sm: "1px solid #000000", xs: "none" },
            paddingLeft: "20px",
            paddingRight: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ height: "40px" }}>
            <Search
              onInputChange={(e) => {
                if (onSearch) {
                  onSearch(e.target.value);
                }
              }}
            />
          </Box>
        </Grid2>

        <Grid2 container size={{ xs: 2, sm: 1 }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "60px",
              height: "30px",
              width: "50px",
              cursor: "pointer",
            }}
            onClick={handleFilterClick}
          >
            <Badge badgeContent={appliedFiltersCount} color="secondary">
              <MdOutlineFilterAlt
                style={{ fontSize: "22px", color: "white" }}
              />
            </Badge>
          </Box>
        </Grid2>
      </Grid2>

      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Filter
          onApplyFilters={handleApplyFilters}
          filters={filters}
          onClose={handleFilterClose}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
        />
        {fetchWashTypesQuery.isLoading && (
          <Loading
            message=" "
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 2,
              backgroundColor: "rgba(255, 255, 255, 0.6)",
            }}
          />
        )}
      </Popover>
      <UploadCsvModal
        open={isModalOpen}
        onClose={handleUploadModalClose}
        title="Import Fleet"
        message="Please import a CSV file."
        sampleCsvUrl={"/sample-files/sample.csv"}
        uploadUrl={uploadUrl}
        statusUrl={uploadStatus}
      />
    </Box>
  );
};
