import { atom } from "jotai";
import { WashInventoryFilters } from "../types";
import { getDateRange } from "../utils";

export const washInventoryFiltersAtom = atom<WashInventoryFilters>({
  fromDate: getDateRange("thisMonth").startDate,
  toDate: getDateRange("thisMonth").endDate,
  sortBy: "endTime",
  sortOrder: "desc",
});

export const washInventorySelectedDateRangeAtom = atom<string>("thisMonth");
