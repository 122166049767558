import React, { useEffect, useState } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import {
  Visibility as PreviewIcon,
  Download as DownloadIcon,
  Payment as Paymenticon,
} from "@mui/icons-material";
import { useFetchPreviewInvoiceQuery } from "../../../../api/invoiceService";
import { Modal, PreviewModal } from "../../../common";
import { downloadFile, theme } from "../../../../utils";
import Loading from "../../../common/loading/Loading";
import { InvoiceItem } from "../../../../types";
import { InvoiceStatus } from "../../../../enums";

type InvoiceActionIconsProps = {
  invoice: InvoiceItem;
  handlePayment: (event: any, invoice: InvoiceItem) => void;
};

export const InvoiceActionIcons: React.FC<InvoiceActionIconsProps> = ({
  invoice,
  handlePayment,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkToPdfData, setLinkToPdfData] = useState<string>("");
  const [fetchOnClick, setFetchOnClick] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchInvoicePreviewQuery = useFetchPreviewInvoiceQuery(
    invoice.orgId,
    invoice.accountId,
    invoice.invoiceNumber,
    { enabled: fetchOnClick }
  );
  const pdfFileName = `${invoice.invoiceNumber}.pdf`;

  useEffect(() => {
    if (fetchInvoicePreviewQuery.isSuccess && fetchInvoicePreviewQuery.data) {
      const dataToArrayBuffer = new Uint8Array(fetchInvoicePreviewQuery.data);
      const linkToPdf = URL.createObjectURL(
        new Blob([dataToArrayBuffer], {
          type: "application/pdf",
        })
      );
      setLinkToPdfData(linkToPdf);
      if (isDownloading) {
        downloadFile(linkToPdf, pdfFileName);
        setIsDownloading(false);
      }
    }
  }, [fetchInvoicePreviewQuery.isSuccess, fetchInvoicePreviewQuery.data]);

  const handleOpenModal = (event: any) => {
    event.stopPropagation();
    setFetchOnClick(true);
    setIsModalOpen(true);
  };

  const downloadPdf = (event: any) => {
    event.stopPropagation();
    if (linkToPdfData) downloadFile(linkToPdfData, pdfFileName);
    else {
      setFetchOnClick(true);
      setIsDownloading(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFetchOnClick(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flow",
          width: "50px",
        }}
      >
        <Tooltip title="Preview">
          <IconButton onClick={handleOpenModal}>
            <PreviewIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download">
          <IconButton onClick={downloadPdf}>
            <DownloadIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Pay">
          <IconButton
            onClick={(event: any) => {
              handlePayment(event, invoice);
            }}
            disabled={
              invoice.invoiceStatus !== InvoiceStatus.InProgress &&
              invoice.invoiceStatus !== InvoiceStatus.SendToCustomer
            }
          >
            <Paymenticon
              fontSize="small"
              sx={{
                ...((invoice.invoiceStatus === InvoiceStatus.InProgress ||
                  invoice.invoiceStatus === InvoiceStatus.SendToCustomer) && {
                  color: theme.palette.primary.main,
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      {isModalOpen && (
        <PreviewModal
          open={isModalOpen}
          linkToPdfData={linkToPdfData}
          loader={fetchInvoicePreviewQuery.isLoading}
          onClose={handleCloseModal}
          fileName={pdfFileName}
        />
      )}
      {isDownloading && (
        <Modal
          open={isDownloading}
          onClose={() => setIsDownloading(false)}
          title={""}
          content={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                padding: "30px",
                textAlign: "center",
                gap: 2,
              }}
            >
              <Loading message="Downloading..."></Loading>
            </Box>
          }
          sx={{
            modal: {
              width: { lg: "40%", xs: "80%", sm: "80%" },
            },
          }}
        />
      )}
    </>
  );
};
