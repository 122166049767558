import List from "@mui/material/List";
import { ContactListItem } from "./ContactListItem";
import { AccountContact } from "../../../types";
import { CreateContact } from "./CreateContact";
import { Box, Collapse } from "@mui/material";
import { useAtom } from "jotai";
import {
  selectedContactToDeleteAtom,
  selectedContactToEditAtom,
} from "../../../atoms";
import { ConfirmationModal } from "../../common";
import { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { theme } from "../../../utils";

type ContactListProps = {
  list: AccountContact[];
  handleEdit: (
    selectedContact: AccountContact,
    updatedContact: AccountContact
  ) => void;
  handleDelete: () => void;
};

export const ContactList = ({
  list,
  handleEdit,
  handleDelete,
}: ContactListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useAtom(
    selectedContactToEditAtom
  );
  const [, setSelectedContactToDelete] = useAtom(selectedContactToDeleteAtom);

  const handleClickEdit = (contactId: number) => {
    setSelectedContact(list.find((e) => e.id === contactId));
  };

  const handleEditWrapper = (contact: AccountContact) => {
    selectedContact && handleEdit(selectedContact, contact);
  };

  const handleDiscard = () => {
    setSelectedContact(undefined);
  };

  const handleOpenModal = (contactId: number) => {
    setSelectedContactToDelete(list.find((e) => e.id === contactId));
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedContactToDelete(undefined);
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    handleCloseModal();
    handleDelete();
  };

  return (
    <>
      <List
        sx={{
          width: "100%",
        }}
      >
        {list.map((item, index) => (
          <div key={index}>
            <Collapse
              sx={{ width: "100%" }}
              in={selectedContact && selectedContact.id === item.id}
              timeout={500}
            >
              <div>
                <Box sx={{ pb: 1, height: "fit-content" }}>
                  {selectedContact && (
                    <CreateContact
                      handleCreate={handleEditWrapper}
                      handleDiscard={handleDiscard}
                      defaultValues={selectedContact}
                    />
                  )}
                </Box>
              </div>
            </Collapse>
            <Collapse
              sx={{ width: "100%" }}
              in={!(selectedContact && selectedContact.id === item.id)}
              timeout={500}
            >
              <ContactListItem
                data={item}
                handleEdit={handleClickEdit}
                handleDelete={handleOpenModal}
              />
            </Collapse>
          </div>
        ))}
      </List>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={"Delete contact"}
        message={"Are you sure you want to delete this contact?"}
      />
    </>
  );
};
