import { Badge, Box, Grid2, Popover } from "@mui/material";
import { Button, DateRangePicker, SelectBox } from "../../common";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  DateRange,
  DropdownItem,
  FilterItem,
  UserPermission,
  WashInventoryFilters,
} from "../../../types";
import {
  selectedOrgIdAtom,
  userAtom,
  userPermissionAtom,
  washFilterStateAtom,
} from "../../../atoms";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { washInventoryFiltersAtom } from "../../../atoms/washInventoryAtoms";
import { getDateRange } from "../../../utils/dateUtil";
import { DATE_RANGE_OPTIONS } from "../../../constants";
import moment, { Moment } from "moment";
import {
  useFetchAccountsQuery,
  useFetchAllAccountsSummaryQuery,
  useFetchOrganizationsQuery,
  useFetchWashTypesQuery,
} from "../../../api";
import {
  AccountModel,
  UserModel,
  OrganizationModel,
  WashTypeModel,
  LocationModel,
} from "../../../models";
import { useFetchLeadsQuery } from "../../../api/userService";
import Loading from "../../common/loading/Loading";
import { Filter } from "../../common/filter";
import { theme } from "../../../utils";
import { MdOutlineFilterAlt } from "react-icons/md";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Action, DateRangeOptions, Module, Role } from "../../../enums";
import { useHasPermission } from "@zdistancelab-packages/ui-permission-validator";
import { useSearchParams } from "react-router-dom";

type WashInventoryToolBarProps = {
  onBulkEdit?: () => void;
  onRefresh?: () => void;
  onDownload?: () => void;
  resetPage: () => void;
  disableBulkEditBtn?: boolean;
  selectedDateRange: string;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<string>>;
};

export default function WashInventoryToolBar({
  onBulkEdit,
  onRefresh,
  onDownload,
  resetPage,
  selectedDateRange,
  setSelectedDateRange,
  disableBulkEditBtn = false,
}: WashInventoryToolBarProps) {
  const [user] = useAtom(userAtom);
  const [selectedOrgId, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [userPermission] = useAtom<UserPermission>(userPermissionAtom);
  const [queryParams] = useSearchParams();
  const hasWritePermission = useHasPermission(userPermission, [
    { module: Module.WashInventory, action: Action.Write },
  ]);
  const [selectedOrgIdFilter, setSelectedOrgIdFilter] = useState(
    user?.orgId || 0
  );
  const fetchAccountsSummaryQuery = useFetchAllAccountsSummaryQuery(
    selectedOrgIdFilter ? selectedOrgIdFilter : user?.orgId || 0
  );
  const [summary, setSummary] = useState<any[]>([]);

  const fetchWashTypesQuery = useFetchWashTypesQuery(
    selectedOrgIdFilter,
    selectedOrgIdFilter ? true : false
  );
  const fetchLeadUsersQuery = useFetchLeadsQuery(
    selectedOrgIdFilter,
    selectedOrgIdFilter ? true : false
  );
  const fetchAccountsQuery = useFetchAccountsQuery(selectedOrgIdFilter, {
    enabled: selectedOrgIdFilter ? true : false,
  });
  const fetchOrganizationsQuery = useFetchOrganizationsQuery({
    enabled: user?.roleCode === Role.SuperAdmin,
  });

  const [washInventoryFilters, setWashInventoryFilters] = useAtom(
    washInventoryFiltersAtom
  );

  const [washTypeDropDown, setWashTypeDropDown] = useState<DropdownItem[]>([]);
  const [leadUserDropDown, setLeadUserDropDown] = useState<DropdownItem[]>([]);
  const [locationsDropdown, setLocationsDropdown] = useState<DropdownItem[]>(
    []
  );
  const [organizationsDropdown, setOrganizationsDropdown] = useState<
    DropdownItem[]
  >([]);
  const [accountsDropdown, setAccountsDropdown] = useState<DropdownItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isFilterOpen = Boolean(anchorEl);

  const filters = useMemo<FilterItem[]>(() => {
    const baseFilters: FilterItem[] = [
      {
        type: "list",
        label: "User",
        values: leadUserDropDown,
        keyword: "userId",
      },
      {
        type: "list",
        label: "Wash Type",
        values: washTypeDropDown,
        keyword: "washTypeId",
      },
      {
        type: "text",
        label: "Vehicle Number",
        keyword: "vehicleNumber",
      },
    ];

    if (user?.roleCode === Role.SuperAdmin) {
      return [
        {
          type: "list",
          label: "Organization",
          values: organizationsDropdown,
          keyword: "orgId",
        },
        {
          type: "list",
          label: "Account",
          values: accountsDropdown,
          keyword: "accountId",
        },
        {
          type: "list",
          label: "Location",
          values: locationsDropdown,
          keyword: "locationNumber",
        },
        ...baseFilters,
      ];
    }
    if (user?.roleCode === Role.Admin) {
      return [
        {
          type: "list",
          label: "Account",
          values: accountsDropdown,
          keyword: "accountId",
        },
        {
          type: "list",
          label: "Location",
          values: locationsDropdown,
          keyword: "locationNumber",
        },
        ...baseFilters,
      ];
    }

    return baseFilters;
  }, [
    user?.roleCode,
    leadUserDropDown,
    washTypeDropDown,
    organizationsDropdown,
    accountsDropdown,
    locationsDropdown,
  ]);

  const [filterValues, setFilterValues] = useAtom(washFilterStateAtom);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const dateRanges: DropdownItem[] = [
    ...DATE_RANGE_OPTIONS.map((option) => ({
      key: option,
      value: option.replace(/\s+/g, "").replace(/^\w/, (c) => c.toLowerCase()),
    })),
  ];

  useEffect(() => {
    if (fetchAccountsSummaryQuery.isSuccess) {
      setSummary(fetchAccountsSummaryQuery.data);
    } else if (fetchAccountsSummaryQuery.isError) {
      setSummary([]);
    }
  }, [
    fetchAccountsSummaryQuery.isSuccess,
    fetchAccountsSummaryQuery.data,
    fetchAccountsSummaryQuery.isError,
  ]);

  const handleOnFilterChange = (changedFilter: { [key: string]: any }) => {
    const keyName = Object.keys(changedFilter)[0];
    if (keyName === "accountId") {
      summary.map((summary) => {
        if (summary.accountId === changedFilter?.accountId) {
          const dropdown = LocationModel.buildLocationDropdown(
            summary.locations
          );
          setLocationsDropdown(dropdown);
        }
      });

      setFilterValues((prev) => ({
        ...prev,
        [keyName]: changedFilter?.accountId,
      }));
    }
    if (keyName === "orgId") {
      setFilterValues((prev) => ({
        ...prev,
        [keyName]: changedFilter.orgId,
      }));
    }
  };

  const handleApplyFilters = () => {
    resetPage();
    const appliedFilters: WashInventoryFilters = { ...washInventoryFilters };
    filters.forEach((filter) => {
      const filterKeyword = filter.keyword as keyof WashInventoryFilters;
      const filterValue = filterValues[filter.keyword];
      if (filterKeyword === "orgId") {
        setSelectedOrgId(filterValue);
      }
      if (filterValue !== undefined && filterValue !== null) {
        appliedFilters[filterKeyword] = filterValue;
      } else {
        delete appliedFilters[filterKeyword];
      }
    });
    setWashInventoryFilters(appliedFilters);
    handleFilterClose();
  };

  useEffect(() => {
    if (user?.orgId) {
      setSelectedOrgIdFilter(user.orgId);
    }
  }, [user]);

  useEffect(() => {
    handleApplyFilters();
    return () => {
      const appliedFilters: WashInventoryFilters = {};
      filters.forEach((filter) => {
        const filterKeyword = filter.keyword as keyof WashInventoryFilters;
        const filterValue = filterValues[filter.keyword];
        if (filterKeyword === "orgId") {
          if (filterValue !== undefined && filterValue !== null) {
            appliedFilters[filterKeyword] = filterValue;
          } else {
            delete appliedFilters[filterKeyword];
          }
        }
      });
      setFilterValues({});
      setWashInventoryFilters({ ...washInventoryFilters, ...appliedFilters });
    };
  }, []);

  useEffect(() => {
    if (user?.roleCode === Role.Admin || user?.roleCode === Role.SuperAdmin) {
      setSelectedOrgIdFilter(filterValues["orgId"] || user?.orgId || 0);
      setLocationsDropdown([]);
    } else setSelectedOrgIdFilter(selectedOrgId);
  }, [filterValues["orgId"]]);

  useEffect(() => {
    if (fetchOrganizationsQuery.isSuccess) {
      const dropdown = OrganizationModel.buildOrganizationDropdown(
        fetchOrganizationsQuery.data
      );
      setOrganizationsDropdown(dropdown);
      const orgId = queryParams.get("orgId")
        ? Number(queryParams.get("orgId"))
        : fetchOrganizationsQuery.data[0].orgId;
      setSelectedOrgId(orgId);
      setSelectedOrgIdFilter(orgId);
      const filters = {
        ...filterValues,
        orgId: orgId,
      };
      setFilterValues(filters);
      setWashInventoryFilters({
        ...washInventoryFilters,
        orgId: orgId,
      });
    } else if (fetchOrganizationsQuery.isError) {
      setOrganizationsDropdown([]);
    }
  }, [
    fetchOrganizationsQuery.isSuccess,
    fetchOrganizationsQuery.isError,
    fetchOrganizationsQuery.data,
    fetchOrganizationsQuery.error,
    fetchOrganizationsQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchAccountsQuery.isSuccess) {
      const dropdown = AccountModel.buildAccountsDropdown(
        fetchAccountsQuery.data
      );
      setAccountsDropdown(dropdown);
    } else if (fetchAccountsQuery.isError) {
      setAccountsDropdown([]);
    }
  }, [
    fetchAccountsQuery.isSuccess,
    fetchAccountsQuery.data,
    fetchAccountsQuery.isError,
    fetchAccountsQuery.isLoading,
    fetchAccountsQuery.isFetching,
    fetchAccountsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchWashTypesQuery.isSuccess) {
      const dropdown: DropdownItem[] = WashTypeModel.buildWashTypeDropDown(
        fetchWashTypesQuery.data
      );
      setWashTypeDropDown(dropdown);
    } else if (fetchWashTypesQuery.isError) {
      setWashTypeDropDown([]);
    }
  }, [
    fetchWashTypesQuery.isSuccess,
    fetchWashTypesQuery.isError,
    fetchWashTypesQuery.data,
  ]);

  useEffect(() => {
    if (fetchLeadUsersQuery.isSuccess) {
      const dropdown: DropdownItem[] = UserModel.buildLeadUserDropdown(
        fetchLeadUsersQuery.data
      );
      setLeadUserDropDown(dropdown);
    } else if (fetchLeadUsersQuery.isError) {
      setLeadUserDropDown([]);
    }
  }, [
    fetchLeadUsersQuery.isSuccess,
    fetchLeadUsersQuery.isError,
    fetchLeadUsersQuery.data,
  ]);

  const handleDateChange = (selectedDateRange: string) => {
    resetPage();
    setSelectedDateRange(selectedDateRange);
    if (selectedDateRange !== "customDate") {
      const dateRange: DateRange = getDateRange(selectedDateRange);
      setWashInventoryFilters({
        ...washInventoryFilters,
        fromDate: dateRange.startDate.toString(),
        toDate: dateRange.endDate.toString(),
        dateRange: Object.values(DateRangeOptions).includes(
          selectedDateRange as DateRangeOptions
        )
          ? selectedDateRange
          : undefined,
      });
    } else {
      setWashInventoryFilters({
        ...washInventoryFilters,
        dateRange: selectedDateRange,
      });
    }
  };

  const handleCustomDateChange = (
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    if (!startDate) {
      startDate = moment(washInventoryFilters.fromDate);
    }
    if (!endDate) {
      endDate = moment(washInventoryFilters.toDate);
    }
    if (startDate && endDate) {
      const startDateStartOfTheDay = startDate.startOf("day").toISOString();
      const endDateEndOfTheDay = endDate.endOf("day").toISOString();
      resetPage();
      setWashInventoryFilters({
        ...washInventoryFilters,
        fromDate: startDateStartOfTheDay,
        toDate: endDateEndOfTheDay,
      });
    }
  };

  useEffect(() => {
    const accountId = Number(queryParams.get("accountId")) || undefined;
    if (accountId) {
      summary.map((summary) => {
        if (summary.accountId === accountId) {
          const dropdown = LocationModel.buildLocationDropdown(
            summary.locations
          );
          setLocationsDropdown(dropdown);
        }
      });
    }
  }, [summary]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        p={1}
      >
        <Grid2
          pl={1}
          size={{ lg: 6, md: 12, sm: 12, xs: 12 }}
          container
          alignItems="flex-end"
          justifyContent={"flex-start"}
        >
          <Grid2 size={{ lg: 4.5, sm: 6, xs: 12 }}>
            <SelectBox
              label="Date"
              name="date"
              dropdown={dateRanges}
              value={selectedDateRange}
              onChange={(e) => handleDateChange(e.target.value)}
              sx={{ width: "100%", height: "33px" }}
            />
          </Grid2>
          {selectedDateRange === "customDate" && (
            <Grid2 size={{ lg: 12, sm: 12, xs: 12 }} alignSelf={"end"}>
              <DateRangePicker
                start={moment(washInventoryFilters.fromDate)}
                end={moment(washInventoryFilters.toDate)}
                onDateChange={(startDate, endDate) => {
                  handleCustomDateChange(startDate, endDate);
                }}
              />
            </Grid2>
          )}
        </Grid2>
        <Grid2
          pr={1}
          container
          alignItems="center"
          size={{ lg: 6, md: 12, xs: 12 }}
          justifyContent="flex-end"
        >
          {hasWritePermission && (
            <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
              <Button
                label={"Bulk Edit"}
                variantType={"primary"}
                icon={EditOutlinedIcon}
                onClick={onBulkEdit}
                disabled={disableBulkEditBtn}
                sx={{
                  width: "100%",
                  height: "30px",
                  padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
                }}
              />
            </Grid2>
          )}
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Button
              label={"Refresh"}
              variantType={"secondary"}
              icon={AutorenewOutlinedIcon}
              onClick={onRefresh}
              sx={{
                width: "100%",
                height: "30px",
                padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 4, lg: 3 }}>
            <Button
              label={"Download"}
              variantType={"secondary"}
              icon={FileDownloadOutlinedIcon}
              sx={{
                width: "100%",
                height: "30px",
                padding: { xl: "1rem", lg: "0", xs: "0", sm: "0" },
              }}
              onClick={onDownload}
            />
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 4, lg: 1 }}
            sx={{ flexBasis: { xs: "content", sm: "content", lg: "content" } }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "60px",
                height: "30px",
                width: "50px",
                cursor: "pointer",
              }}
              onClick={handleFilterClick}
            >
              <Badge
                badgeContent={Object.values(filterValues).length}
                color="secondary"
              >
                <MdOutlineFilterAlt
                  style={{ fontSize: "22px", color: "white" }}
                />
              </Badge>
            </Box>
          </Grid2>
        </Grid2>
      </Grid2>
      <Popover
        open={isFilterOpen}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <>
          <Filter
            onApplyFilters={handleApplyFilters}
            onFilterChange={handleOnFilterChange}
            filters={filters}
            onClose={handleFilterClose}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            preservedFilters={["orgId"]}
          />
          {(fetchWashTypesQuery.isLoading ||
            fetchAccountsQuery.isLoading ||
            fetchLeadUsersQuery.isLoading) && (
            <Loading
              message=" "
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2,
                backgroundColor: "rgba(255, 255, 255, 0.6)",
              }}
            />
          )}
        </>
      </Popover>
    </Box>
  );
}
