import React, { useEffect, useState } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import {
  Visibility as PreviewIcon,
  Info as InfoIcon,
  Download as DownloadIcon,
  AttachFile as AttachFileIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import { useFetchPreviewInvoiceQuery } from "../../../api/invoiceService";
import { Modal, PreviewModal } from "../../common";
import {
  buildQueryString,
  downloadFile,
  getInvoiceDetailsRoute,
  theme,
} from "../../../utils";
import Loading from "../../common/loading/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "../../../atoms";
import { ACCOUNT_DETAILS_TAB_KEY } from "../../../constants";
import { Action, InvoiceStatus, Module, Role } from "../../../enums";
import { InvoiceItem } from "../../../types";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";

type InvoiceActionIconsProps = {
  invoice: InvoiceItem;
  handleAttachPdf: (invoice: InvoiceItem) => void;
  handleRegenerateInvoice: (invoice: InvoiceItem) => void;
};

export const InvoiceActionIcons: React.FC<InvoiceActionIconsProps> = ({
  invoice,
  handleAttachPdf,
  handleRegenerateInvoice,
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkToPdfData, setLinkToPdfData] = useState<string>("");
  const [fetchOnClick, setFetchOnClick] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [user] = useAtom(userAtom);

  const fetchInvoicePreviewQuery = useFetchPreviewInvoiceQuery(
    invoice.orgId,
    invoice.accountId,
    invoice.invoiceNumber,
    { enabled: fetchOnClick }
  );
  const pdfFileName = `${invoice.invoiceNumber}.pdf`;

  useEffect(() => {
    if (fetchInvoicePreviewQuery.isSuccess && fetchInvoicePreviewQuery.data) {
      const dataToArrayBuffer = new Uint8Array(fetchInvoicePreviewQuery.data);
      const linkToPdf = URL.createObjectURL(
        new Blob([dataToArrayBuffer], {
          type: "application/pdf",
        })
      );
      setLinkToPdfData(linkToPdf);
      if (isDownloading) {
        downloadFile(linkToPdf, pdfFileName);
        setIsDownloading(false);
      }
    }
  }, [fetchInvoicePreviewQuery.isSuccess, fetchInvoicePreviewQuery.data]);

  const showInvoiceDetails = (event: any) => {
    event.stopPropagation();
    if (user) {
      const tab = queryParams.get(ACCOUNT_DETAILS_TAB_KEY);
      const route = getInvoiceDetailsRoute(user);
      const path =
        (tab ? `${tab}/` : "") +
        route.path.replace(":invoiceNumber", invoice.invoiceNumber).concat(
          buildQueryString({
            orgId: invoice.orgId,
            accountId: invoice.accountId,
          })
        );

      navigate(path);
    }
  };

  const regenerateInvoice = (event: any) => {
    event.stopPropagation();
    handleRegenerateInvoice(invoice);
  };

  const handleOpenModal = (event: any) => {
    event.stopPropagation();
    setFetchOnClick(true);
    setIsModalOpen(true);
  };

  const downloadPdf = (event: any) => {
    event.stopPropagation();
    if (linkToPdfData) downloadFile(linkToPdfData, pdfFileName);
    else {
      setFetchOnClick(true);
      setIsDownloading(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFetchOnClick(false);
  };

  const handleAttachPdfWrapper = (event: any) => {
    event.stopPropagation();

    handleAttachPdf(invoice);
  };

  return (
    <>
      <Box
        sx={{
          display: "flow",
          width: "50px",
        }}
      >
        <Tooltip title="Preview">
          <IconButton onClick={handleOpenModal}>
            <PreviewIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download">
          <IconButton onClick={downloadPdf}>
            <DownloadIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Details">
          <IconButton onClick={showInvoiceDetails}>
            <InfoIcon
              fontSize="small"
              sx={{ color: theme.palette.primary.main }}
            />
          </IconButton>
        </Tooltip>
        <AllowedAccess
          roles={[Role.SuperAdmin, Role.Admin]}
          permissions={[{ module: Module.Invoice, action: Action.Write }]}
        >
          <Tooltip title="Regenerate Invoice">
            <IconButton
              sx={{
                "&.Mui-disabled": {
                  pointerEvents: "auto",
                },
              }}
              onClick={regenerateInvoice}
              disabled={invoice.invoiceStatus !== InvoiceStatus.Pending}
            >
              <RestartAltIcon
                fontSize="small"
                sx={{
                  ...(invoice.invoiceStatus === InvoiceStatus.Pending && {
                    color: theme.palette.primary.main,
                  }),
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Attach PDF">
            <IconButton
              sx={{
                "&.Mui-disabled": {
                  pointerEvents: "auto",
                },
              }}
              onClick={handleAttachPdfWrapper}
              disabled={
                invoice.quickbookInvoiceId === null ||
                invoice.isPdfAttached ||
                invoice.invoiceStatus !== InvoiceStatus.InProgress
              }
            >
              <AttachFileIcon
                fontSize="small"
                sx={{
                  ...(!(
                    invoice.quickbookInvoiceId === null ||
                    invoice.isPdfAttached ||
                    invoice.invoiceStatus !== InvoiceStatus.InProgress
                  ) && {
                    color: theme.palette.primary.main,
                  }),
                }}
              />
            </IconButton>
          </Tooltip>
        </AllowedAccess>
      </Box>

      {isModalOpen && (
        <PreviewModal
          open={isModalOpen}
          linkToPdfData={linkToPdfData}
          loader={fetchInvoicePreviewQuery.isLoading}
          onClose={handleCloseModal}
          fileName={pdfFileName}
        />
      )}
      {isDownloading && (
        <Modal
          open={isDownloading}
          onClose={() => setIsDownloading(false)}
          title={""}
          content={
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                padding: "30px",
                textAlign: "center",
                gap: 2,
              }}
            >
              <Loading message="Downloading..."></Loading>
            </Box>
          }
          sx={{
            modal: {
              width: { lg: "40%", xs: "80%", sm: "80%" },
            },
          }}
        />
      )}
    </>
  );
};
