import React from "react";
import { Modal as MuiModal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "../button";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  form?: string;
  sx?: {
    modal?: object;
    header?: object;
    content?: object;
    footer?: object;
  };
  hideBackdrop?: boolean;
  disablePrimaryButton?: boolean;
};

const defaultStyle = {
  modal: {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    borderRadius: 2,
    overflow: "hidden",
  },
  header: {
    borderBottom: "1px solid #e0e0e0",
    padding: "5px 16px",
  },
  content: {
    padding: "5px 16px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    borderTop: "1px solid #e0e0e0",
  },
};

export const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  content,
  primaryButtonText,
  secondaryButtonText,
  form,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  sx = {},
  disablePrimaryButton = false,
  hideBackdrop = false,
}) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="dynamic-modal-title"
      aria-describedby="dynamic-modal-description"
      hideBackdrop={hideBackdrop}
    >
      <Box sx={{ ...defaultStyle.modal, ...sx.modal }}>
        <Box sx={{ ...defaultStyle.header, ...sx.header }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography id="dynamic-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ ...defaultStyle.content, ...sx.content }}>{content}</Box>

        <Box sx={{ ...defaultStyle.footer, ...sx.footer }}>
          {secondaryButtonText && (
            <Button
              variantType="secondary"
              color="primary"
              sx={{
                minWidth: 130,
                marginRight: 2,
                width: { xs: "fit-content" },
              }}
              label={secondaryButtonText}
              onClick={onSecondaryButtonClick}
            />
          )}
          {primaryButtonText && (
            <Button
              {...(form && { form })}
              type={form ? "submit" : "button"}
              variantType="primary"
              color="primary"
              sx={{ minWidth: 130, width: { xs: "fit-content" } }}
              label={primaryButtonText}
              onClick={form ? undefined : onPrimaryButtonClick}
              disabled={disablePrimaryButton}
            />
          )}
        </Box>
      </Box>
    </MuiModal>
  );
};
