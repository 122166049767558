import React, { useEffect, useState } from "react";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { changePasswordSchema } from "../../../../validations";
import { useValidateForm } from "../../../../hooks";
import { Button, TextField } from "../../../common";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PasswordRequirementTooltip } from "./PasswordRequirementTooltip";

type ChangeUserPasswordFormProps = {
  isOldPasswordRequired?: boolean;
  isUserNameRequired?: boolean;
  handleUpdatePassword: (data: any) => void;
};

export const ChangeUserPasswordForm: React.FC<ChangeUserPasswordFormProps> = ({
  isOldPasswordRequired = true,
  isUserNameRequired = false,
  handleUpdatePassword,
}) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors, reset } = useValidateForm(
    changePasswordSchema(isOldPasswordRequired, isUserNameRequired)
  );
  const [password, setPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [toolTipPlacement, setToolTipPlacement] = useState<
    "top" | "bottom" | "right" | "left" | "right-end"
  >("right");
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleKeydown = (event: React.KeyboardEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setToolTipPlacement("right");
  };

  const handleInfoIconHover = (event: React.MouseEvent<HTMLElement> | null) => {
    setAnchorEl(event ? event.currentTarget : null);
    setToolTipPlacement("right-end");
  };
  const handleBlur = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    reset();
  }, []);

  const onSubmit = (formData: any) => {
    setLoading(true);
    if (!isOldPasswordRequired) {
      delete formData.oldPassword;
    }
    handleUpdatePassword(formData);
  };

  return (
    <Box
      onSubmit={handleSubmit(onSubmit)}
      component={"form"}
      id="changePasswordForm"
      noValidate
      sx={{
        width: "100%",
      }}
    >
      {isUserNameRequired && (
        <Box sx={{ mb: 3 }}>
          <TextField
            label={"Username"}
            type="text"
            name={"userName"}
            labelVariant="body2"
            labelColor="textSecondary"
            register={isUserNameRequired && register}
            error={isUserNameRequired && errors.userName}
            required
          />
        </Box>
      )}
      {isOldPasswordRequired && (
        <Box sx={{ mb: 3 }}>
          <TextField
            label={"Old Password"}
            type={showOldPassword ? "text" : "password"}
            name={"oldPassword"}
            labelVariant="body2"
            labelColor="textSecondary"
            register={isOldPasswordRequired && register}
            error={isOldPasswordRequired && errors.oldPassword}
            required
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleOldPasswordVisibility}
                      edge="end"
                    >
                      {showOldPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>
      )}
      <Box sx={{ mb: 3 }}>
        <TextField
          label={"New Password"}
          type={showNewPassword ? "text" : "password"}
          name={"newPassword"}
          labelVariant="body2"
          labelColor="textSecondary"
          register={register}
          error={errors.newPassword}
          onKeyDown={handleKeydown}
          showInfoIcon={true}
          onInfoIconHover={handleInfoIconHover}
          onBlurCapture={handleBlur}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleNewPasswordVisibility}
                    edge="end"
                  >
                    {showNewPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <PasswordRequirementTooltip
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          password={password}
          placement={toolTipPlacement}
        />
      </Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          label={"Confirm Password"}
          type="password"
          name={"confirmNewPassword"}
          error={errors.confirmNewPassword}
          register={register}
          required
          labelVariant="body2"
          labelColor="textSecondary"
        />
      </Box>
      <Box sx={{ pt: 1, mb: 2 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          label={"Change Password"}
        />
      </Box>
    </Box>
  );
};
