import { Typography, Grid2 as Grid, Box, Skeleton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import LocationIcon from "@mui/icons-material/LocationOn";

export const Address = ({
  title,
  address,
  contactNumber,
  email,
  isLoading,
}: {
  title: string;
  address: string;
  contactNumber: string;
  email: string;
  isLoading: boolean;
}) => {
  return (
    <Grid size={2}>
      <Typography variant="subtitle1" fontWeight={"bold"}>
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <LocationIcon
          sx={{ fontSize: "14px", color: "#929797", paddingBottom: "1px" }}
        />
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
        ) : (
          <Typography variant="subtitle2" fontWeight={"300"}>
            {address}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <CallIcon
          sx={{ fontSize: "14px", color: "#929797", paddingBottom: "1px" }}
        />
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
        ) : (
          <Typography variant="subtitle2" fontWeight={"300"}>
            {contactNumber}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <EmailIcon
          sx={{ fontSize: "14px", color: "#929797", paddingBottom: "1px" }}
        />
        {isLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100%" }} />
        ) : (
          <Typography variant="subtitle2" fontWeight={"300"}>
            {email}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};
