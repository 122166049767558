import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  OrganizationInfo,
  Organization,
  QueryOptions,
  ApiError,
  ApiBasePathParams,
  OrganizationProfileInfo,
  OrganizationAddressInfo,
} from "../types";
import { DEFAULT_QUERY_OPTIONS } from "../constants";
import { AxiosError, AxiosResponse } from "axios";

export const useFetchOrganizationsQuery = (
  queryOptions?: QueryOptions<Organization[]>
) => {
  const fetchOrganizations = async (): Promise<Organization[]> => {
    const path = config.api.organizationService.getAllOrganizations;
    const response = await client.get<any>(path);

    return response.data.organisations;
  };

  return useQuery("fetchOrganizations", fetchOrganizations, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchOrganizationInfoQuery = (
  orgId: number,
  queryOptions?: QueryOptions<OrganizationInfo>
) => {
  const fetchOrganizationInfo = async (): Promise<OrganizationInfo> => {
    const path = config.api.organizationService.getOrganisation.replace(
      "{orgId}",
      orgId.toString()
    );

    const response = await client.get<{
      organisation: OrganizationInfo;
    }>(path);

    return response.data.organisation;
  };

  return useQuery(["fetchOrganizationInfo", orgId], fetchOrganizationInfo, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useCreateOrganizationMutation = () => {
  const mutation = useMutation<
    number,
    AxiosError<ApiError>,
    Pick<ApiBasePathParams, "orgId"> & {
      organizationInfo: {
        name: string;
        logo?: string;
      };
    },
    unknown
  >(
    async (
      data: Pick<ApiBasePathParams, "orgId"> & {
        organizationInfo: {
          name: string;
          logo?: string;
        };
      }
    ) => {
      const { orgId, organizationInfo } = data;
      const path = config.api.organizationService.createOrganisation.replace(
        "{orgId}",
        orgId.toString()
      );

      const response = await client.post<{ orgId: number }>(path, {
        ...organizationInfo,
      });
      return response.data.orgId;
    }
  );

  return mutation;
};

export const useUpdateOrganizationMutation = () => {
  const mutation = useMutation<any, AxiosError<ApiError>, any, any>(
    async (
      data: Pick<ApiBasePathParams, "orgId"> & Partial<OrganizationProfileInfo>
    ) => {
      const { orgId, ...organizationProfileInfo } = data;
      const path = config.api.organizationService.updateOrganisation.replace(
        "{orgId}",
        orgId.toString()
      );

      const response = await client.patch(path, { ...organizationProfileInfo });

      return response;
    }
  );

  return mutation;
};

export const useFetchOrganizationLogoUploadPreSignedUrlQuery = (
  orgId: number,
  fileName: string,
  queryOptions?: QueryOptions<string>
) => {
  const fetchPreSignedUrl = async (): Promise<string> => {
    const path = config.api.organizationService.getOrgLogoUploadSignedUrl
      .replace("{orgId}", orgId.toString())
      .replace("{fileName}", fileName.toString());

    const response = await client.get<{ url: string; key: string }>(path);

    return response.data.url;
  };

  return useQuery(
    ["organizationFetchPreSignedUrl", orgId, fileName],
    fetchPreSignedUrl,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useUpdateOrganizationAddressMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    Pick<ApiBasePathParams, "orgId"> & Partial<OrganizationAddressInfo>,
    unknown
  >(
    async (
      data: Pick<ApiBasePathParams, "orgId"> & Partial<OrganizationAddressInfo>
    ) => {
      const { orgId, ...organizationAddressInfo } = data;
      const path =
        config.api.organizationService.updateOrganisationAddress.replace(
          "{orgId}",
          orgId.toString()
        );

      const response = await client.patch(path, { ...organizationAddressInfo });

      return response;
    }
  );

  return mutation;
};

export const useDeleteOrganizationMutation = () => {
  const mutation = useMutation(async (data: { orgId: number }) => {
    const { orgId } = data;
    const path = config.api.organizationService.deleteOrganisation.replace(
      "{orgId}",
      orgId.toString()
    );

    const response = await client.delete(path);
    return response;
  });

  return mutation;
};
