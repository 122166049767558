import React, { useState } from "react";
import { IconButton, Box, Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { ConfirmationModal, Modal } from "../modal";
import { ModalForm } from "../../../types";
import { theme } from "../../../utils";

type ActionIconsV2Props = {
  rowData: any;
  formProps?: { form: React.FC<ModalForm<any, any, any>>; name: string };
  editProps?: {
    title?: string;
    primaryButtonText?: string;
    secondaryButtonText?: string;
  };
  deleteProps?: {
    title?: string;
    description?: string;
  };
  hasWritePermission?: boolean;
  hasDeletePermission?: boolean;
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
};

export const ActionIconsV2: React.FC<ActionIconsV2Props> = ({
  rowData,
  formProps,
  editProps,
  deleteProps,
  hasWritePermission = true,
  hasDeletePermission = true,
  onEdit,
  onDelete,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleOpenEditModal = () => setIsEditModalOpen(true);
  const handleCloseEditModal = () => setIsEditModalOpen(false);
  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);
  const handleEditWrapper = (data: any) => {
    onEdit(data);
    handleCloseEditModal();
  };

  const handleConfirmDelete = () => {
    onDelete(rowData);
    handleCloseDeleteModal();
  };

  return (
    <>
      <Box
        sx={{
          display: "flow",
          width: "50px",
        }}
      >
        {formProps && hasWritePermission && (
          <Tooltip title="Edit">
            <IconButton onClick={handleOpenEditModal} aria-label="edit">
              <EditOutlinedIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Tooltip>
        )}
        {hasDeletePermission && (
          <Tooltip title="Delete">
            <IconButton onClick={handleOpenDeleteModal} aria-label="delete">
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {formProps && (
        <Modal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          title={editProps?.title || "Edit"}
          content={
            <formProps.form
              id={formProps.name}
              handleEdit={handleEditWrapper}
              defaultValues={rowData}
            ></formProps.form>
          }
          form={formProps.name}
          primaryButtonText={editProps?.primaryButtonText || "Confirm"}
          secondaryButtonText={editProps?.secondaryButtonText || "Cancel"}
          onSecondaryButtonClick={handleCloseEditModal}
          sx={{
            modal: {
              width: { lg: "30%", xs: "80%", sm: "50%" },
            },
          }}
        />
      )}
      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        icon={
          <DeleteOutlineOutlinedIcon
            fontSize="medium"
            sx={{ color: theme.palette.primary.main }}
          />
        }
        title={deleteProps?.title || "Delete Item"}
        message={
          deleteProps?.description ||
          "Are you sure you want to delete this item?"
        }
      />
    </>
  );
};
