import {
  Box,
  Divider,
  Grid2 as Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { OrganizationIntegrationsInfo } from "../../../types";
import { EditIcon } from "../../icons";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../../enums";
import { useEffect } from "react";

type ViewPaymentGatewayIntegrationInfoProps = {
  data?: OrganizationIntegrationsInfo;
  isLoading: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewPaymentGatewayIntegrationInfo = ({
  data,
  isLoading = false,
  handleEditMode,
}: ViewPaymentGatewayIntegrationInfoProps) => {
  return (
    <Grid
      container
      size={{ sm: 12, md: 6 }}
      direction={"column"}
      spacing={4}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000040",
        borderRadius: "10px",
        p: 4,
        position: "relative",
        justifyContent: "flex-start",
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "600", pb: 1 }}>
          Payment Gateway
        </Typography>
        <Divider />
      </Box>
      <AllowedAccess
        roles={[Role.SuperAdmin]}
        permissions={[{ module: Module.Organization, action: Action.Write }]}
      >
        <Box
          onClick={() => handleEditMode(true)}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        >
          <EditIcon width={"21px"} active={true} />
        </Box>
      </AllowedAccess>
      <Grid container size={12}>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Worldpay Acceptor ID
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.worldpay && data.worldpay.wpAcceptorId
                    ? "*".repeat(data.worldpay.wpAcceptorId.length)
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Worldpay Account ID
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.worldpay && data.worldpay.wpAccountId
                    ? "*".repeat(data.worldpay.wpAccountId.length)
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Worldpay Account Token
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.worldpay && data.worldpay.wpAccountToken
                    ? "*".repeat(data.worldpay.wpAccountToken.length)
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
        <Grid container size={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
            }}
          >
            {isLoading || !data ? (
              <Skeleton
                variant="rectangular"
                sx={{ height: 67, width: "100%" }}
              />
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  Worldpay Terminal Id
                </Typography>
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "left",
                    wordWrap: "break-word",
                  }}
                >
                  {data.worldpay && data.worldpay.wpTerminalId
                    ? "*".repeat(data.worldpay.wpTerminalId.length)
                    : "N/A"}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
