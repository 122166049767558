import { Box, Grid2 } from "@mui/material";
import { DataGrid, Modal, Toast } from "../common";
import {
  InvoiceFilters,
  InvoiceInfo,
  InvoiceItem,
  InvoiceOverviewInfo,
  InvoiceRequestParams,
  ToastOptions,
} from "../../types";
import {
  buildQueryString,
  downloadFile,
  getInvoiceDetailsRoute,
  theme,
} from "../../utils";
import { useAtom } from "jotai";
import {
  invoiceDataGridPaginationAtom,
  invoiceFilterStateAtom,
  isInvoiceActionsMutationsLoadingAtom,
  isReceivePaymentFormActiveAtom,
  selectedAccountAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
  userAtom,
} from "../../atoms";
import {
  useFetchDownloadInvoicesCsvQuery,
  useFetchInvoiceOverviewQuery,
  useFetchInvoicesQuery,
  useRegenerateInvoiceMutation,
} from "../../api/invoiceService";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../utils/dateUtil";
import { InvoiceActionIcons, InvoiceToolbar } from "./fragments";
import {
  DateRangeOptions,
  InvoicePaymentCallbackMessage,
  InvoiceStatus,
  Role,
} from "../../enums";
import { CreateInvoice } from "./CreateInvoice";
import { queryClient } from "../../App";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ACCOUNT_DETAILS_TAB_KEY,
  CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN,
} from "../../constants";
import { useAttachPdfToQuickbooksMutation } from "../../api";
import { CreateRegenerateInvoiceModal } from "../common/modal/CreateRegenerateInvoiceModal";
import { PaymentDialog } from "../payment";

export const Invoice = () => {
  const navigate = useNavigate();
  const [selectedDateRange, setSelectedDateRange] =
    useState<string>("thisMonth");
  const [queryParams, setQueryParams] = useSearchParams();
  const [user] = useAtom(userAtom);
  const [filterValues, setFilterValues] = useAtom(invoiceFilterStateAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [selectedAccount] = useAtom(selectedAccountAtom);
  const [invoiceList, setInvoiceList] = useState<InvoiceInfo>();
  const [isInvoiceActionsMutationsLoading] = useAtom(
    isInvoiceActionsMutationsLoadingAtom
  );
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [orgId, setOrgId] = useState(0);
  const [accountId, setAccountId] = useState(0);
  const [invoicePaginationModel, setInvoicePaginationModel] = useAtom(
    invoiceDataGridPaginationAtom
  );
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceItem[]>([]);
  const [isReceivePaymentFormActive, setIsReceivePaymentFormActive] = useAtom(
    isReceivePaymentFormActiveAtom
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceOverviewForPayment, setInvoiceOverviewForPayment] =
    useState<InvoiceOverviewInfo>();
  const [invoiceFilters, setInvoiceFilters] = useState<InvoiceFilters>({
    fromDate: getDateRange("thisMonth").startDate,
    toDate: getDateRange("thisMonth").endDate,
    status:
      user?.roleCode === Role.Customer
        ? String(CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value)
        : InvoiceStatus.All,
    sortBy: "invoiceDate",
    sortOrder: "desc",
  });
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const resetPage = () => {
    setInvoicePaginationModel({ ...invoicePaginationModel, page: 0 });
  };
  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setInvoiceFilters({
          ...invoiceFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else if (invoiceFilters.sortBy !== "") {
      setInvoiceFilters({
        ...invoiceFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  const buildInvoiceRequestParams = (): InvoiceRequestParams => {
    return {
      orgId: selectedOrgId || 0,
      accountId:
        user?.roleCode === Role.SuperAdmin || user?.roleCode === Role.Admin
          ? invoiceFilters?.accountId
            ? invoiceFilters?.accountId || 0
            : selectedAccountId || 0
          : selectedAccountId || 0,
      filters: {
        page: invoiceFilters?.page ? invoiceFilters?.page + 1 : 1,
        pageSize: invoiceFilters?.pageSize,
        fromDate: invoiceFilters?.fromDate,
        toDate: invoiceFilters?.toDate,
        status:
          invoiceFilters?.status !== InvoiceStatus.All
            ? invoiceFilters?.status
              ? invoiceFilters?.status
              : ""
            : "",
        sortBy: invoiceFilters?.sortBy,
        sortOrder: invoiceFilters?.sortOrder,
      },
    };
  };
  const downloadInvoicesQuery = useFetchDownloadInvoicesCsvQuery(
    buildInvoiceRequestParams(),
    {
      enabled: false,
    }
  );
  const fetchInvoicesQuery = useFetchInvoicesQuery(
    buildInvoiceRequestParams(),
    selectedOrgId ? true : false
  );
  const attachPdfToQuickbooksMutation = useAttachPdfToQuickbooksMutation();
  const regenerateInvoiceMutation = useRegenerateInvoiceMutation();
  const fetchInvoiceOverviewQuery = useFetchInvoiceOverviewQuery(
    selectedInvoices[0]?.orgId || 0,
    selectedInvoices[0]?.accountId || 0,
    selectedInvoices[0]?.invoiceNumber || "",
    {
      enabled:
        selectedInvoices[0]?.orgId &&
        selectedInvoices[0]?.accountId &&
        selectedInvoices[0]?.invoiceNumber &&
        selectedInvoices.length === 1 &&
        (selectedInvoices[0].invoiceStatus === InvoiceStatus.InProgress ||
          selectedInvoices[0].invoiceStatus === InvoiceStatus.SendToCustomer)
          ? true
          : false,
    }
  );

  const columns: GridColDef[] = [
    {
      field: "invoiceDate",
      headerName: "Date",
      width: 230,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "invoiceFrequency",
      headerName: "Frequency",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 200,
      editable: false,
    },
    {
      field: "invoiceAmount",
      headerName: "Amount",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      field: "invoiceStatus",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params) => {
        if (user?.roleCode === Role.Customer) {
          if (
            [
              InvoiceStatus.OnHold,
              InvoiceStatus.InProgress,
              InvoiceStatus.SendToCustomer,
            ].includes(params.row.invoiceStatus)
          ) {
            return "Unpaid";
          } else if (
            params.row.invoiceStatus === InvoiceStatus.PaymentReceived
          ) {
            return "Paid";
          } else {
            return "Pending";
          }
        }

        return params.row.invoiceStatus;
      },
    },
    {
      field: "subStatus",
      headerName: "Sub Status",
      width: 150,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 300,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <InvoiceActionIcons
          invoice={params.row}
          handleAttachPdf={handleAttachPdf}
          handleRegenerateInvoice={handleRegenerateInvoice}
        />
      ),
    },
  ];

  const handleRowSelectionChange = (rows: InvoiceItem[]) => {
    setSelectedInvoices(rows);
  };

  const handleRefresh = () => {
    fetchInvoicesQuery.refetch();
  };

  const handleDownload = () => {
    downloadInvoicesQuery.refetch();
  };

  const onCreateInvoice = () => {
    setIsCreateInvoiceModalOpen(true);
  };

  const handleCloseCreateInvoiceModal = () => {
    setIsCreateInvoiceModalOpen(false);
  };

  const handleAttachPdf = (invoice: InvoiceItem) => {
    attachPdfToQuickbooksMutation.mutate({
      orgId: invoice.orgId,
      accountId: invoice.accountId,
      invoiceNumber: invoice.invoiceNumber,
    });
  };

  const handleRegenerateInvoice = (invoice: InvoiceItem) => {
    setOrgId(invoice.orgId);
    setAccountId(invoice.accountId);
    regenerateInvoiceMutation.mutate({
      orgId: invoice.orgId,
      accountId: invoice.accountId,
      invoiceNumber: invoice.invoiceNumber,
    });
  };

  const handleClickInvoiceRow = ({ row }: { row: InvoiceItem }) => {
    if (user) {
      const tab = queryParams.get(ACCOUNT_DETAILS_TAB_KEY);
      const route = getInvoiceDetailsRoute(user);
      const path =
        (tab ? `${tab}/` : "") +
        route.path.replace(":invoiceNumber", row.invoiceNumber).concat(
          buildQueryString({
            orgId: row.orgId,
            accountId: row.accountId,
          })
        );

      navigate(path);
    }
  };

  const handleClosePaymentDialog = (message: InvoicePaymentCallbackMessage) => {
    setIsReceivePaymentFormActive(false);
    if (message === InvoicePaymentCallbackMessage.PaymentSuccess) {
      fetchInvoicesQuery.refetch();
      fetchInvoiceOverviewQuery.refetch();
    }
  };

  useEffect(() => {
    if (attachPdfToQuickbooksMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "PDF attached",
        severity: "success",
      });
      handleRefresh();
    }

    if (attachPdfToQuickbooksMutation.isError) {
      setToastOptions({
        open: true,
        message: "Failed to attach PDF",
        severity: "error",
      });
    }
  }, [
    attachPdfToQuickbooksMutation.isLoading,
    attachPdfToQuickbooksMutation.isSuccess,
    attachPdfToQuickbooksMutation.isError,
  ]);

  useEffect(() => {
    if (regenerateInvoiceMutation.isSuccess) {
      setRequestId(regenerateInvoiceMutation.data.data);
      setIsStatusModalOpen(true);
    }

    if (regenerateInvoiceMutation.isError) {
      setToastOptions({
        open: true,
        message: "Invoice regenerating failed!",
        severity: "error",
      });
    }
  }, [
    regenerateInvoiceMutation.isLoading,
    regenerateInvoiceMutation.isSuccess,
    regenerateInvoiceMutation.isError,
  ]);

  useEffect(() => {
    if (fetchInvoiceOverviewQuery.isSuccess) {
      setInvoiceOverviewForPayment(fetchInvoiceOverviewQuery.data);
    }
    setLoading(
      fetchInvoiceOverviewQuery.isLoading ||
        fetchInvoiceOverviewQuery.isFetching ||
        fetchInvoiceOverviewQuery.isRefetching
    );
  }, [
    fetchInvoiceOverviewQuery.isSuccess,
    fetchInvoiceOverviewQuery.isError,
    fetchInvoiceOverviewQuery.data,
    fetchInvoiceOverviewQuery.error,
    fetchInvoiceOverviewQuery.isLoading,
    fetchInvoiceOverviewQuery.isFetching,
    fetchInvoiceOverviewQuery.isRefetching,
  ]);

  useEffect(() => {
    if (downloadInvoicesQuery.isSuccess) {
      const accountName = selectedAccount ? selectedAccount.accountName : "";
      const fileName =
        [
          user?.roleCode === Role.SuperAdmin ? "" : accountName || "unknown",
          invoiceFilters.fromDate || "",
          invoiceFilters.fromDate && invoiceFilters.toDate ? "to" : "",
          invoiceFilters.toDate || "",
        ]
          .filter(Boolean)
          .join("_") + "_invoice.csv";
      const blob = new Blob([downloadInvoicesQuery.data], {
        type: "text/csv",
      });
      const linkToCsv = URL.createObjectURL(blob);

      if (linkToCsv) downloadFile(linkToCsv, fileName);

      queryClient.resetQueries("downloadInvoices");
    }
  }, [
    downloadInvoicesQuery.isSuccess,
    downloadInvoicesQuery.data,
    downloadInvoicesQuery.error,
    downloadInvoicesQuery.isError,
    downloadInvoicesQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchInvoicesQuery.isSuccess) {
      setInvoiceList(fetchInvoicesQuery.data);
    }
  }, [fetchInvoicesQuery.isSuccess, fetchInvoicesQuery.data]);

  useEffect(() => {
    setInvoiceFilters({
      ...invoiceFilters,
      page: invoicePaginationModel.page,
      pageSize: invoicePaginationModel.pageSize,
    });
  }, [invoicePaginationModel]);

  useEffect(() => {
    const dateRange = queryParams.get("dateRange");
    const dateFilters: Partial<InvoiceFilters> = {
      fromDate: queryParams.get("fromDate") || undefined,
      toDate: queryParams.get("toDate") || undefined,
    };

    if (
      dateRange &&
      Object.values(DateRangeOptions).includes(dateRange as DateRangeOptions)
    ) {
      if (dateRange !== DateRangeOptions.CustomDate) {
        dateFilters.fromDate = getDateRange(dateRange).startDate;
        dateFilters.toDate = getDateRange(dateRange).endDate;
      }
      dateFilters.dateRange = dateRange;

      setSelectedDateRange(dateRange);
    }

    const queryFilters: InvoiceFilters = {
      orgId: Number(queryParams.get("orgId")) || undefined,
      accountId: Number(queryParams.get("accountId")) || undefined,
      status:
        queryParams.get("status") ||
        (user?.roleCode === Role.Customer
          ? String(CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value)
          : InvoiceStatus.All),
      ...dateFilters,
      page: Number(queryParams.get("page")) || undefined,
      pageSize: Number(queryParams.get("pageSize")) || undefined,
      sortBy: queryParams.get("sortBy") || undefined,
      sortOrder: queryParams.get("sortOrder") || undefined,
    };

    const tempInvoiceFiltersFromQueryStrings = Object.entries(
      queryFilters
    ).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const tempFilterValuesFromQueryStrings = Object.entries({
      orgId: tempInvoiceFiltersFromQueryStrings.orgId,
      accountId: tempInvoiceFiltersFromQueryStrings.accountId,
      status: tempInvoiceFiltersFromQueryStrings.status,
    }).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    Object.values(tempFilterValuesFromQueryStrings).length > 0 &&
      setFilterValues({
        ...filterValues,
        ...tempFilterValuesFromQueryStrings,
      });
    setInvoiceFilters({
      ...invoiceFilters,
      ...tempInvoiceFiltersFromQueryStrings,
    });
    setInvoicePaginationModel({
      ...invoicePaginationModel,
      page: tempInvoiceFiltersFromQueryStrings.page || 0,
      pageSize: tempInvoiceFiltersFromQueryStrings.pageSize || 10,
    });
  }, []);

  useEffect(() => {
    const filters = Object.entries(invoiceFilters).reduce(
      (acc: any, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const tab = queryParams.get("tab");

    setQueryParams({
      ...(tab && { tab }),
      ...filters,
    });
  }, [invoiceFilters]);

  useEffect(() => {
    if (!invoiceList?.items || selectedInvoices.length === 0) return;

    const updatedSelectedInvoice = selectedInvoices.map(
      (selectedInvoice) =>
        invoiceList.items.find(
          (invoice) => invoice.id === selectedInvoice.id
        ) || selectedInvoice
    );

    setSelectedInvoices(updatedSelectedInvoice);
  }, [invoiceList?.items]);

  return (
    <>
      <Box
        sx={{
          marginTop: "50px",
          width: "100%",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Toast setOptions={setToastOptions} options={toastOptions} />
        <Box>
          <Grid2
            container
            direction={"column"}
            sx={{
              marginTop: "0px",
              marginBottom: "8px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <InvoiceToolbar
              invoices={selectedInvoices}
              invoiceFilters={invoiceFilters}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              setInvoiceFilters={setInvoiceFilters}
              onRefresh={handleRefresh}
              onDownload={handleDownload}
              onCreateInvoice={onCreateInvoice}
              resetPage={resetPage}
            />
          </Grid2>
          <Grid2 container direction={"column"} spacing={2}>
            <DataGrid
              onRowClick={handleClickInvoiceRow}
              columns={columns}
              rows={invoiceList?.items || []}
              loading={
                fetchInvoicesQuery.isLoading ||
                fetchInvoicesQuery.isRefetching ||
                downloadInvoicesQuery.isLoading ||
                downloadInvoicesQuery.isRefetching ||
                attachPdfToQuickbooksMutation.isLoading ||
                regenerateInvoiceMutation.isLoading ||
                isInvoiceActionsMutationsLoading
              }
              sortModel={[
                {
                  field: invoiceFilters.sortBy || "",
                  sort: (invoiceFilters.sortOrder as GridSortDirection) || null,
                },
              ]}
              onSortModelChange={handleSortModelChange}
              paginationModel={invoicePaginationModel}
              setPaginationModel={setInvoicePaginationModel}
              onSelectedRowsChange={handleRowSelectionChange}
              totalRecords={invoiceList?.totalItems}
              paginationMode="server"
              sortingMode="server"
              rowCount={invoiceList?.totalItems ? invoiceList?.totalItems : 0}
              pageSize={invoiceList?.pageSize}
              sx={{
                border: "none",
                backgroundColor: theme.palette.secondary.light,
                padding: "20px",
                paddingTop: "0px",
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                height: "70vh",
              }}
            />
          </Grid2>
        </Box>
      </Box>
      <Modal
        open={isCreateInvoiceModalOpen}
        onClose={handleCloseCreateInvoiceModal}
        title={"Create Invoice"}
        content={<CreateInvoice handleRefresh={handleRefresh} />}
        sx={{
          modal: {
            width: { lg: "80%", md: "85%", xs: "80%", sm: "80%" },
            height: { lg: "85%", md: "85%", xs: "85%", sm: "85%" },
          },
        }}
      />
      <CreateRegenerateInvoiceModal
        open={isStatusModalOpen}
        onClose={() => setIsStatusModalOpen(false)}
        title={"Regenerate Invoice"}
        requestId={requestId}
        orgId={orgId}
        accountId={accountId}
        regenerate={true}
        handleRefresh={handleRefresh}
      />
      <PaymentDialog
        open={isReceivePaymentFormActive}
        loading={loading}
        handleClose={handleClosePaymentDialog}
        invoiceOverview={invoiceOverviewForPayment}
      />
    </>
  );
};
