import {
  CircularProgress,
  Box,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";

type LoadingProps = {
  message?: string;
  sx?: SxProps<Theme>;
};

const Loading = ({ message = "Loading...", sx }: LoadingProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        textAlign: "center",
        ...sx,
      }}
    >
      <CircularProgress />
      <Typography sx={{ mt: 2, fontSize: "18px", fontWeight: 500 }}>
        {message}
      </Typography>
    </Box>
  );
};

export default Loading;
