import { Role, UserStatus } from "../enums";
import { DropdownItem, LeadUser, User, UserInfo } from "../types";

export class UserModel {
  public static buildLeadUserDropdown(leadUsers: LeadUser[]): DropdownItem[] {
    if (!leadUsers) {
      return [];
    }

    return leadUsers.map((user) => ({
      key: user.leadName,
      value: user.leadId,
    }));
  }

  public static buildUserStatusDropdown(): DropdownItem[] {
    return Object.entries(UserStatus).map(([key, value]) => ({
      key: key,
      value: value,
    }));
  }

  public static buildUserRoleDropdown(): DropdownItem[] {
    return Object.entries(Role).map(([key, value]) => ({
      key: key.replace(/([a-z])([A-Z])/g, "$1 $2"),
      value: value,
    }));
  }

  public static buildUser(users: User[]): UserInfo[] {
    return users.map((user) => ({
      ...user,
      fullName: `${user.firstName} ${user.lastName}`,
      employeeStatus: `${user.active ? "active" : "inactive"}`,
    }));
  }
}
