import { Box, Grid2 as Grid } from "@mui/material";
import { OrganizationIntegrationsInfo } from "../../types";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { selectedOrgIdAtom, toastOptionsAtom } from "../../atoms";
import {
  EditPaymentGatewayIntegrationInfo,
  EditQuickbooksIntegrationInfo,
  ViewPaymentGatewayIntegrationInfo,
  ViewQuickbooksIntegrationInfo,
} from "./fragments";
import {
  useFetchQuickbooksAuthUriQuery,
  useFetchQuickbooksCredentialsQuery,
  useFetchWorldpayCredentialsQuery,
  useUpdateQuickbooksCredentialsMutation,
  useUpdateWorldpayCredentialsMutation,
} from "../../api";
import { Button } from "../common";
import { INTUIT_CONNECT_TO_QUICKBOOKS_CALLBACK_SOURCE } from "../../constants";
import { config } from "../../config";
import { AllowedAccess } from "@zdistancelab-packages/ui-permission-validator";
import { Action, Module, Role } from "../../enums";

export const Integrations = () => {
  const [quickbooksAuthUri, setQuickbooksAuthUri] = useState<string>();
  const [isQuickbooksIntegrationEditMode, setIsQuickbooksIntegrationEditMode] =
    useState(false);
  const [
    isPaymentGatewayIntegrationEditMode,
    setIsPaymentGatewayIntegrationEditMode,
  ] = useState(false);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [integrationsInfo, setIntegrationsInfo] =
    useState<OrganizationIntegrationsInfo>();
  const fetchQuickbooksCredentialsQuery =
    useFetchQuickbooksCredentialsQuery(selectedOrgId);
  const updateQuickbooksCredentialsMutation =
    useUpdateQuickbooksCredentialsMutation();
  const updateWorldpayCredentialsMutation =
    useUpdateWorldpayCredentialsMutation();
  const fetchQuickbooksAuthUriQuery = useFetchQuickbooksAuthUriQuery(
    selectedOrgId,
    { enabled: selectedOrgId !== 0 }
  );
  const fetchWorldpayCredentialsQuery = useFetchWorldpayCredentialsQuery(
    selectedOrgId,
    { enabled: selectedOrgId !== 0 }
  );

  useEffect(() => {
    if (fetchQuickbooksAuthUriQuery.isSuccess) {
      setQuickbooksAuthUri(fetchQuickbooksAuthUriQuery.data);
    }
  }, [
    fetchQuickbooksAuthUriQuery.isSuccess,
    fetchQuickbooksAuthUriQuery.isError,
    fetchQuickbooksAuthUriQuery.data,
    fetchQuickbooksAuthUriQuery.error,
    fetchQuickbooksAuthUriQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchWorldpayCredentialsQuery.isSuccess) {
      setIntegrationsInfo({
        ...integrationsInfo,
        worldpay: fetchWorldpayCredentialsQuery.data,
      });
    }
  }, [
    fetchWorldpayCredentialsQuery.isSuccess,
    fetchWorldpayCredentialsQuery.isError,
    fetchWorldpayCredentialsQuery.data,
    fetchWorldpayCredentialsQuery.error,
    fetchWorldpayCredentialsQuery.isLoading,
    fetchWorldpayCredentialsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchQuickbooksCredentialsQuery.isSuccess) {
      const quickbooksIntegrationsInfo = fetchQuickbooksCredentialsQuery.data;
      setIntegrationsInfo({
        ...integrationsInfo,
        quickbooks: {
          clientId: quickbooksIntegrationsInfo.clientId,
          secretId: quickbooksIntegrationsInfo.secretId,
          realmId: quickbooksIntegrationsInfo.realmId,
          isQbSessionValid: quickbooksIntegrationsInfo.isQbSessionValid,
        },
      });
    }
  }, [
    fetchQuickbooksCredentialsQuery.isSuccess,
    fetchQuickbooksCredentialsQuery.isError,
    fetchQuickbooksCredentialsQuery.data,
    fetchQuickbooksCredentialsQuery.error,
    fetchQuickbooksCredentialsQuery.isLoading,
    fetchQuickbooksCredentialsQuery.isRefetching,
  ]);

  useEffect(() => {
    if (updateQuickbooksCredentialsMutation.isSuccess) {
      fetchQuickbooksCredentialsQuery.refetch();
      fetchQuickbooksAuthUriQuery.refetch();

      if (isPaymentGatewayIntegrationEditMode)
        setIsPaymentGatewayIntegrationEditMode(false);
      if (isQuickbooksIntegrationEditMode)
        setIsQuickbooksIntegrationEditMode(false);

      setToastOptions({
        open: true,
        message: "Credentials updated",
        severity: "success",
      });
    }

    if (updateQuickbooksCredentialsMutation.isError) {
      if (isPaymentGatewayIntegrationEditMode)
        setIsPaymentGatewayIntegrationEditMode(false);
      if (isQuickbooksIntegrationEditMode)
        setIsQuickbooksIntegrationEditMode(false);

      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateQuickbooksCredentialsMutation.isSuccess,
    updateQuickbooksCredentialsMutation.isError,
    updateQuickbooksCredentialsMutation.isLoading,
    updateQuickbooksCredentialsMutation.data,
    updateQuickbooksCredentialsMutation.error,
  ]);

  useEffect(() => {
    if (updateWorldpayCredentialsMutation.isSuccess) {
      fetchWorldpayCredentialsQuery.refetch();
      fetchQuickbooksAuthUriQuery.refetch();

      if (isPaymentGatewayIntegrationEditMode)
        setIsPaymentGatewayIntegrationEditMode(false);
      if (isQuickbooksIntegrationEditMode)
        setIsQuickbooksIntegrationEditMode(false);

      setToastOptions({
        open: true,
        message: "Credentials updated",
        severity: "success",
      });
    }

    if (updateWorldpayCredentialsMutation.isError) {
      if (isPaymentGatewayIntegrationEditMode)
        setIsPaymentGatewayIntegrationEditMode(false);
      if (isQuickbooksIntegrationEditMode)
        setIsQuickbooksIntegrationEditMode(false);

      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    updateWorldpayCredentialsMutation.isSuccess,
    updateWorldpayCredentialsMutation.isError,
    updateWorldpayCredentialsMutation.isLoading,
    updateWorldpayCredentialsMutation.data,
    updateWorldpayCredentialsMutation.error,
  ]);

  const handleDiscardEditPaymentGatewayIntegrationInfo = () => {
    setIsPaymentGatewayIntegrationEditMode(false);
  };

  const handleDiscardEditQuickbooksIntegrationInfo = () => {
    setIsQuickbooksIntegrationEditMode(false);
  };

  const handleEditPaymentGatewayIntegrationInfo = (data: any) => {
    if (isPaymentGatewayIntegrationEditMode)
      setIsPaymentGatewayIntegrationEditMode(false);
    updateWorldpayCredentialsMutation.mutate({
      orgId: selectedOrgId,
      ...data,
    });
  };

  const handleEditQuickbooksIntegrationInfo = (data: any) => {
    if (isQuickbooksIntegrationEditMode)
      setIsQuickbooksIntegrationEditMode(false);
    updateQuickbooksCredentialsMutation.mutate({
      orgId: selectedOrgId,
      ...data,
    });
  };

  const handleConnectToQuickbooks = (url: string) => {
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = window.screenY + (window.innerHeight - height) / 2;

    const authPopup = window.open(
      url,
      "IntuitAuth",
      `width=${width},height=${height},top=${top},left=${left}`
    );

    const messageListener = (event: MessageEvent<any>) => {
      if (event.origin !== config.api.baseUrl) {
        return;
      }

      const { success, source } = event.data;

      if (source !== INTUIT_CONNECT_TO_QUICKBOOKS_CALLBACK_SOURCE) {
        return;
      }

      if (success) {
        setToastOptions({
          open: true,
          message: "Authenticated to quickbooks",
          severity: "success",
        });
      } else {
        setToastOptions({
          open: true,
          message: "Quickbooks authentication failed",
          severity: "success",
        });
      }

      if (authPopup) {
        authPopup.close();

        fetchQuickbooksCredentialsQuery.refetch();
      }

      window.removeEventListener("message", messageListener);
    };

    window.addEventListener("message", messageListener);
  };

  return (
    <Grid
      container
      direction={"row"}
      spacing={3}
      gap={3}
      sx={{
        direction: "row",
        borderRadius: "10px",
        px: "4px",
        justifyContent: "space-between",
      }}
    >
      <AllowedAccess
        roles={[Role.Admin]}
        permissions={[{ module: Module.Organization, action: Action.All }]}
      >
        <Grid size={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box>
            <Button
              color="success"
              label={
                !(
                  fetchQuickbooksAuthUriQuery.isLoading ||
                  fetchQuickbooksAuthUriQuery.isRefetching ||
                  fetchQuickbooksAuthUriQuery.isFetching
                ) && integrationsInfo?.quickbooks?.isQbSessionValid
                  ? "Authenticated with Quickbooks"
                  : "Connect to Quickbooks"
              }
              disabled={
                integrationsInfo?.quickbooks?.isQbSessionValid ||
                fetchQuickbooksAuthUriQuery.isLoading ||
                fetchQuickbooksAuthUriQuery.isRefetching ||
                fetchQuickbooksAuthUriQuery.isFetching ||
                !quickbooksAuthUri
              }
              onClick={() => {
                quickbooksAuthUri &&
                  handleConnectToQuickbooks(quickbooksAuthUri);
              }}
              sx={{
                py: "0.2rem",
                width: "100%",
                minWidth: "160px",
                borderRadius: 0,
                minHeight: "33px",
              }}
            />
          </Box>
        </Grid>
      </AllowedAccess>
      {!isPaymentGatewayIntegrationEditMode ? (
        <ViewPaymentGatewayIntegrationInfo
          data={integrationsInfo}
          isLoading={
            fetchQuickbooksCredentialsQuery.isLoading ||
            fetchQuickbooksCredentialsQuery.isFetching ||
            updateQuickbooksCredentialsMutation.isLoading ||
            fetchWorldpayCredentialsQuery.isLoading ||
            updateWorldpayCredentialsMutation.isLoading
          }
          handleEditMode={setIsPaymentGatewayIntegrationEditMode}
        />
      ) : (
        integrationsInfo && (
          <EditPaymentGatewayIntegrationInfo
            data={integrationsInfo}
            handleEdit={handleEditPaymentGatewayIntegrationInfo}
            handleDiscard={handleDiscardEditPaymentGatewayIntegrationInfo}
          />
        )
      )}
      {!isQuickbooksIntegrationEditMode ? (
        <ViewQuickbooksIntegrationInfo
          data={integrationsInfo}
          isLoading={
            fetchQuickbooksCredentialsQuery.isLoading ||
            fetchQuickbooksCredentialsQuery.isFetching ||
            updateQuickbooksCredentialsMutation.isLoading ||
            updateWorldpayCredentialsMutation.isLoading
          }
          handleEditMode={setIsQuickbooksIntegrationEditMode}
        />
      ) : (
        integrationsInfo && (
          <EditQuickbooksIntegrationInfo
            data={integrationsInfo}
            handleEdit={handleEditQuickbooksIntegrationInfo}
            handleDiscard={handleDiscardEditQuickbooksIntegrationInfo}
          />
        )
      )}
    </Grid>
  );
};
