import { useMutation, useQuery } from "react-query";
import { client } from "./axios";
import { config } from "../config";
import {
  ApiBasePathParams,
  ApiError,
  OmnitokenInfo,
  QueryOptions,
  WorldpayCredentials,
} from "../types";
import { AxiosError, AxiosResponse } from "axios";
import { DEFAULT_QUERY_OPTIONS } from "../constants";

export const useFetchHostedPaymentsPageMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      amount: number;
      addressLine1?: string;
      addressLine2?: string;
      city?: string;
      state?: string;
      zip?: string;
      saveCard?: boolean;
      authTransaction?: boolean;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        amount: number;
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        state?: string;
        zip?: string;
        saveCard?: boolean;
        authTransaction?: boolean;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path =
        config.api.worldpayIntegrationService.initiateWorldpayTransaction
          .replace("{orgId}", orgId.toString())
          .replace("{accountId}", accountId.toString())
          .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.hostedPaymentsPageUrl;
    }
  );

  return mutation;
};

export const useFetchCreditCardSaleMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      omnitokenId?: string | number;
      tokenId?: string;
      netTransactionId?: string;
      expirationMonth?: string;
      expirationYear?: string;
      cardLogo?: string;
      amount: number;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        omnitokenId?: string | number;
        tokenId?: string;
        netTransactionId?: string;
        expirationMonth?: string;
        expirationYear?: string;
        cardLogo?: string;
        amount: number;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path = config.api.worldpayIntegrationService.creditCardSale
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.callback;
    }
  );

  return mutation;
};

export const useCashPaymentMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      amount: number;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        amount: number;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path = config.api.worldpayIntegrationService.cashPayment
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.callback;
    }
  );

  return mutation;
};

export const useCheckPaymentMutation = () => {
  const mutation = useMutation<
    string,
    AxiosError<ApiError>,
    ApiBasePathParams & {
      invoiceNumber: string;
      amount: number;
      bankName: string;
      checkNumber: string;
    },
    unknown
  >(
    async (
      data: ApiBasePathParams & {
        invoiceNumber: string;
        amount: number;
        bankName: string;
        checkNumber: string;
      }
    ) => {
      const { orgId, accountId, invoiceNumber, ...payload } = data;

      const path = config.api.worldpayIntegrationService.checkPayment
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { ...payload });

      return response.data.callback;
    }
  );

  return mutation;
};

export const useFetchOmnitokensQuery = (
  orgId: number,
  accountId: number,
  queryOptions?: QueryOptions<string>
) => {
  const fetchOmnitokens = async (): Promise<OmnitokenInfo> => {
    const path = config.api.worldpayIntegrationService.getOmnitokens
      .replace("{orgId}", orgId.toString())
      .replace("{accountId}", accountId.toString());

    const response = await client.get<any>(path);

    return response.data;
  };

  return useQuery(["fetchOmnitokens", orgId, accountId], fetchOmnitokens, {
    ...queryOptions,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useFetchWorldpayCredentialsQuery = (
  orgId: number,
  queryOptions?: QueryOptions<string>
) => {
  const fetchWorldpayCredentials = async (): Promise<WorldpayCredentials> => {
    const path =
      config.api.worldpayIntegrationService.getWorldpayCredentials.replace(
        "{orgId}",
        orgId.toString()
      );

    const response = await client.get<any>(path);

    return response.data.credentials;
  };

  return useQuery(
    ["fetchWorldpayCredentials", orgId],
    fetchWorldpayCredentials,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export const useUpdateWorldpayCredentialsMutation = () => {
  const mutation = useMutation<any, AxiosError<ApiError>, any, any>(
    async (data: Pick<ApiBasePathParams, "orgId"> & WorldpayCredentials) => {
      const { orgId, ...worldpayCredentials } = data;
      const path =
        config.api.worldpayIntegrationService.createOrUpdateWorldpayCredentials.replace(
          "{orgId}",
          orgId.toString()
        );

      const response = await client.post(path, { ...worldpayCredentials });

      return response;
    }
  );

  return mutation;
};

export const useCreditCardReturnMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    {
      orgId: number;
      accountId: number;
      invoiceNumber: string;
      paymentRecordId: number;
    }
  >(
    async (data: {
      orgId: number;
      accountId: number;
      invoiceNumber: string;
      paymentRecordId: number;
    }) => {
      const { orgId, accountId, invoiceNumber, paymentRecordId } = data;
      const path = config.api.worldpayIntegrationService.creditCardReturn
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { paymentRecordId });

      return response;
    }
  );

  return mutation;
};

export const useCreditCardReversalMutation = () => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<ApiError>,
    {
      orgId: number;
      accountId: number;
      invoiceNumber: string;
      paymentRecordId: number;
    }
  >(
    async (data: {
      orgId: number;
      accountId: number;
      invoiceNumber: string;
      paymentRecordId: number;
    }) => {
      const { orgId, accountId, invoiceNumber, paymentRecordId } = data;
      const path = config.api.worldpayIntegrationService.creditCardReversal
        .replace("{orgId}", orgId.toString())
        .replace("{accountId}", accountId.toString())
        .replace("{invoiceNumber}", invoiceNumber.toString());

      const response = await client.post(path, { paymentRecordId });

      return response;
    }
  );

  return mutation;
};
