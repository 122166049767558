import { useEffect, useState } from "react";
import {
  Box,
  Grid2 as Grid,
  styled,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  Typography,
  Divider,
  InputAdornment,
  Card,
  CardContent,
  CardMedia,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { TextField, SelectBox } from "../../common";
import { FaMoneyCheckAlt, FaCreditCard, FaMoneyBillWave } from "react-icons/fa";
import { RiBankFill } from "react-icons/ri";
import { PaymentMethod, Role } from "../../../enums";
import { CARD_LOGOS, STATES } from "../../../constants";
import {
  DropdownItem,
  InvoiceOverviewInfo,
  Omnitoken,
  PaymentDetails,
} from "../../../types";
import { IconType } from "react-icons";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useFetchOmnitokensQuery } from "../../../api";
import { useAtom } from "jotai";
import { userAtom } from "../../../atoms";

const ToggleButton = styled(MuiToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#3e8bd2",
  },
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
});

export const paymentMethods: (DropdownItem & { icon: IconType })[] = [
  { key: "Card", value: PaymentMethod.Card, icon: FaCreditCard },
  { key: "Check", value: PaymentMethod.Check, icon: FaMoneyCheckAlt },
  { key: "ACH", value: PaymentMethod.Ach, icon: RiBankFill },
  { key: "Cash", value: PaymentMethod.Cash, icon: FaMoneyBillWave },
];

type Props = {
  invoice: InvoiceOverviewInfo | undefined;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<PaymentDetails>;
  orgId: number;
  accountId: number;
  paymentMethod: PaymentMethod;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
};

export const DetailsForm = ({
  invoice,
  setValue,
  register,
  errors,
  orgId,
  accountId,
  paymentMethod,
  setPaymentMethod,
}: Props) => {
  const [user] = useAtom(userAtom);
  const [selectedRadioBtnValue, setSelectedRadioBtnValue] =
    useState<string>("newPaymentMethod");
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<Omnitoken[]>(
    []
  );

  const fetchOmnitokensQuery = useFetchOmnitokensQuery(orgId, accountId, {
    enabled: orgId && accountId ? true : false,
  });

  const handleChoosePaymentMethod = (
    event: React.MouseEvent<HTMLElement>,
    paymentMethod: PaymentMethod | null
  ) => {
    if (paymentMethod) setPaymentMethod(paymentMethod);
  };

  useEffect(() => {
    setValue("paymentMethod", paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    setValue("amount", invoice?.balanceAmount);
  }, [invoice]);

  useEffect(() => {
    setValue(
      "omnitokenId",
      selectedRadioBtnValue === "newPaymentMethod" ? "" : selectedRadioBtnValue
    );
    setValue(
      "tokenId",
      selectedRadioBtnValue !== "newPaymentMethod"
        ? savedPaymentMethods.filter(
            (paymentMethod) => paymentMethod.id === selectedRadioBtnValue
          )[0].tokenId
        : ""
    );
    setValue(
      "expirationMonth",
      selectedRadioBtnValue !== "newPaymentMethod"
        ? savedPaymentMethods.filter(
            (paymentMethod) => paymentMethod.id === selectedRadioBtnValue
          )[0].expirationMonth
        : ""
    );
    setValue(
      "expirationYear",
      selectedRadioBtnValue !== "newPaymentMethod"
        ? savedPaymentMethods.filter(
            (paymentMethod) => paymentMethod.id === selectedRadioBtnValue
          )[0].expirationYear
        : ""
    );
    setValue(
      "netTransactionId",
      selectedRadioBtnValue !== "newPaymentMethod"
        ? savedPaymentMethods.filter(
            (paymentMethod) => paymentMethod.id === selectedRadioBtnValue
          )[0].netTransactionId
        : ""
    );
    setValue(
      "cardLogo",
      selectedRadioBtnValue !== "newPaymentMethod"
        ? savedPaymentMethods.filter(
            (paymentMethod) => paymentMethod.id === selectedRadioBtnValue
          )[0].cardLogo
        : ""
    );
  }, [selectedRadioBtnValue]);

  useEffect(() => {
    if (fetchOmnitokensQuery.isSuccess) {
      setSavedPaymentMethods(fetchOmnitokensQuery.data.omnitokens);
    }
  }, [
    fetchOmnitokensQuery.isSuccess,
    fetchOmnitokensQuery.isError,
    fetchOmnitokensQuery.data,
    fetchOmnitokensQuery.error,
    fetchOmnitokensQuery.isLoading,
    fetchOmnitokensQuery.isFetching,
    fetchOmnitokensQuery.isRefetching,
  ]);

  return (
    <Grid
      container
      size={12}
      spacing={{ xs: 0, sm: 4 }}
      direction={"row"}
      sx={{
        p: { xs: 1, sm: 4 },
        pb: 0,
      }}
    >
      <Grid container size={12}>
        <TextField
          label="Amount"
          name="amount"
          type="number"
          register={register}
          valueAsNumber={true}
          error={errors.amount}
          required
          disablePointerEvents
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          }}
        />
      </Grid>
      <Grid
        container
        size={{ xs: 12, md: 12, lg: 12 }}
        sx={{
          textAlign: "center",
          flexDirection: "column",
        }}
        gap={2}
      >
        <Grid
          container
          size={{ xs: 12, md: 12, lg: 12 }}
          sx={{
            textAlign: "center",
            py: 4,
            flexDirection: "column",
          }}
          gap={2}
        >
          <Grid
            container
            size={12}
            flexGrow={1}
            direction="column"
            justifyContent={"flex-end"}
            gap={1}
          >
            <Divider />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Wash Charges</Typography>
              <Typography>$ {invoice?.totalWashCharge || 0}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Miscellaneous Charges</Typography>
              <Typography>
                ${" "}
                {(invoice?.totalMiscCharge ?? 0) +
                  (invoice?.tripMinimumCharge ?? 0)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Invoice Amount</Typography>
              <Typography>$ {invoice?.invoiceAmount || 0}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Amount Paid</Typography>
              <Typography>$ {invoice?.paidAmount || 0}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Amount Due</Typography>
              <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
                $ {invoice?.balanceAmount || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography sx={{ textAlign: "left" }}>Payment methods</Typography>
        {fetchOmnitokensQuery.isLoading && (
          <Grid
            container
            size={12}
            sx={{
              direction: "row",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 0px 4px 0px #00000040",
              p: 3,
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        )}
        {savedPaymentMethods.map((savedPaymentMethod, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              px: 1,
              boxShadow: "0px 0px 4px 0px #00000040",
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setSelectedRadioBtnValue(savedPaymentMethod.id);
            }}
          >
            <Radio
              checked={selectedRadioBtnValue === savedPaymentMethod.id}
              size="small"
              value={savedPaymentMethod.id}
              onChange={() => {
                setSelectedRadioBtnValue(savedPaymentMethod.id);
              }}
              inputProps={{ "aria-label": "A" }}
            />
            <CardMedia
              component="img"
              sx={{ width: 40, height: 40, objectFit: "contain" }}
              image={
                CARD_LOGOS[savedPaymentMethod.cardLogo] || CARD_LOGOS["Other"]
              }
              alt="Card Merchant Logo"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiCardContent-root": { p: "0 !important" },
              }}
            >
              <CardContent sx={{ p: 0 }}>
                <Typography sx={{ color: "text.secondary", p: "1rem" }}>
                  xxxx xxxx xxxx {savedPaymentMethod.cardLast4}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        ))}
        <Accordion
          expanded={selectedRadioBtnValue === "newPaymentMethod"}
          onChange={() => {
            setSelectedRadioBtnValue("newPaymentMethod");
          }}
          disableGutters
          sx={{
            boxShadow: "0px 0px 4px 0px #00000040",
            "& .MuiPaper-root": {
              margin: "0 !important",
            },
            "&:before": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{
              "& .MuiAccordionSummary-content": { margin: 0 },
              px: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Radio
                checked={selectedRadioBtnValue === "newPaymentMethod"}
                size="small"
                value="newPaymentMethod"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
                onChange={() => {
                  setSelectedRadioBtnValue("newPaymentMethod");
                }}
              />
              <Typography sx={{ textAlign: "left", py: "1rem" }}>
                Choose a different payment method
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiCardContent-root": { px: "2rem !important", py: "1rem" },
              }}
            >
              <Grid
                container
                size={12}
                spacing={{ xs: 0, sm: 4 }}
                direction={"row"}
                sx={{
                  p: { xs: 1, sm: 4 },
                }}
              >
                <Grid size={12}>
                  <Grid
                    container
                    size={{ xs: 12, md: 8, lg: 12 }}
                    sx={{ textAlign: "center" }}
                  >
                    <ToggleButtonGroup
                      sx={{
                        width: "100%",
                        display: { sm: "flex", xs: "none" },
                      }}
                      value={paymentMethod}
                      {...register}
                      exclusive
                      onChange={handleChoosePaymentMethod}
                    >
                      {paymentMethods
                        .filter((paymentMethod) => {
                          if (!user) {
                            return paymentMethod.value === PaymentMethod.Card;
                          }
                          if (
                            user?.roleCode === Role.SuperAdmin ||
                            user?.roleCode === Role.Admin
                          ) {
                            return true;
                          }
                          return (
                            paymentMethod.value !== PaymentMethod.Cash &&
                            paymentMethod.value !== PaymentMethod.Check
                          );
                        })
                        .map((item, index) => (
                          <ToggleButton key={index} value={item.value || ""}>
                            <item.icon fontSize={"1rem"} />
                            {item.key}
                          </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <Box
                      sx={{
                        display: {
                          sm: "none",
                        },
                        width: "100%",
                      }}
                    >
                      <SelectBox
                        label="Payment method"
                        name="paymentMethod"
                        value={paymentMethod}
                        onChange={(e: any) => {
                          setPaymentMethod(e.target.value);
                        }}
                        dropdown={paymentMethods}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {paymentMethod === PaymentMethod.Card && (
                  <>
                    <Grid
                      container
                      size={{ xs: 12, md: 12, lg: 12 }}
                      sx={{
                        textAlign: "center",
                        py: 4,
                        flexDirection: "column",
                      }}
                      gap={2}
                    >
                      <Grid container size={12}>
                        <TextField
                          label="Address Line 1"
                          name="addressLine1"
                          required
                          register={register}
                          error={errors.addressLine1}
                          defaultValue={invoice?.address.addressLine1}
                        />
                      </Grid>
                      <Grid container size={12}>
                        <TextField
                          label="Address Line 2 (Optional)"
                          name="addressLine2"
                          register={register}
                          error={errors.addressLine2}
                          defaultValue={invoice?.address.addressLine2}
                        />
                      </Grid>
                      <Grid container size={12} direction={"row"} spacing={2}>
                        <Grid size={{ sm: 4, xs: 12 }}>
                          <TextField
                            label="City"
                            name="city"
                            required
                            register={register}
                            error={errors.city}
                            helperTextSx={{
                              whiteSpace: "nowrap !important",
                            }}
                            defaultValue={invoice?.address.city}
                          />
                        </Grid>
                        <Grid size={{ sm: 4, xs: 12 }}>
                          <SelectBox
                            key={invoice?.address.state || "default"}
                            label="State"
                            name="state"
                            dropdown={STATES}
                            required
                            register={register}
                            error={errors.state}
                            helperTextSx={{
                              whiteSpace: "nowrap !important",
                            }}
                            defaultValue={invoice?.address.state || ""}
                          />
                        </Grid>
                        <Grid size={{ sm: 4, xs: 12 }}>
                          <TextField
                            type="number"
                            label="Zip"
                            name="zip"
                            required
                            register={register}
                            error={errors.zip}
                            helperTextSx={{
                              whiteSpace: "nowrap !important",
                            }}
                            defaultValue={invoice?.address.zip}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <FormControlLabel
                      control={<Checkbox {...register("saveCard")} />}
                      label="Save card for future payments"
                      sx={{ textAlign: "left" }}
                    />
                  </>
                )}

                {paymentMethod === PaymentMethod.Check && (
                  <Grid
                    container
                    size={{ xs: 12, md: 12, lg: 12 }}
                    sx={{
                      textAlign: "center",
                      py: 4,
                      flexDirection: "column",
                    }}
                    gap={2}
                  >
                    <Grid container size={12}>
                      <TextField
                        label="Bank Name"
                        name="bankName"
                        required
                        register={register}
                        error={errors.bankName}
                      />
                    </Grid>
                    <Grid container size={12}>
                      <TextField
                        label="Check Number"
                        name="checkNumber"
                        required
                        register={register}
                        error={errors.checkNumber}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
