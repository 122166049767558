import { useState } from "react";
import { Tabs } from "../../common";
import { Grid2 as Grid, Box } from "@mui/material";
import { TabItem } from "../../../types";
import { Invoice } from "./invoice";
import { Payments } from "./payments";

const tabItems: TabItem[] = [
  { key: "Invoices", value: 0 },
  { key: "Payments", value: 1 },
];

export const InvoicesAndPayments = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Box sx={{ boxShadow: "0px 0px 4px 0px #00000040", width: "100%" }}>
      <Grid
        container
        direction={"column"}
        spacing={2}
        sx={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Tabs
          defaultTab="No location"
          tabItems={tabItems}
          onTabChange={(tab) => setSelectedTab(tab)}
          value={selectedTab}
        />
      </Grid>
      <Grid container direction={"column"} spacing={2}>
        {selectedTab === 0 && <Invoice />}
        {selectedTab === 1 && <Payments />}
      </Grid>
    </Box>
  );
};
