import { Box, Grid2 as Grid } from "@mui/material";
import { CropperRef, Cropper } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

type Props = {
  image: string;
  onCropComplete: (croppedAreaPixels: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => void;
};

export const CropContainerV2 = ({ image, onCropComplete }: Props) => {
  const onChange = (cropper: CropperRef) => {
    const croppedAreaPixels = cropper.getCoordinates();
    onCropComplete({
      width: croppedAreaPixels?.width || 0,
      height: croppedAreaPixels?.height || 0,
      x: croppedAreaPixels?.left || 0,
      y: croppedAreaPixels?.top || 0,
    });
  };

  return (
    <Grid
      container
      size={12}
      direction={"column"}
      sx={{
        height: {
          xs: "300px",
          md: "400px",
        },
      }}
    >
      <Grid
        size={12}
        flex={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Cropper
            src={image}
            onChange={onChange}
            className={"cropper"}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
