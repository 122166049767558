import React from "react";
import { Card, CardContent, Typography, Box, Skeleton } from "@mui/material";

type TileProps = {
  heading: string;
  total: string | undefined;
  isLoading: boolean;
  footer?: React.ReactNode;
};

export const Tile: React.FC<TileProps> = ({
  heading,
  total,
  isLoading,
  footer,
}) => {
  return (
    <Card
      sx={{
        minHeight: "155px",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "40px" }}
        >
          {heading}
        </Typography>

        <Box>
          {isLoading ? (
            <Skeleton variant="text" width={60} height={40} />
          ) : (
            <Typography variant="h4" sx={{ fontWeight: "700" }}>
              {total}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          {isLoading ? <Skeleton variant="text" width={100} /> : footer}
        </Box>
      </CardContent>
    </Card>
  );
};
