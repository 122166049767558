import { Box } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { toastOptionsAtom } from "../../../atoms";
import { InvoicePaymentInitiatePayload } from "../../../types";
import Loading from "../../common/loading/Loading";
import { useFetchCreditCardSaleMutation } from "../../../api";

type Props = {
  payload: InvoicePaymentInitiatePayload | undefined;
  orgId: number;
};

export const CreditCardSale = ({ payload, orgId }: Props) => {
  const hasMutated = useRef(false);
  const [paymentCallbackUrl, setPaymentCallbackUrl] = useState<string>();
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const fetchCreditCardSaleMutation = useFetchCreditCardSaleMutation();
  useEffect(() => {
    if (payload && !hasMutated.current) {
      hasMutated.current = true;
      setTimeout(() => {
        fetchCreditCardSaleMutation.mutate({
          orgId: orgId,
          accountId: payload.accountId,
          invoiceNumber: payload.invoiceNumber,
          omnitokenId: payload.omnitokenId,
          tokenId: payload.tokenId,
          expirationMonth: payload.expirationMonth,
          expirationYear: payload.expirationYear,
          amount: payload.invoiceAmount,
          cardLogo: payload.cardLogo,
          netTransactionId: payload.netTransactionId,
        });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (fetchCreditCardSaleMutation.isSuccess) {
      const callBackUrl = fetchCreditCardSaleMutation.data;
      setPaymentCallbackUrl(callBackUrl);
    }

    if (fetchCreditCardSaleMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    fetchCreditCardSaleMutation.isSuccess,
    fetchCreditCardSaleMutation.isError,
    fetchCreditCardSaleMutation.isLoading,
    fetchCreditCardSaleMutation.data,
    fetchCreditCardSaleMutation.error,
  ]);

  return (
    <Box
      sx={{
        borderRadius: "10px",
        minHeight: "630px",
        py: "auto",
      }}
    >
      <iframe
        style={{
          height: "630px",
          width: "100%",
          border: "none",
        }}
        src={paymentCallbackUrl}
        onLoad={() => setIsIframeLoading(false)}
      ></iframe>
      {(fetchCreditCardSaleMutation.isLoading || isIframeLoading) && (
        <Loading
          message=" "
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            backgroundColor: "rgba(255, 255, 255, 0.6)",
          }}
        />
      )}
    </Box>
  );
};
