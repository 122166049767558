import { useMutation, useQuery } from "react-query";
import {
  LoginCredentials,
  LoginResponse,
  LogoffRequest,
  QueryOptions,
  User,
} from "../types";
import { client } from "./axios";
import { config } from "../config";
import { DEFAULT_QUERY_OPTIONS } from "../constants";

export const useLoginMutation = () => {
  const mutation = useMutation(
    async ({ username, password }: LoginCredentials) => {
      const path = config.api.authService.login;
      const response = await client.post<LoginResponse>(path, {
        userName: username,
        password,
      });
      const user = response.data.response;

      return user;
    }
  );

  return mutation;
};

export const useLogoffMutation = () => {
  const mutation = useMutation(async ({ userName }: LogoffRequest) => {
    const path = config.api.authService.logoff;
    const response = await client.post(path, {
      userName,
    });
    return response;
  });

  return mutation;
};

export const useVerifyDirectPaymentTokenQuery = (
  token: string,
  queryOptions?: QueryOptions<string>
) => {
  const verifyDirectPaymentToken = async (): Promise<User> => {
    const path = config.api.authService.verifyDirectPaymentToken.replace(
      "{token}",
      token.toString()
    );

    const response = await client.get<User>(path);

    return response.data;
  };

  return useQuery(
    ["verifyDirectPaymentToken", token],
    verifyDirectPaymentToken,
    {
      ...queryOptions,
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};
