import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Avatar, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLogoffMutation } from "../../api";
import { LogoffRequest } from "../../types";
import { useAtom } from "jotai";
import { isLoaderActiveAtom, storage, userAtom } from "../../atoms";
import { UserProfileRoute, LoginRoute } from "../../routes";
import { SESSION_STORAGE_USER_KEY } from "../../constants";

export const ProfileMenu: React.FC = () => {
  const { mutate, isSuccess, isError, data, error, isLoading } =
    useLogoffMutation();
  const [user, setUser] = useAtom(userAtom);
  const [, setIsLoaderActive] = useAtom(isLoaderActiveAtom);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (isSuccess) {
      setIsLoaderActive(false);
      setUser(null);
      storage.removeItem(SESSION_STORAGE_USER_KEY);
      navigate(LoginRoute.path);
    }
    if (isError) {
      setIsLoaderActive(false);
    }
    if (isLoading) {
      setIsLoaderActive(true);
    }
  }, [isSuccess, isError, data, error, isLoading]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoff = async ({ userName }: LogoffRequest) => {
    setAnchorEl(null);
    mutate({ userName });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex" }} onClick={handleMenu}>
        <Avatar
          alt={user?.firstName + " " + user?.lastName}
          src={user?.profilePic}
          sx={{ width: 21, height: 21, marginRight: "10px" }}
        />
        <Typography
          variant="body1"
          sx={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            color: "#000000",
            textTransform: "capitalize",
          }}
        >
          {user?.firstName + " " + user?.lastName}
        </Typography>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "150px",
            top: "60px !important",
          },
          "& .css-99wn1p-MuiList-root-MuiMenu-list": {
            padding: "0px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate(UserProfileRoute.path);
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (user) handleLogoff({ userName: user.userName });
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
