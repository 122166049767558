import { AccountMenuItem } from "../../types";
import { OrganizationProfile } from "../profile";
import { Integrations } from "../integrations";
import { OrganizationAddress } from "../address";

export const organizationMenus: AccountMenuItem[] = [
  {
    name: "profile",
    displayName: "Profile",
    component: <OrganizationProfile />,
  },
  {
    name: "integrations",
    displayName: "Integrations",
    component: <Integrations />,
  },
  {
    name: "addresses",
    displayName: "Addresses",
    component: <OrganizationAddress />,
  },
];

export const organizationMenuDefault: AccountMenuItem = organizationMenus[0];
