import { Box, Skeleton, Typography } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import { OrganizationProfileInfo } from "../../../../../types";
import { EditIcon } from "../../../../icons";

type ViewOrganizationProfileBodyProps = {
  data?: OrganizationProfileInfo;
  isLoading?: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ViewOrganizationProfileBody = ({
  data,
  isLoading,
  handleEditMode,
}: ViewOrganizationProfileBodyProps) => {
  return (
    <Grid
      container
      spacing={{ xs: 0, sm: 2 }}
      sx={{
        width: "100%",
        height: "100%",
        px: 3,
        position: "relative",
      }}
    >
      <Box
        onClick={() => handleEditMode(true)}
        sx={{
          ":hover": {
            cursor: "pointer",
          },
          position: "absolute",
          right: "0.5rem",
          top: "0.5rem",
        }}
      >
        {data && <EditIcon width={"21px"} active={true} />}
      </Box>
      <Grid
        container
        size={{ xs: 12, sm: 6 }}
        gap={3}
        sx={{
          px: { xs: 0, sm: 4 },
          pt: 3,
          pb: { xs: 0, sm: 3 },
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Contact Person
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {data && data.contactPerson ? data.contactPerson : "N/A"}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Contact Number
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {data && data.contactNumber ? data.contactNumber : "N/A"}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Office Number
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {data && data.officeNumber ? data.officeNumber : "N/A"}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
      <Grid
        container
        size={{ xs: 12, sm: 6 }}
        gap={3}
        sx={{
          px: { xs: 0, sm: 4 },
          pt: 3,
          pb: { xs: 0, sm: 3 },
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Mobile Number
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {data && data.mobileNumber ? data.mobileNumber : "N/A"}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#606060",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Timezone
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "left",
                }}
              >
                {data && data.timezone ? data.timezone : "N/A"}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
