import { useEffect, useState } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { CustomerInfo, ToastOptions } from "../../../types";
import { Button, Toast } from "../../common";
import {
  ViewUserProfileInfo,
  EditUserProfileInfo,
  ProfilePicHandler,
  ChangeUserPasswordModal,
  LogoutModal,
} from "./fragments";
import { objectDiff, theme } from "../../../utils";
import { selectedOrgIdAtom, userAtom } from "../../../atoms";
import { useAtom } from "jotai";
import {
  useFetchUserQuery,
  useUpdateUserProfileMutation,
  useUpdateUserProfilePicMutation,
} from "../../../api/userService";

export const UserProfile = () => {
  const [user] = useAtom(userAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const updateUserProfileMutation = useUpdateUserProfileMutation();
  const updateUserProfilePicMutation = useUpdateUserProfilePicMutation();
  const fetchUserQuery = useFetchUserQuery(selectedOrgId, user?.id || 0);
  const [userInfo, setUserInfo] = useState<CustomerInfo>({
    id: 0,
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    employeeId: "",
    userName: "",
    roleCode: "",
    active: false,
    roleName: "",
    profilePic: "",
  });
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const [isProfileEditMode, setIsProfileEditMode] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [updatedUserName, setUpdatedUserName] = useState("");
  const handleEditProfile = (
    data: Pick<
      CustomerInfo,
      "firstName" | "lastName" | "emailId" | "phoneNumber"
    >
  ) => {
    if (data.emailId !== userInfo.emailId) {
      setUpdatedUserName(data.emailId);
    }
    const changedProps = objectDiff(userInfo, data);
    updateUserProfileMutation.mutate({
      ...userInfo,
      orgId: selectedOrgId,
      ...changedProps,
    });
  };

  const updateProfilePic = (profilePic: string) => {
    const id = user?.id || 0;
    const orgId = selectedOrgId;
    updateUserProfilePicMutation.mutate({ id, orgId, profilePic });
  };

  const handleCloseModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  useEffect(() => {
    if (fetchUserQuery.isSuccess) {
      setUserInfo(fetchUserQuery.data);
    }
  }, [fetchUserQuery.isSuccess, fetchUserQuery.data, fetchUserQuery.isLoading]);

  useEffect(() => {
    if (updateUserProfileMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "User updated successfully.",
        severity: "success",
      });
      if (updatedUserName) {
        setLogoutModalOpen(true);
      }
      fetchUserQuery.refetch();
      setIsProfileEditMode(false);
    } else if (updateUserProfileMutation.isError) {
      const { status } = updateUserProfileMutation.error;
      switch (status) {
        case 409:
          setToastOptions({
            open: true,
            message: "A user with this email or phone number already exists.",
            severity: "error",
          });
          break;

        default:
          setToastOptions({
            open: true,
            message: "Something went wrong.",
            severity: "error",
          });
          break;
      }
    }
  }, [
    updateUserProfileMutation.isSuccess,
    updateUserProfileMutation.isError,
    updateUserProfileMutation.isLoading,
  ]);

  useEffect(() => {
    if (updateUserProfilePicMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Profile picture updated successfully!",
        severity: "success",
      });
    }

    if (updateUserProfilePicMutation.isError) {
      setToastOptions({
        open: true,
        message: "Failed to upload profile picture. Please try again.",
        severity: "error",
      });
    }
  }, [
    updateUserProfilePicMutation.isSuccess,
    updateUserProfilePicMutation.isError,
    updateUserProfilePicMutation.isLoading,
  ]);

  return (
    <Box
      sx={{
        marginTop: "50px",
        width: "100%",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Toast setOptions={setToastOptions} options={toastOptions} />
      <Grid2
        container
        direction="column"
        spacing={3}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: theme.palette.background.default,
          alignItems: "center",
          padding: "20px 40px",
        }}
      >
        <Grid2 container size={12} direction="row">
          <Typography sx={{ fontSize: "1rem", fontWeight: 700, color: "#000" }}>
            Profile
          </Typography>
        </Grid2>
        <Grid2
          container
          size={12}
          direction="row"
          sx={{
            flexGrow: 1,
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {isProfileEditMode ? (
            <EditUserProfileInfo
              defaultValues={userInfo}
              handleEdit={handleEditProfile}
              handleDiscard={setIsProfileEditMode}
              loading={updateUserProfileMutation.isLoading}
            />
          ) : (
            <ViewUserProfileInfo
              data={userInfo}
              handleEditMode={setIsProfileEditMode}
              loading={fetchUserQuery.isLoading}
            />
          )}
        </Grid2>
        <Grid2
          container
          size={12}
          direction="row"
          sx={{
            flexGrow: 1,
            boxShadow: "0px 0px 4px 0px #00000040",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid2
            size={{ lg: 6, sm: 6, xs: 12 }}
            gap={3}
            sx={{
              px: { md: 2, xs: 0, sm: 4 },
              py: 0,
              flexDirection: "column",
            }}
          >
            <ProfilePicHandler
              profilePicUrl={userInfo.profilePic}
              isDisableButton={updateUserProfilePicMutation.isLoading}
              updateImage={updateProfilePic}
            />
          </Grid2>
          <Grid2
            size={{ lg: 6, xs: 12 }}
            gap={3}
            sx={{
              px: { md: 2, xs: 0, sm: 4 },
              py: 0,
              flexDirection: "column",
            }}
          >
            <Button
              label={"Change Password"}
              sx={{ width: "200px", marginBottom: "35px" }}
              onClick={() => {
                setIsResetPasswordModalOpen(true);
              }}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <ChangeUserPasswordModal
        open={isResetPasswordModalOpen}
        onClose={handleCloseModal}
      />
      <LogoutModal
        open={logoutModalOpen}
        userName={updatedUserName}
        message={
          "Your email has been updated. Please log in again to continue."
        }
      />
    </Box>
  );
};
