import { Report } from "../types";

export class ReportModel {
  public static buildAccountActivityReport(reports: Report[]): Report[] {
    return reports.map((report,index) => ({
      id: index,
      ...report,
    }));
  }
  public static buildLeadReport(reports: Report[]): Report[] {
    return reports.map((report,index) => ({
      id: index,
      ...report,
    }));
  }
}
