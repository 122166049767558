import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type PdfNavigationProps = {
  navigateLeft: () => void;
  navigateRight: () => void;
};

export const PdfNavigation = ({
  navigateLeft,
  navigateRight,
}: PdfNavigationProps) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: "50%",
        }}
      >
        <IconButton onClick={navigateLeft}>
          <ChevronLeftIcon
            sx={{
              fontSize: {
                lg: "2rem",
                sm: "1.4rem",
                xs: "1rem",
              },
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: "50%",
        }}
      >
        <IconButton onClick={navigateRight}>
          <ChevronRightIcon
            sx={{
              fontSize: {
                lg: "2rem",
                sm: "1.4rem",
                xs: "1rem",
              },
            }}
          />
        </IconButton>
      </Box>
    </>
  );
};
