import * as React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DatePickerProps as MuiDatePickerProps,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers/DatePicker";
import { Box, FormHelperText, Typography } from "@mui/material";
import {
  FieldError,
  Control,
  Controller,
  FieldErrorsImpl,
  Merge,
  ControllerRenderProps,
} from "react-hook-form";

type DatePickerProps = MuiDatePickerProps<any, any> &
  React.RefAttributes<HTMLDivElement> & {
    label?: string;
    placeHolder?: string;
    name: string;
    onChange?: (data: any) => void;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    defaultValue?: any;
    control?: Control<any>;
    defaultTimeNow?: boolean;
  };

export const DatePicker = ({
  label,
  placeHolder,
  name,
  error,
  onChange,
  defaultValue,
  control,
  defaultTimeNow = false,
  ...props
}: DatePickerProps) => {
  const renderDatePicker = ({
    field,
    props,
  }: {
    field?: ControllerRenderProps<any, string>;
    props?: MuiDatePickerProps<any, any>;
  }) => {
    return (
      <MuiDatePicker
        {...field}
        {...props}
        onChange={(value) => {
          if (defaultTimeNow) {
            const now = new Date();
            const dateWithCurrentTime = value
              .set("hour", now.getHours())
              .set("minute", now.getMinutes())
              .set("second", now.getSeconds())
              .set("millisecond", now.getMilliseconds());

            field && field.onChange(dateWithCurrentTime);
            onChange && onChange(dateWithCurrentTime);
          } else {
            field && field.onChange(value);
            onChange && onChange(value);
          }
        }}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            borderBottom: error ? "1px solid #AA210E" : "1px solid #D2D2D2",
            borderRadius: "0px",
            alignItems: "flex-end",
            "& fieldset": {
              border: "none",
            },
          },
          "& .MuiInputBase-input": {
            padding: "0.5rem",
          },
        }}
        label={placeHolder}
      />
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        position: "relative",
      }}
    >
      <Typography sx={{ marginBottom: "4px" }}>{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {control ? (
          <Controller
            control={control}
            name={name}
            render={({ field }) =>
              renderDatePicker({ field, props: { defaultValue, ...props } })
            }
          />
        ) : (
          renderDatePicker({ props: { value: defaultValue, ...props } })
        )}
      </LocalizationProvider>
      {error && (
        <FormHelperText
          sx={{
            bottom: "-1.5rem",
            position: "absolute",
            color: "#AA210E",
          }}
        >{`${error.message}`}</FormHelperText>
      )}
    </Box>
  );
};
