import { Chip, ChipProps, SvgIconProps } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import { InvoicePaymentStatus, InvoiceStatus } from "../../../enums";
import { ExtendedInvoiceStatus } from "../../../types";

type InvoicePaymentStatusChipProps = ChipProps & {
  status: ExtendedInvoiceStatus;
};

type InvoicePaymentStatusChipIconProps = SvgIconProps & {
  status: ExtendedInvoiceStatus;
};

const InvoicePaymentStatusChipIcon: React.FC<
  InvoicePaymentStatusChipIconProps
> = ({ status, ...props }) => {
  if (
    status === InvoiceStatus.Cancelled ||
    status === InvoiceStatus.Deleted ||
    status === InvoicePaymentStatus.Overdue
  ) {
    return <ErrorOutlineIcon {...props} />;
  } else if (
    [InvoiceStatus.PaymentReceived, InvoicePaymentStatus.Paid].includes(status)
  ) {
    return <CheckCircleOutlineIcon {...props} />;
  } else {
    return <AccessAlarmIcon {...props} />;
  }
};

export const InvoicePaymentStatusChip: React.FC<
  InvoicePaymentStatusChipProps
> = ({ status, ...props }) => {
  const getColor = (status: ExtendedInvoiceStatus) => {
    if (
      [InvoiceStatus.PaymentReceived, InvoicePaymentStatus.Paid].includes(
        status
      )
    ) {
      return "#40aa0e";
    } else {
      return "#eb4040";
    }
  };

  const getBgColor = (status: ExtendedInvoiceStatus) => {
    if (
      [InvoiceStatus.PaymentReceived, InvoicePaymentStatus.Paid].includes(
        status
      )
    ) {
      return "#dbff8e";
    } else {
      return "#ffd7d7";
    }
  };

  return (
    <Chip
      {...props}
      sx={{
        borderRadius: 0,
        color: getColor(status),
        fontWeight: 600,
        backgroundColor: getBgColor(status),
        textTransform: "capitalize",
      }}
      label={status.replace(/-/g, " ")}
      icon={
        <InvoicePaymentStatusChipIcon
          status={status}
          sx={{ color: `${getColor(status)} !important` }}
        />
      }
    />
  );
};
