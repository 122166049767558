import { Grid2 as Grid, Typography } from "@mui/material";

type Props = {
  title?: string;
};

export const VerificationError = ({ title }: Props) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh", width: "100%" }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#fff",
          padding: "40px",
          borderRadius: "12px",
          textAlign: "center",
          maxWidth: "400px",
          width: "90%",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            backgroundColor: "#ff3b30",
            color: "#fff",
            fontSize: "40px",
            marginBottom: "20px",
          }}
        >
          ✘
        </Grid>
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          Access Denied
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "rgba(0, 0, 0, 0.7)", marginBottom: 3 }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};
