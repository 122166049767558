import { CircularProgress, Grid2 as Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { AccountDetails, AccountTitle, InvoicesAndPayments } from "./fragments";
import {
  useFetchAccountInfoQuery,
  useFetchOrganizationInfoQuery,
  useVerifyDirectPaymentTokenQuery,
} from "../../api";
import {
  dpUserAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
} from "../../atoms";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { AccountInfo, ApiError, OrganizationInfo } from "../../types";
import { VerificationError } from "./VerificationError";
import { AxiosError } from "axios";

export const DirectPayment = () => {
  const params = useParams<{ token: string }>();
  const [, setDpUser] = useAtom(dpUserAtom);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [, setSelectedOrgId] = useAtom(selectedOrgIdAtom);
  const [, setSelectedAccountId] = useAtom(selectedAccountIdAtom);
  const verifyDirectPaymentTokenQuery = useVerifyDirectPaymentTokenQuery(
    params.token || ""
  );
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);

  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>();
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();

  const fetchOrganizationInfoQuery = useFetchOrganizationInfoQuery(
    selectedOrgId,
    { enabled: selectedOrgId ? true : false }
  );

  const fetchAccountInfoQuery = useFetchAccountInfoQuery(
    selectedOrgId,
    selectedAccountId || 0,
    { contacts: true },
    { enabled: selectedAccountId ? true : false }
  );

  useEffect(() => {
    if (fetchOrganizationInfoQuery.isSuccess) {
      setOrganizationInfo(fetchOrganizationInfoQuery.data);
    }
  }, [
    fetchOrganizationInfoQuery.isSuccess,
    fetchOrganizationInfoQuery.isError,
    fetchOrganizationInfoQuery.data,
    fetchOrganizationInfoQuery.error,
    fetchOrganizationInfoQuery.isLoading,
  ]);

  useEffect(() => {
    if (fetchAccountInfoQuery.isSuccess) {
      setAccountInfo(fetchAccountInfoQuery.data);
    }
  }, [
    fetchAccountInfoQuery.isSuccess,
    fetchAccountInfoQuery.isError,
    fetchAccountInfoQuery.data,
    fetchAccountInfoQuery.error,
    fetchAccountInfoQuery.isLoading,
  ]);

  useEffect(() => {
    if (verifyDirectPaymentTokenQuery.isSuccess) {
      const dpUser = verifyDirectPaymentTokenQuery.data;

      setDpUser(dpUser);
      setSelectedOrgId(dpUser.orgId);
      setSelectedAccountId(dpUser.accountId);
    }
    if (verifyDirectPaymentTokenQuery.isError) {
      const error = verifyDirectPaymentTokenQuery.error as AxiosError<ApiError>;
      setErrorMessage(error.response?.data.error.message);
    }
  }, [
    verifyDirectPaymentTokenQuery.isSuccess,
    verifyDirectPaymentTokenQuery.isError,
    verifyDirectPaymentTokenQuery.data,
    verifyDirectPaymentTokenQuery.error,
    verifyDirectPaymentTokenQuery.isLoading,
  ]);

  // eslint-disable-next-line no-constant-condition
  return verifyDirectPaymentTokenQuery.isLoading ? (
    <Grid
      size={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh !important",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : verifyDirectPaymentTokenQuery.isError ? (
    <Grid
      size={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh !important",
      }}
    >
      <VerificationError title={errorMessage} />
    </Grid>
  ) : (
    <Grid
      container
      direction={"column"}
      spacing={2}
      gap={2}
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        direction: "row",
        p: "1rem",
      }}
    >
      <AccountTitle organizationInfo={organizationInfo} />
      <AccountDetails
        organizationInfo={organizationInfo}
        accountInfo={accountInfo}
        isLoading={
          fetchOrganizationInfoQuery.isLoading ||
          fetchAccountInfoQuery.isLoading
        }
      />
      <InvoicesAndPayments />
    </Grid>
  );
};
