import { Grid2 } from "@mui/material";
import { Tile } from "./Tile";
import { SummaryTile } from "../../../types";

type DashboardSummaryRowProps = {
  tiles: SummaryTile[];
};

export const DashboardSummaryRow: React.FC<DashboardSummaryRowProps> = ({
  tiles,
}) => {
  return (
    <>
      {tiles.map((tile, index) => (
        <Grid2 key={index} size={{ lg: 3, md: 6, sm: 6, xs: 12, xl: 3 }}>
          <Tile
            heading={tile.heading}
            total={tile.total}
            isLoading={tile.isLoading}
            footer={tile.footer}
          />
        </Grid2>
      ))}
    </>
  );
};
