import moment from "moment";
import {
  GroupByYearMonthWeek,
  InvoiceGroup,
  OrgUserGroupByRole,
  WashesGroup,
} from "../types";

export const getNumberOfWashesByDate = (
  washData: WashesGroup[],
  date: string
): number => {
  return washData.find((wash) => wash.key === date)?.numberOfWashes || 0;
};

export const getNumberOfWashesByDateRange = (
  washData: WashesGroup[],
  startDate: string,
  endDate: string
): number => {
  const start = moment(startDate);
  const end = moment(endDate);

  return washData
    .filter((wash) => {
      const washDate = moment(wash.key);
      return washDate.isSameOrAfter(start) && washDate.isSameOrBefore(end);
    })
    .reduce((total, wash) => total + wash.numberOfWashes, 0);
};

export const getInvoiceAmountByDate = (
  invoiceData: InvoiceGroup[],
  date: string
): number => {
  const invoice = invoiceData.find((invoice) => invoice.key === date);
  return invoice ? invoice.invoiceAmount : 0;
};

export const getInvoiceAmountByDateRange = (
  invoiceData: InvoiceGroup[],
  startDate: string,
  endDate: string
): number => {
  const start = moment(startDate);
  const end = moment(endDate);
  return invoiceData
    .filter((invoice) => {
      const invoiceDate = moment(invoice.key);
      return (
        invoiceDate.isSameOrAfter(start) && invoiceDate.isSameOrBefore(end)
      );
    })
    .reduce((total, invoice) => total + invoice.invoiceAmount, 0);
};

export const getTotalNumberOfUsers = (
  usersData: OrgUserGroupByRole[]
): number => {
  return usersData.reduce(
    (total, userGroup) => total + userGroup.numberOfUsers,
    0
  );
};

export const getProgressPercent = (
  currentPeriodValue: number,
  previousPeriodValue: number
): number => {
  let progressPercent = 0;

  if (previousPeriodValue > 0) {
    progressPercent =
      ((currentPeriodValue - previousPeriodValue) / previousPeriodValue) * 100;
  }

  return progressPercent;
};

export const aggregateWashesByMonth = (
  washData: GroupByYearMonthWeek,
  year: string
) => {
  const result: { month: string; totalWashes: number }[] = [];

  if (washData[year]) {
    const months = washData[year];
    for (const month in months) {
      let totalWashesForMonth = 0;

      for (const week in months[month]) {
        totalWashesForMonth += months[month][week];
      }

      result.push({
        month,
        totalWashes: totalWashesForMonth,
      });
    }
  }

  return result;
};

export const aggregateWashesByMonthAndWeek = (
  washData: GroupByYearMonthWeek,
  year: string
) => {
  const data = washData[year];
  const outputData: Array<{ [key: string]: number | string }> = [];

  for (const month in data) {
    const weekTotals: { [key: string]: number } = {
      week1: 0,
      week2: 0,
      week3: 0,
      week4: 0,
      week5: 0,
    };

    for (const week in data[month]) {
      const weekNumber = parseInt(week) - 27;
      if (weekNumber >= 1 && weekNumber <= 5) {
        weekTotals[`week${weekNumber}`] += data[month][week];
      }
    }

    outputData.push({
      ...weekTotals,
      month: month,
    });
  }
  return outputData;
};

export const getWashesCountInDateRange = (
  washesGroupedByYearMonthAndWeek: GroupByYearMonthWeek,
  startDate: string,
  endDate: string
): number => {
  const start = moment(startDate);
  const end = moment(endDate);
  let totalWashes = 0;

  for (const year in washesGroupedByYearMonthAndWeek) {
    const yearData = washesGroupedByYearMonthAndWeek[year];

    for (const month in yearData) {
      const monthData = yearData[month];

      const monthStartDate = moment(`${year}-${month}-01`, "YYYY-MM-DD");
      const monthEndDate = monthStartDate.clone().endOf("month");

      if (
        monthEndDate.isSameOrAfter(start) &&
        monthStartDate.isSameOrBefore(end)
      ) {
        for (const week in monthData) {
          const weekData = monthData[week];
          totalWashes += weekData;
        }
      }
    }
  }

  return totalWashes;
};

export const getInvoiceAmountByKey = (
  data: InvoiceGroup[],
  key: string
): number => {
  const item = data.find((entry) => entry.key === key);
  return item ? item.invoiceAmount : 0;
};

export const formatCurrency = (
  amount: number,
  currency: string = "USD"
): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  }).format(amount);
};
