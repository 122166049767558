import { Box, Skeleton, Typography } from "@mui/material";
import { Grid2 as Grid, Paper, styled } from "@mui/material";
import { AccountProfileInfo } from "../../../../../types";
import { EditIcon } from "../../../../icons";
import { theme } from "../../../../../utils";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import { toastOptionsAtom } from "../../../../../atoms";
import { ImageCropModal } from "../../../../common";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  flexGrow: 1,
  borderRadius: "10px",
  boxShadow: "none",
}));

type ViewAccountProfileHeaderProps = {
  data?: AccountProfileInfo;
  isLoading?: boolean;
  handleEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleUploadProfilePicture: ({
    data,
    logo,
  }: {
    data: Pick<AccountProfileInfo, "accountName" | "logo">;
    logo?: File;
  }) => void;
};

export const ViewAccountProfileHeader = ({
  data,
  isLoading,
  handleEditMode,
  handleUploadProfilePicture,
}: ViewAccountProfileHeaderProps) => {
  const [uploadedImage, setUploadedImage] = useState<File>();
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [, setToastOptions] = useAtom(toastOptionsAtom);
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (uploadedImage) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setUploadedImageUrl(e.target.result as string);
      };
      reader.readAsDataURL(uploadedImage);
    }
  }, [uploadedImage]);

  const handleConfirm = (file: File) => {
    setUploadedImage(file);
    setCropModalOpen(false);

    if (data && uploadedImage) {
      const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
      const fileExtension = uploadedImage.name.split(".").pop();
      const fileName = `logo_${timestamp}.${fileExtension}`;

      handleUploadProfilePicture({
        data: { accountName: data.accountName, logo: fileName },
        logo: file,
      });
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file && file.type.startsWith("image/")) {
      setUploadedImage(file);
      setCropModalOpen(true);
    } else {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }

    event.target.value = "";
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          width: "100%",
          minHeight: "150px",
          position: "relative",
        }}
      >
        <Box
          onClick={() => {
            handleEditMode(true);
          }}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
            position: "absolute",
            right: "1rem",
            top: "1rem",
          }}
        >
          {data && <EditIcon width={"21px"} active={true} />}
        </Box>
        <Grid container size={{ xs: 4, sm: 6 }} direction={"row"}>
          <Grid container size={{ sm: 6, md: 6, lg: 4, xs: 12 }}>
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pl: { md: 6, xs: 2 },
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="circular"
                  sx={{
                    width: { xs: 60, sm: 70 },
                    height: { xs: 60, sm: 70 },
                  }}
                />
              ) : (
                <Box
                  component={"img"}
                  alt="Account logo"
                  src={uploadedImageUrl ? uploadedImageUrl : data?.logo}
                  sx={{
                    width: { xs: 60, sm: 70 },
                    height: { xs: 60, sm: 70 },
                    objectFit: "contain",
                  }}
                />
              )}
              <input
                ref={uploadInputRef}
                type="file"
                name="logo"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
            </Item>
          </Grid>
          <Grid
            container
            size={6}
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
            }}
          >
            <Item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              {isLoading ? (
                <Skeleton variant="rectangular" width={"100%"} height={60} />
              ) : (
                <>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      textDecorationColor: theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      uploadInputRef.current && uploadInputRef.current.click()
                    }
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        textAlign: "left",
                      }}
                    >
                      Change Profile Picture
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#a6a6a6",
                      textAlign: "left",
                    }}
                  >
                    (Image must be in jpeg/png format)
                  </Typography>
                </>
              )}
            </Item>
          </Grid>
        </Grid>
        <Grid container size={6}>
          <Item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              px: { xs: 0, sm: 4 },
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  width: "100%",
                  pr: { xs: 0, sm: 4 },
                }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "2rem", width: "100%" }}
                />
              </Box>
            ) : (
              <>
                <Typography
                  sx={{
                    color: "#606060",
                    fontSize: "14px",
                  }}
                >
                  Company Name
                </Typography>
                <Typography>{data?.accountName}</Typography>
              </>
            )}
          </Item>
        </Grid>
      </Grid>
      {uploadedImage && (
        <ImageCropModal
          open={cropModalOpen}
          file={uploadedImage}
          onClose={() => {
            setCropModalOpen(false);
          }}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};
