import { atom } from "jotai";
import {
  AccountMenuItem,
  AccountContact,
  DropdownItem,
  AccountBasicInfo,
} from "../types";
import { accountMenuDefault } from "../components/account/AccountMenus";

export const accountActiveMenuItemAtom =
  atom<AccountMenuItem>(accountMenuDefault);
export const accountDetailSelectedAtom = atom(false);
export const isCreateAccountModeAtom = atom(false);
export const selectedAccountIdAtom = atom<number>();
export const selectedAccountAtom = atom<AccountBasicInfo>();
export const isCreateContactComponentActiveAtom = atom(false);
export const isCreateLocationComponentActiveAtom = atom(false);
export const isCreateButtonActiveAtom = atom(
  (get) => {
    const activeMenuItem = get(accountActiveMenuItemAtom);

    if (activeMenuItem.name === "location") {
      return get(isCreateLocationComponentActiveAtom);
    } else if (activeMenuItem.name === "contact") {
      return get(isCreateContactComponentActiveAtom);
    }

    return false;
  },
  (get, set) => {
    set(isCreateLocationComponentActiveAtom, false);
    set(isCreateContactComponentActiveAtom, false);
  }
);
export const selectedContactToEditAtom = atom<AccountContact>();
export const selectedContactToDeleteAtom = atom<AccountContact>();
export const washTypesDropDownAtom = atom<DropdownItem[]>();
export const miscWashTypesDropDownAtom = atom<DropdownItem[]>();
export const expandedAccountAccordionItemAtom = atom<AccountMenuItem | null>(
  accountMenuDefault
);
export const isAccountProfileBodyEditModeAtom = atom(false);
export const isCreateBillingModeAtom = atom(false);
