import { Box, Grid2 as Grid } from "@mui/material";
import { SelectBox, TextField } from "../../common";
import { ChargeInformationRow, ModalForm } from "../../../types";
import { useValidateForm } from "../../../hooks";
import { editMiscChargeSchema } from "../../../validations";
import { miscWashTypesDropDownAtom } from "../../../atoms";
import { useAtom } from "jotai";

type Props = ModalForm<
  ChargeInformationRow,
  { washRate: number; washTypeId: number; id: number }
>;

export const MiscChargeForm = ({ id, defaultValues, handleEdit }: Props) => {
  const [miscWashTypesDropDown] = useAtom(miscWashTypesDropDownAtom);
  const { register, handleSubmit, errors } =
    useValidateForm(editMiscChargeSchema);

  const handleEditWrapper = (data: {
    washRate: string;
    washTypeId: number;
  }) => {
    handleEdit({
      ...data,
      washRate: Number(data.washRate),
      id: defaultValues ? defaultValues.id : 0,
    });
  };

  return (
    <Box
      onSubmit={handleSubmit(handleEditWrapper)}
      component={"form"}
      id={id}
      noValidate
      sx={{
        height: "100%",
      }}
    >
      <Grid
        container
        size={12}
        direction={"column"}
        gap={3}
        sx={{
          borderRadius: "10px",
          p: 3,
          mb: 2,
          width: "100%",
        }}
      >
        <Grid size={12}>
          {miscWashTypesDropDown && (
            <SelectBox
              label="Misc Type"
              name="washTypeId"
              dropdown={miscWashTypesDropDown}
              register={register}
              error={errors["washTypeId"]}
              defaultValue={defaultValues && defaultValues.id}
              valueAsNumber={true}
            />
          )}
        </Grid>
        <Grid size={12}>
          <TextField
            type={"number"}
            label="Misc Rate"
            name="washRate"
            register={register}
            error={errors["washRate"]}
            defaultValue={defaultValues && defaultValues.washRate}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
