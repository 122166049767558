import {
  Card,
  CardContent,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { defaultProfileImage, sprayzAppLogoBlack } from "../../../assets";
import { theme } from "../../../utils";
import { AutoRetryImage } from "../../common/image/AutoRetryImage";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { OrganizationInfo } from "../../../types";

type Props = { organizationInfo?: OrganizationInfo };

export const AccountTitle = ({ organizationInfo }: Props) => {
  return (
    <Card
      sx={{
        display: "flex",
        boxShadow: "0px 0px 4px 0px #00000040",
        width: "100%",
        minHeight: 120,
      }}
    >
      <CardContent sx={{ display: "flex", alignItems: "center", px: 4, py: 0 }}>
        <AutoRetryImage
          sx={{
            width: 250,
            height: 100,
            objectFit: "contain",
          }}
          src={sprayzAppLogoBlack}
          defaultImage={defaultProfileImage}
        />
      </CardContent>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <CardContent
          sx={{
            flex: "1 0 auto",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography component="div" variant="h5">
            {"Sprayzapp"}
          </Typography>
        </CardContent>
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip
            title={organizationInfo?.contactNumber}
            slotProps={{ tooltip: { sx: { fontSize: "1rem" } } }}
          >
            <IconButton>
              <CallIcon
                fontSize="medium"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={organizationInfo?.primaryAddress?.email}
            slotProps={{ tooltip: { sx: { fontSize: "1rem" } } }}
          >
            <IconButton>
              <EmailIcon
                fontSize="medium"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Tooltip>
        </CardContent>
      </Box>
    </Card>
  );
};
