import React from "react";
import { Box, Popper, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { theme } from "../../../../utils";

interface PasswordRequirementTooltipProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  password: string;
  placement: "top" | "bottom" | "right" | "left" | "right-end";
}

export const PasswordRequirementTooltip: React.FC<
  PasswordRequirementTooltipProps
> = ({ open, anchorEl, password, placement = "right" }) => {
  const passwordRequirements = [
    {
      label: "At least 8 characters",
      valid: password.length >= 8,
    },
    {
      label: "No more than 20 characters",
      valid: password.length <= 20 && password.length > 0,
    },
    {
      label: "At least one uppercase letter",
      valid: /[A-Z]/.test(password),
    },
    {
      label: "At least one lowercase letter",
      valid: /[a-z]/.test(password),
    },
    {
      label: "At least one number",
      valid: /[0-9]/.test(password),
    },
    {
      label: "At least one special character (!@#$%^&*)",
      valid: /[!@#$%^&*]/.test(password),
    },
  ];

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        width: { lg: 250, md: 200 },
        backgroundColor: theme.palette.background.paper,
        zIndex: 1300,
        display: {
          xs: "none",
          md: "flex",
        },
      }}
      placement={placement}
    >
      <Box sx={{ mt: 2, mb: 1, px: 2 }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          Requirements:
        </Typography>
        <Box sx={{ mb: 2 }}>
          {passwordRequirements.map((requirement, index) => (
            <Typography
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                color: requirement.valid ? "green" : "red",
                fontSize: "14px",
              }}
            >
              {requirement.valid ? (
                <CheckCircleIcon sx={{ mr: 1, fontSize: 14 }} />
              ) : (
                <CancelIcon sx={{ mr: 1, fontSize: 14 }} />
              )}
              {requirement.label}
            </Typography>
          ))}
        </Box>

        <Box
          sx={{
            borderTop: "1px solid #ccc",
            marginBottom: "5px",
          }}
        />

        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Samples
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          <ul
            style={{
              paddingLeft: "15px",
              marginTop: "0px",
            }}
          >
            <li>Example123!</li>
            <li>StrongPass2024@</li>
          </ul>
        </Typography>
      </Box>
    </Popper>
  );
};
