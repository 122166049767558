import axios from "axios";
import { config } from "../config";
import {
  SESSION_STORAGE_DP_USER_KEY,
  SESSION_STORAGE_USER_KEY,
} from "../constants";
import { User } from "../types";

export const client = axios.create({
  baseURL: config.api.baseUrl,
});

client.interceptors.request.use(
  (req) => {
    if (req.headers.Authorization === false) {
      return req;
    }

    const userValue = sessionStorage.getItem(SESSION_STORAGE_USER_KEY);
    const dpUserValue = sessionStorage.getItem(SESSION_STORAGE_DP_USER_KEY);
    const user: User | null = userValue && JSON.parse(userValue);
    const dpUser: User | null = dpUserValue && JSON.parse(dpUserValue);

    if (user) {
      req.headers.Authorization = `Bearer ${user.token}`;
    } else if (dpUser) {
      req.headers.Authorization = `Bearer ${dpUser.token}`;
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      sessionStorage.removeItem(SESSION_STORAGE_USER_KEY);
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
