import {
  Box,
  Chip,
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { Control, Controller, FieldError, Merge } from "react-hook-form";
import { DropdownItem } from "../../../types";

type AutocompleteProps = {
  dropdown: DropdownItem[];
  label: string;
  name: string;
  defaultValues?: any[];
  control?: Control<any>;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  isMultipleAllowed?: boolean;
};

export const Autocomplete = ({
  dropdown,
  label,
  name,
  defaultValues,
  control,
  error,
  isMultipleAllowed = true,
}: AutocompleteProps) => {
  return (
    <Box>
      <Typography>{label}</Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiAutocomplete
            {...field}
            multiple={isMultipleAllowed}
            autoSelect={true}
            id={name}
            options={(dropdown || []).map((option) => option.key)}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                paddingTop: "5px",
                paddingLeft: "5px",
                backgroundColor: "#fff",
              },
            }}
            defaultValue={defaultValues}
            freeSolo
            onChange={(_, data) => field.onChange(data)}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    variant="outlined"
                    label={option}
                    key={key}
                    {...tagProps}
                  />
                );
              })
            }
            renderInput={(params) => (
              <MuiTextField
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    py: 0,
                    "& .fieldset": {
                      borderColor: error ? "#AA210E" : "#D2D2D2",
                    },
                  },
                  "& .MuiFormHelperText-root.Mui-error": {
                    color: "#AA210E",
                    marginLeft: "0px",
                  },
                }}
                placeholder={label}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        )}
      />
    </Box>
  );
};
