import { atomWithStorage } from "jotai/utils";
import { storage } from "./storage";
import { SESSION_STORAGE_DP_USER_KEY } from "../constants";
import { User } from "../types";

const getDefaultValue = () => {
  const dpUser = sessionStorage.getItem(SESSION_STORAGE_DP_USER_KEY);

  if (!dpUser) return null;

  try {
    return JSON.parse(dpUser) as User;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e: any) {
    return null;
  }
};

export const dpUserAtom = atomWithStorage<User | null>(
  SESSION_STORAGE_DP_USER_KEY,
  getDefaultValue(),
  storage
);
