import { Box, Grid2 } from "@mui/material";
import { DataGrid, Toast } from "../../../common";
import {
  InvoiceFilters,
  InvoiceInfo,
  InvoiceItem,
  InvoiceOverviewInfo,
  InvoiceRequestParams,
  ToastOptions,
} from "../../../../types";
import { theme } from "../../../../utils";
import { useAtom } from "jotai";
import {
  invoiceDataGridPaginationAtom,
  invoiceFilterStateAtom,
  isInvoiceActionsMutationsLoadingAtom,
  isReceivePaymentFormActiveAtom,
  selectedAccountIdAtom,
  selectedOrgIdAtom,
} from "../../../../atoms";
import {
  useFetchInvoiceOverviewQuery,
  useFetchInvoicesQuery,
} from "../../../../api/invoiceService";
import { GridColDef, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { formatDate, getDateRange } from "../../../../utils/dateUtil";
import {
  DateRangeOptions,
  InvoicePaymentCallbackMessage,
  InvoiceStatus,
} from "../../../../enums";
import { useSearchParams } from "react-router-dom";
import { CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN } from "../../../../constants";
import { PaymentDialog } from "../../../payment";
import { InvoiceToolbar } from "./InvoiceToolbar";
import { InvoiceActionIcons } from "./InvoiceActionIcons";

export const Invoice = () => {
  const [selectedDateRange, setSelectedDateRange] =
    useState<string>("thisMonth");
  const [queryParams, setQueryParams] = useSearchParams();
  const [invoiceForPayment, setInvoiceForPayment] = useState<InvoiceItem>();
  const [filterValues, setFilterValues] = useAtom(invoiceFilterStateAtom);
  const [selectedOrgId] = useAtom(selectedOrgIdAtom);
  const [selectedAccountId] = useAtom(selectedAccountIdAtom);
  const [invoiceList, setInvoiceList] = useState<InvoiceInfo>();
  const [isInvoiceActionsMutationsLoading] = useAtom(
    isInvoiceActionsMutationsLoadingAtom
  );
  const [invoicePaginationModel, setInvoicePaginationModel] = useAtom(
    invoiceDataGridPaginationAtom
  );
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceItem[]>([]);
  const [isReceivePaymentFormActive, setIsReceivePaymentFormActive] = useAtom(
    isReceivePaymentFormActiveAtom
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [invoiceOverviewForPayment, setInvoiceOverviewForPayment] =
    useState<InvoiceOverviewInfo>();
  const [invoiceFilters, setInvoiceFilters] = useState<InvoiceFilters>({
    fromDate: getDateRange("thisMonth").startDate,
    toDate: getDateRange("thisMonth").endDate,
    status: String(CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value),
    sortBy: "invoiceDate",
    sortOrder: "desc",
  });
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });
  const resetPage = () => {
    setInvoicePaginationModel({ ...invoicePaginationModel, page: 0 });
  };
  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (sortModel.length > 0) {
      const sortField = sortModel[0].field;
      const sortOrder: GridSortDirection = sortModel[0].sort;
      if (sortOrder) {
        setInvoiceFilters({
          ...invoiceFilters,
          sortBy: sortField,
          sortOrder: sortOrder,
        });
      }
    } else if (invoiceFilters.sortBy !== "") {
      setInvoiceFilters({
        ...invoiceFilters,
        sortBy: "",
        sortOrder: "",
      });
    }
  };
  const buildInvoiceRequestParams = (): InvoiceRequestParams => {
    return {
      orgId: selectedOrgId || 0,
      accountId: selectedAccountId || 0,
      filters: {
        page: invoiceFilters?.page ? invoiceFilters?.page + 1 : 1,
        pageSize: invoiceFilters?.pageSize,
        fromDate: invoiceFilters?.fromDate,
        toDate: invoiceFilters?.toDate,
        status:
          invoiceFilters?.status !== InvoiceStatus.All
            ? invoiceFilters?.status
              ? invoiceFilters?.status
              : ""
            : "",
        sortBy: invoiceFilters?.sortBy,
        sortOrder: invoiceFilters?.sortOrder,
      },
    };
  };
  const fetchInvoicesQuery = useFetchInvoicesQuery(
    buildInvoiceRequestParams(),
    selectedOrgId && selectedAccountId ? true : false
  );
  const fetchInvoiceOverviewQuery = useFetchInvoiceOverviewQuery(
    invoiceForPayment?.orgId || 0,
    invoiceForPayment?.accountId || 0,
    invoiceForPayment?.invoiceNumber || "",
    {
      enabled:
        invoiceForPayment?.orgId &&
        invoiceForPayment?.accountId &&
        invoiceForPayment?.invoiceNumber &&
        (invoiceForPayment.invoiceStatus === InvoiceStatus.InProgress ||
          invoiceForPayment.invoiceStatus === InvoiceStatus.SendToCustomer)
          ? true
          : false,
    }
  );

  const columns: GridColDef[] = [
    {
      field: "invoiceDate",
      headerName: "Date",
      width: 230,
      editable: false,
      valueGetter: (value) => {
        return formatDate(value, "dddd - MMMM DD,YYYY");
      },
    },
    {
      field: "accountName",
      headerName: "Account Name",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "invoiceFrequency",
      headerName: "Frequency",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 200,
      editable: false,
    },
    {
      field: "invoiceAmount",
      headerName: "Amount",
      width: 150,
      editable: false,
      valueGetter: (value) => {
        return `$ ${parseFloat(value).toFixed(2)}`;
      },
    },
    {
      field: "invoiceStatus",
      headerName: "Status",
      width: 150,
      editable: false,
      renderCell: (params) => {
        if (
          [
            InvoiceStatus.OnHold,
            InvoiceStatus.InProgress,
            InvoiceStatus.SendToCustomer,
          ].includes(params.row.invoiceStatus)
        ) {
          return "Unpaid";
        } else if (params.row.invoiceStatus === InvoiceStatus.PaymentReceived) {
          return "Paid";
        } else {
          return "Pending";
        }
      },
    },
    {
      field: "subStatus",
      headerName: "Sub Status",
      width: 150,
      editable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 300,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <InvoiceActionIcons
          invoice={params.row}
          handlePayment={handlePayment}
        />
      ),
    },
  ];

  const handleRowSelectionChange = (rows: InvoiceItem[]) => {
    setSelectedInvoices(rows);
  };

  const handleRefresh = () => {
    fetchInvoicesQuery.refetch();
  };

  const handleClosePaymentDialog = (message: InvoicePaymentCallbackMessage) => {
    setIsReceivePaymentFormActive(false);
    if (message === InvoicePaymentCallbackMessage.PaymentSuccess) {
      fetchInvoicesQuery.refetch();
      fetchInvoiceOverviewQuery.refetch();
    }
  };

  const handlePayment = (event: any, invoice: InvoiceItem) => {
    event.stopPropagation();
    setInvoiceForPayment(invoice);
    setIsReceivePaymentFormActive(true);
  };

  useEffect(() => {
    if (fetchInvoiceOverviewQuery.isSuccess) {
      setInvoiceOverviewForPayment(fetchInvoiceOverviewQuery.data);
    }
    setLoading(
      fetchInvoiceOverviewQuery.isLoading ||
        fetchInvoiceOverviewQuery.isFetching ||
        fetchInvoiceOverviewQuery.isRefetching
    );
  }, [
    fetchInvoiceOverviewQuery.isSuccess,
    fetchInvoiceOverviewQuery.isError,
    fetchInvoiceOverviewQuery.data,
    fetchInvoiceOverviewQuery.error,
    fetchInvoiceOverviewQuery.isLoading,
    fetchInvoiceOverviewQuery.isFetching,
    fetchInvoiceOverviewQuery.isRefetching,
  ]);

  useEffect(() => {
    if (fetchInvoicesQuery.isSuccess) {
      setInvoiceList(fetchInvoicesQuery.data);
    }
  }, [
    fetchInvoicesQuery.isSuccess,
    fetchInvoicesQuery.isLoading,
    fetchInvoicesQuery.isRefetching,
    fetchInvoicesQuery.isFetching,
    fetchInvoicesQuery.data,
  ]);

  useEffect(() => {
    setInvoiceFilters({
      ...invoiceFilters,
      page: invoicePaginationModel.page,
      pageSize: invoicePaginationModel.pageSize,
    });
  }, [invoicePaginationModel]);

  useEffect(() => {
    const dateRange = queryParams.get("dateRange");
    const dateFilters: Partial<InvoiceFilters> = {
      fromDate: queryParams.get("fromDate") || undefined,
      toDate: queryParams.get("toDate") || undefined,
    };

    if (
      dateRange &&
      Object.values(DateRangeOptions).includes(dateRange as DateRangeOptions)
    ) {
      if (dateRange !== DateRangeOptions.CustomDate) {
        dateFilters.fromDate = getDateRange(dateRange).startDate;
        dateFilters.toDate = getDateRange(dateRange).endDate;
      }
      dateFilters.dateRange = dateRange;

      setSelectedDateRange(dateRange);
    }

    const queryFilters: InvoiceFilters = {
      status:
        queryParams.get("status") ||
        String(CUSTOMER_INVOICE_STATUS_FILTER_DROPDOWN[0].value),
      ...dateFilters,
      page: Number(queryParams.get("page")) || undefined,
      pageSize: Number(queryParams.get("pageSize")) || undefined,
      sortBy: queryParams.get("sortBy") || undefined,
      sortOrder: queryParams.get("sortOrder") || undefined,
    };

    const tempInvoiceFiltersFromQueryStrings = Object.entries(
      queryFilters
    ).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    const tempFilterValuesFromQueryStrings = Object.entries({
      status: tempInvoiceFiltersFromQueryStrings.status,
    }).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    }, {});

    Object.values(tempFilterValuesFromQueryStrings).length > 0 &&
      setFilterValues({
        ...filterValues,
        ...tempFilterValuesFromQueryStrings,
      });
    setInvoiceFilters({
      ...invoiceFilters,
      ...tempInvoiceFiltersFromQueryStrings,
    });
    setInvoicePaginationModel({
      ...invoicePaginationModel,
      page: tempInvoiceFiltersFromQueryStrings.page || 0,
      pageSize: tempInvoiceFiltersFromQueryStrings.pageSize || 10,
    });
  }, []);

  useEffect(() => {
    const filters = Object.entries(invoiceFilters).reduce(
      (acc: any, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    const tab = queryParams.get("tab");

    setQueryParams({
      ...(tab && { tab }),
      ...filters,
    });
  }, [invoiceFilters]);

  useEffect(() => {
    if (!invoiceList?.items || selectedInvoices.length === 0) return;

    const updatedSelectedInvoice = selectedInvoices.map(
      (selectedInvoice) =>
        invoiceList.items.find(
          (invoice) => invoice.id === selectedInvoice.id
        ) || selectedInvoice
    );

    setSelectedInvoices(updatedSelectedInvoice);
  }, [invoiceList?.items]);

  return (
    <>
      <Box
        sx={{
          marginTop: "10px",
          width: "100%",
          // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
        }}
      >
        <Toast setOptions={setToastOptions} options={toastOptions} />
        <Box>
          <Grid2
            container
            direction={"column"}
            sx={{
              marginTop: "0px",
              marginBottom: "8px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <InvoiceToolbar
              invoiceFilters={invoiceFilters}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              setInvoiceFilters={setInvoiceFilters}
              onRefresh={handleRefresh}
              resetPage={resetPage}
            />
          </Grid2>
          <Grid2 container direction={"column"} spacing={2}>
            <DataGrid
              columns={columns}
              rows={invoiceList?.items || []}
              loading={
                fetchInvoicesQuery.isLoading ||
                fetchInvoicesQuery.isRefetching ||
                isInvoiceActionsMutationsLoading
              }
              sortModel={[
                {
                  field: invoiceFilters.sortBy || "",
                  sort: (invoiceFilters.sortOrder as GridSortDirection) || null,
                },
              ]}
              onSortModelChange={handleSortModelChange}
              paginationModel={invoicePaginationModel}
              setPaginationModel={setInvoicePaginationModel}
              onSelectedRowsChange={handleRowSelectionChange}
              totalRecords={invoiceList?.totalItems}
              paginationMode="server"
              sortingMode="server"
              rowCount={invoiceList?.totalItems ? invoiceList?.totalItems : 0}
              pageSize={invoiceList?.pageSize}
              sx={{
                border: "none",
                backgroundColor: theme.palette.secondary.light,
                padding: "20px",
                paddingTop: "0px",
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                height: "70vh",
              }}
            />
          </Grid2>
        </Box>
      </Box>
      <PaymentDialog
        open={isReceivePaymentFormActive}
        loading={loading}
        handleClose={handleClosePaymentDialog}
        invoiceOverview={invoiceOverviewForPayment}
      />
    </>
  );
};
