import React, { useEffect, useState } from "react";
import { AuthPageLayout } from "../../layouts";
import { ChangePasswordPayload, ToastOptions } from "../../../types";
import { ChangeUserPasswordForm, LogoutModal } from "./fragments";
import { useLocation } from "react-router-dom";
import {
  useResetUserPasswordMutation,
  useValidateResetPasswordToken,
} from "../../../api/userService";
import { Toast } from "../../common";

export const ResetPassword: React.FC = () => {
  const location = useLocation();
  const [orgId, setOrgId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [token, setToken] = useState<string | null>(null);
  const resetPasswordMutation = useResetUserPasswordMutation();
  const validateResetPasswordToken = useValidateResetPasswordToken(
    token || "",
    token !== null
  );
  const [userName, setUserName] = useState("");
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleResetPassword = async (data: ChangePasswordPayload) => {
    if (!token) {
      setToastOptions({
        open: true,
        message: "Token is missing. Please use a valid reset password link.",
        severity: "error",
      });
    } else {
      setUserName(data.userName);
      data.passwordResetToken = token;
      resetPasswordMutation.mutate({ orgId, userId, data });
    }
  };

  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [location.search]);

  useEffect(() => {
    if (token) {
      validateResetPasswordToken.refetch();
    }
  }, [token, validateResetPasswordToken.refetch]);

  useEffect(() => {
    if (validateResetPasswordToken.isError) {
      setToastOptions({
        open: true,
        message: "Invalid or expired reset password token.",
        severity: "error",
      });
    }
    if (validateResetPasswordToken.isSuccess) {
      const data = validateResetPasswordToken.data;
      if (data && data.orgId && data.userId) {
        setOrgId(data.orgId);
        setUserId(data.userId);
      } else {
        setToastOptions({
          open: true,
          message: "Invalid or expired reset password token.",
          severity: "error",
        });
      }
    }
  }, [
    validateResetPasswordToken.isSuccess,
    validateResetPasswordToken.data,
    validateResetPasswordToken.isError,
  ]);

  useEffect(() => {
    if (resetPasswordMutation.isSuccess) {
      setToastOptions({
        open: true,
        message: "Password reset successfully completed.",
        severity: "success",
      });
      setLogoutModalOpen(true);
    }
    if (resetPasswordMutation.isError) {
      setToastOptions({
        open: true,
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [
    resetPasswordMutation.isError,
    resetPasswordMutation.data,
    resetPasswordMutation.isError,
  ]);

  return (
    <AuthPageLayout
      content={
        <>
          <Toast setOptions={setToastOptions} options={toastOptions} />
          <ChangeUserPasswordForm
            handleUpdatePassword={handleResetPassword}
            isUserNameRequired={true}
            isOldPasswordRequired={false}
          />
          <LogoutModal
            open={logoutModalOpen}
            userName={userName}
            message={
              "Your password has been updated. Please log in again to continue."
            }
          />
        </>
      }
    />
  );
};
