import React from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { theme } from "../../../utils";

interface ButtonProps extends MuiButtonProps {
  label?: string;
  variantType?: "primary" | "secondary";
  icon?: SvgIconComponent;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  variantType = "primary",
  icon: Icon,
  ...props
}) => {
  const { disabled } = props;

  return (
    <MuiButton
      variant={variantType === "primary" ? "contained" : "outlined"}
      color={props.color ? props.color : "primary"}
      startIcon={Icon ? <Icon /> : null}
      {...props}
      sx={{
        boxSizing: "border-box",
        textTransform: "none",
        borderRadius: "60px",
        ...props.sx,
        ...(!disabled && {
          border: "1px solid " + theme.palette.primary.main,
          ...(!props.color && {
            background:
              variantType === "primary"
                ? theme.palette.primary.main
                : theme.palette.secondary.light,
          }),
          color:
            variantType === "primary"
              ? theme.palette.button.main
              : theme.palette.button.light,
        }),
      }}
    >
      {label}
    </MuiButton>
  );
};

export default Button;
