export const RefreshIcon = ({ width = "25", height = "23", style = {} }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12.7883 0C7.12746 0 2.47382 4.34134 2.02006 9.87652H0.885731C0.526867 9.87652 0.203465 10.0939 0.0667831 10.4269C-0.0698992 10.76 0.00715719 11.1428 0.26191 11.3964L2.24567 13.3718C2.59099 13.7156 3.148 13.7156 3.49332 13.3718L5.47708 11.3964C5.73184 11.1428 5.80889 10.76 5.67221 10.4269C5.53553 10.0939 5.21213 9.87652 4.85326 9.87652H3.79886C4.24728 5.33532 8.09512 1.77778 12.7883 1.77778C16.0589 1.77778 18.9219 3.50632 20.5078 6.09517C20.7639 6.51338 21.3094 6.64398 21.7262 6.38688C22.1429 6.12978 22.2731 5.58232 22.0168 5.16411C20.1198 2.06729 16.6953 0 12.7883 0Z"
        fill="#686868"
      />
      <path
        d="M22.4572 9.62707C22.1134 9.28479 21.5601 9.28479 21.2164 9.62707L19.2319 11.6024C18.9774 11.8558 18.9001 12.2389 19.036 12.5724C19.172 12.9058 19.4945 13.1235 19.8524 13.1235H20.9105C20.4618 17.6622 16.6141 21.2222 11.9149 21.2222C8.64051 21.2222 5.77571 19.4922 4.18948 16.9035C3.93349 16.4857 3.38969 16.3561 2.97487 16.614C2.56006 16.8718 2.4313 17.4194 2.68729 17.8372C4.58503 20.9342 8.0095 23 11.9149 23C17.5729 23 22.2294 18.6613 22.6831 13.1235H23.8211C24.1791 13.1235 24.5015 12.9058 24.6376 12.5724C24.7736 12.2389 24.6962 11.8558 24.4416 11.6024L22.4572 9.62707Z"
        fill="#686868"
      />
    </svg>
  );
};
